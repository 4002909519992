import React, {useState, useEffect} from 'react';
import {useUser} from '../UserContext';
import { useParams } from 'react-router-dom';
import BillingService from '../../services/BillingService';
import Billing from './Billing';

export default function CheckoutSuccess (props) {
    const { user } = useUser();
    const {id } = useParams();

    useEffect( async () => {
        
        // TO DO: make sure this call is legit ? or do not do this call at all ?
        //const ret = await BillingService.doCheckoutSuccess(user.accessToken.token, id);

 
    }, []);

    return ( 
        <>
            <Billing action='checkout_success'/>
        </>

    );

}