/* This example requires Tailwind CSS v2.0+ */
import {Link} from 'react-router-dom';
import { useHistory } from "react-router-dom";
import {useUser} from '../UserContext';
import AuthenticationService from '../../services/AuthenticationService';
import { Fragment, useState } from 'react'
import { ChevronDownIcon } from '@heroicons/react/solid'
import { Menu, Dialog, Transition } from '@headlessui/react'
import {
  HomeIcon,
  MenuIcon,
  UsersIcon,
  XIcon,
  CashIcon,
  BellIcon,
  CogIcon,
  AcademicCapIcon,
  DocumentTextIcon
} from '@heroicons/react/outline'


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Header(props) {
  const history = useHistory();

  const [sidebarOpen, setSidebarOpen] = useState(false)
  //const [submenuOpen, setSubmenuOpen] = useState(false)
  const { user, setUser } = useUser();

  const navigation = [
    { name: 'Dashboard', href: '/', icon: HomeIcon, role : 1 },
    { name: 'Candidates', href: '/candidates', icon: AcademicCapIcon, role: 1 },
    { name: 'Tests', href: '/tests', icon: DocumentTextIcon, role: 1 },
    { name: 'Notifications', href: '/notifications', icon: BellIcon, role: 1},
   /* { name: 'Reports', href: '/reports', icon: ChartBarIcon }, */
    { name: 'Team', href: '/team', icon: UsersIcon, role: 1 },
    { name: 'Company', href: '/company', icon: CogIcon, role: 3},
    { name: 'Billing', href: '/billing', icon: CashIcon, role: 3},
  ]
  
  const doSignOut = () => {
      AuthenticationService.signOut();
      setUser(null);
      history.push('/sign-in');
      window.location.reload();      
  }  

  return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full">
        <body class="h-full">
        ```
      */}
      <>
      <div className="sticky top-0 z-50 md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3 bg-white">
        <img className="h-8 w-auto mx-auto" src="/sm-logo.png" alt="Skillmeter" />

            <button
              type="button"
              className="absolute top-0 -ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <MenuIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>

        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as="div" className="fixed inset-0 flex z-40 md:hidden" onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <div className="relative flex-1 flex flex-col max-w-xs w-full bg-white">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                 <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto ">
{/*                  <div className="flex-shrink-0 flex items-center px-4 ">
                    <img
                      className="h-8 w-auto"
                      src="https://tailwindui.com/img/logos/workflow-logo-indigo-600-mark-gray-800-text.svg"
                      alt="Workflow"
                    />
                  </div>*/}
                  <nav className="mt-5 px-2 space-y-1">
                    {navigation.map((item) => (
                      user.role >= item.role ? 

                      <Link
                      key={item.name}
                      to={item.href}  
                        className={classNames(
                          item.name === props.nav
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                          'group flex items-center px-2 py-2 text-base font-medium rounded-md'
                        )}
                      >
                        <item.icon
                          className={classNames(
                            item.name === props.nav ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
                            'mr-4 flex-shrink-0 h-6 w-6'
                          )}
                          aria-hidden="true"
                        />
                        {item.name}
                      </Link> : '' 
                    ))}
                  </nav>
                </div>
              </div>
            </Transition.Child>
            <div className="flex-shrink-0 w-14">{/* Force sidebar to shrink to fit close icon */}</div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex-1 flex flex-col min-h-0  bg-white ">
            <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
              <div className="flex items-center flex-shrink-0 px-4 mt-4">
                <Link to={'/'} className='mx-auto'><img className="h-8 w-auto mx-auto" src="/sm-logo.png" alt="Skillmeter" /></Link>
                
              </div>
              <div className="mx-auto w-full text-center mt-10 pb-8 border-b border-gray-200">

            {/* 👏 &nbsp;&nbsp;Hello Cezar */}

            <Menu as="div" className="relative inline-block text-left">
              <div>
                <Menu.Button className="inline-flex justify-center w-100 rounded-md border-0 px-4 py-2 text-sm font-medium text-gray-700 hover:text-red-800 focus:outline-none">
                👏 Hello {user.firstName ? user.firstName : ''}
                  <ChevronDownIcon className="ml-2 h-5 w-5" aria-hidden="true" />
                </Menu.Button>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute mt-2 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <Link
                          to="/settings"
                          className={classNames(
                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                            'block px-4 py-2 text-sm'
                          )}
                        >
                          Account settings
                        </Link>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          href="mailto:support@skillmeter.com"
                          className={classNames(
                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                            'block px-4 py-2 text-sm'
                          )}
                        >
                          Support
                        </a>
                      )}
                    </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            type="button"
                            onClick={doSignOut}
                            className={classNames(
                              active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                              'block w-full text-left px-4 py-2 text-sm'
                            )}
                          >
                            Sign out
                          </button>
                        )}
                      </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>

              <nav className="mt-5 flex-1 px-2 bg-white space-y-1">
                {navigation.map((item) => (
                  user.role >= item.role ? 
                  <Link
                    key={item.name}
                    to={item.href}
                    className={classNames(
                      item.name === props.nav ? 'text-gray-900' : 'text-gray-400 hover:text-gray-900',
                      'group flex items-center pl-8 px-2 pt-4 pb-2 text-lg font-medium rounded-md'
                    )}
                  >
                    <item.icon
                      className={classNames(
                        item.name === props.nav ? 'text-gray-900' : 'text-gray-400 group-hover:text-gray-900',
                        'mr-3 flex-shrink-0 h-6 w-6'
                      )}
                      aria-hidden="true"
                    />
                    {item.name}
                  </Link>
                  : ''
                ))}
              </nav>
            </div>
          </div>
        </div>
      </>
    </>
  )
}
