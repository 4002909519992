import { CheckCircleIcon } from '@heroicons/react/solid'

export default function AlertSuccess(props) {
  return (
    <div className="rounded-md bg-green-300 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <CheckCircleIcon className="h-5 w-5 text-black" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-black">{props.title}</h3>
          <div className=" text-sm text-green-700">
            <p>{props.message ? props.message: ''}</p>
          </div>
        </div>
      </div>
    </div>
  )
}
