/* This example requires Tailwind CSS v2.0+ */
import { useEffect, useState } from 'react'
import {Link} from 'react-router-dom'
import {ClipboardListIcon, DotsVerticalIcon } from '@heroicons/react/solid'
import { QuestionType } from '../../utils/questions';
import {useUser} from '../UserContext';
import CompaniesService from '../../services/CompaniesService';

export default function QuestionSelector(props) {
  const { user } = useUser();
    const [currentPlan, setCurrentPlan] = useState();

    useEffect( async () => {
      // get company plan
      const ret = await CompaniesService.getCompany(user.accessToken.token);
      setCurrentPlan(ret.Subscription);

    }, []);

   
    if (props.open) 
    return (
            <div className="fixed z-10 inset-0 overflow-y-auto" ariaLabelledby="modal-title" role="dialog" ariaModal="true">
              <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" ariaHidden="true"></div>
          
                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" ariaHidden="true">&#8203;</span>
          
                <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-5/6 md:w-2/3">
                  <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start ">
                      <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
          
                        <ClipboardListIcon className="h-6 w-6 text-green-600" />
          
                      </div>
                      <div className="mt-2 text-center  sm:ml-4 sm:text-left">
                        <h3 className="text-lg leading-6 font-bold text-gray-900" id="modal-title">
                          What type of question do you want to add?
                        </h3>


                        <div role="list" className="mt-3 grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-2 lg:grid-cols-3">
                        {QuestionType.map((qt) => (
                        
                            <div className={"col-span-1 flex flex-wrap shadow-sm rounded-md bg-white  " + ((qt.id == 8 && currentPlan != 3 ) ? "":"hover:bg-blue-200")}>
                              <div className="flex-1 flex items-center justify-between border-2  border-blue-200  rounded-md">
                              {qt.id == 8 && currentPlan != 3 ? 
                                <div className="flex-1 px-4 py-2 text-sm ">
                                <p className='font-bold text-black'>
                                  {qt.title}
                                </p>
                                <p className="text-gray-500">{qt.desc}</p>
                                <span className='bg-red-200 rounded-sm'>Requires Ultimate plan to access this feature.</span> 
                                <Link to={"/billing"} className='ml-2 bg-yellow-200 rounded-sm font-bold underline text-blue-900'>Upgrade now!</Link>
                              </div>
                            
                              : 
                              <Link to={"/tests/" + props.testId + "/questions/add/0?type=" + qt.id } >
                                <div className="flex-1 px-4 py-2 text-sm ">
                                  <p className='font-bold text-black'>
                                    {qt.title}
                                  </p>
                                  <p className="text-gray-500">{qt.desc}</p>
                                  {qt.id == 8 && currentPlan != 3 ? <span className='bg-red-200'>Requires Ultimate plan to access this feature</span> : ''}
                                </div>
                                </Link>
                              
                              }

                              </div>
                            </div>
                        
                        ))}
                        </div>
                      

                        {/* 

                        <div className="mt-2">
                        {QuestionType.map((qt) => (
                          <>
                        <Link to={"/tests/" + props.testId + "/questions/add/0?type=" + qt.id } className='font-bold'> {qt.title}</Link>
                        {qt.id == 6 ? <span >Requires Ultimate plan to access this feature. <a className='text-red-600' href="/admin/billing"><b>Upgrade now!</b></a></span> : ''}
                        <br />
                        <span>{qt.desc}</span> 
                        <br />
                        </>

                          ))}
                        </div>
                        */}
                        
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:px-6 text-center">
                    <button type="button" onClick = {() => {props.setOpen(false)}} className="mt-3 sm:mt-0 sm:ml-3 w-full sm:w-auto inline-flex justify-center font-bold border-2 border-black rounded-md shadow-gray py-2 px-4 hover:bg-gray-400 focus:outline-none">
                      Nevermind
                    </button>
                  </div>
                </div>
          
              </div>
            </div>    
    )
    else 
    return (<></>)
}