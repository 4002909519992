import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './App.css';
import {UserContext} from './components/UserContext.js';
import {CompanyContext} from './components/CompanyContext.js';
import AuthenticationService from './services/AuthenticationService';
import SignIn from './components/Users/SignIn'; 
import SignUp from './components/Users/SignUp'; 
import Dashboard from './components/Home/Dashboard'
import ProtectedRoute from './ProtectedRoute';
import NotFound404 from './components/Home/NotFound404';
import CandidateList from './components/Candidates/CandidateList';
import TestList from './components/Tests/TestList';
import UserList from './components/Team/UserList';
import TrackingCode from './TrackingCode';
import AddEditUser from './components/Team/AddEditUser';
import AddCandidate from './components/Candidates/AddCandidate';
import ImportCandidates from './components/Candidates/ImportCandidates';
import AddEditTest from './components/Tests/AddEditTest';
import Notifications from './components/Notifications/Notifications';
import Company from './components/Company/Settings';
import Billing from './components/Company/Billing';
import UserSettings from './components/Users/Settings'
import DeleteAccount from './components/Users/DeleteAccount'
import QuestionList from './components/Questions/QuestionList';
import AddEditQuestion from './components/Questions/AddEditQuestion';
import Result from './components/Candidates/Results/Result';
import PublicResult from './components/Candidates/Results/PublicResult';
import Receipt from './components/Company/Receipt';
import CheckoutSuccess from './components/Company/CheckoutSuccess';
import CompaniesService from './services/CompaniesService';
import {useMaintenanceStatus} from './hooks/useMaintenanceStatus.js';
import MaintenanceBar from './components/Shared/MaintenanceBar.js';


//const Dashboard = React.lazy(() => import('./components/Home/Dashboard'));
//const Billing = React.lazy(() => import('./components/Company/Billing'));


export default function App() {
  const currentUser = AuthenticationService.getCurrentUser();
  //console.log(currentUser)

  const [user, setUser] = useState(currentUser); 
  const [company, setCompany] = useState();   
  const [timeoutExecution, setTimeoutExecution] = useState(0); 
  const isMaintenance = useMaintenanceStatus();

  const setUserAndToken = (data) => {
    //console.log('set user hook: ');
    //console.log(data);

    // save token in cookie
    AuthenticationService.saveCurrentUser(data);

    // save candidate data
    setUser(data);
  }
  
  const setCompanyData = async()=> {
    if (user) {
      const comp = await CompaniesService.getCompany(user.accessToken.token);
      setCompany(comp)
      //console.log(comp)
    } else {
      setCompany(null)
    }

  }

  useEffect(async() =>{
    setCompanyData()
  },[user])  

  useEffect ( () => {
    //console.log(isMaintenance.maintenance)
    if (isMaintenance && isMaintenance.maintenance === true) {
      setUserAndToken(null)
      //console.log('disconnect user')
    }
  }, [isMaintenance]);

  // setting up a timer to refresh company data every hour (to check if the subscription is expired)
  useEffect(() => {
    const timeout = setTimeout( () => {
      setCompanyData()
      setTimeoutExecution(timeoutExecution + 1)
    }, 60 * 60 * 1000 * 4); // 4 hour timeout to refresh company data
  
    return () => {
      clearTimeout(timeout);

    }
  }, [timeoutExecution]);




  return (
    <div>

          {isMaintenance && isMaintenance.maintenance === true ? <MaintenanceBar reason={isMaintenance.reason}/> : <></>}

          <BrowserRouter>
          <TrackingCode />

            <UserContext.Provider value={{user, setUser: setUserAndToken}}>
              <CompanyContext.Provider value={{company, setCompany: setCompanyData}}>
                <Switch>
                    <Route path="/sign-in" render={props =>
                      <SignIn maintenance={isMaintenance} />
                    }/>     
                                  
                    <Route path="/sign-up" render={props =>
                      <SignUp maintenance={isMaintenance} />
                    }/>                     
                    <ProtectedRoute exact path="/" component={Dashboard}></ProtectedRoute>
                    <ProtectedRoute path="/dashboard" component={Dashboard}></ProtectedRoute>
                    <ProtectedRoute path="/candidates/add" component={AddCandidate}></ProtectedRoute>
                    <ProtectedRoute path="/candidates/import" component={ImportCandidates}></ProtectedRoute>
                    <Route path="/viewresult/:token" component={PublicResult}></Route>                    
                    <ProtectedRoute path="/candidates/:id/result" component={Result}></ProtectedRoute>                    
                    <ProtectedRoute exact path="/candidates" component={CandidateList}></ProtectedRoute>                    
                    <ProtectedRoute path="/tests/add" component={AddEditTest} ></ProtectedRoute>
                    <ProtectedRoute path="/tests/edit/:id" component={AddEditTest} ></ProtectedRoute>
                    <ProtectedRoute path="/tests/:id/questions/edit/:questionId" component={AddEditQuestion} ></ProtectedRoute>
                    <ProtectedRoute path="/tests/:id/questions/add/:questionId" component={AddEditQuestion} ></ProtectedRoute>
                    <ProtectedRoute path="/tests/:id/questions/" component={QuestionList} ></ProtectedRoute>
                    <ProtectedRoute exact path="/tests" component={TestList} ></ProtectedRoute>
                    <ProtectedRoute path="/team/add" component={AddEditUser}></ProtectedRoute>
                    <ProtectedRoute path="/team/user/:id" component={AddEditUser}></ProtectedRoute>
                    <ProtectedRoute exact path="/team" component={UserList}></ProtectedRoute>
                    <ProtectedRoute path="/notifications" component={Notifications}></ProtectedRoute>
                    <ProtectedRoute path="/company" component={Company}></ProtectedRoute>
                    <ProtectedRoute path="/billing/receipt/:id" component={Receipt}></ProtectedRoute>
                    <ProtectedRoute path="/billing/success/:id" component={CheckoutSuccess}></ProtectedRoute>
                    <ProtectedRoute path="/billing" component={Billing}></ProtectedRoute>
                    <ProtectedRoute path="/settings" component={UserSettings}></ProtectedRoute>
                    <ProtectedRoute path="/deleteaccount" component={DeleteAccount}></ProtectedRoute>

                    <Route path='*' exact={true} component={NotFound404} />
                </Switch>
                </CompanyContext.Provider>
            </UserContext.Provider>

          </BrowserRouter>

    </div>

  )
}

