import { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import parse from "html-react-parser";

const AnswerSingleAnswer = forwardRef((props, ref) => {

    const [correct, setCorrect] = useState("Wrong");
    
    useEffect( async() => {
        //console.log(props.answer)

        if (props.answer.A_1_Correct == true && props.answer.R_1 == true) { setCorrect("Correct"); }
        if (props.answer.A_2_Correct == true && props.answer.R_2 == true) { setCorrect("Correct"); }
        if (props.answer.A_3_Correct == true && props.answer.R_3 == true) { setCorrect("Correct"); }
        if (props.answer.A_4_Correct == true && props.answer.R_4 == true) { setCorrect("Correct"); }
        if (props.answer.A_5_Correct == true && props.answer.R_5 == true) { setCorrect("Correct"); }
        if (props.answer.A_6_Correct == true && props.answer.R_6 == true) { setCorrect("Correct"); }
        if (props.answer.A_7_Correct == true && props.answer.R_7 == true) { setCorrect("Correct"); }
        if (props.answer.A_8_Correct == true && props.answer.R_8 == true) { setCorrect("Correct"); }
        if (props.answer.A_9_Correct == true && props.answer.R_9 == true) { setCorrect("Correct"); }
        if (props.answer.A_10_Correct == true && props.answer.R_10 == true) { setCorrect("Correct"); }
        if (props.answer.A_11_Correct == true && props.answer.R_11 == true) { setCorrect("Correct"); }
        if (props.answer.A_12_Correct == true && props.answer.R_12 == true) { setCorrect("Correct"); }


    }, []); 
        
    return (
    <>
        <b>Answer: <span className={"rounded px-1 py-1 " + (correct=='Wrong' ? 'bg-red-300 text-red-800' : '') +  (correct=='Correct' ? ' bg-green-300 text-green-800' : '')}>{correct}</span></b>
        {props.answer.R_1 != null ? <><br />{parse(props.answer.A_1)}</> : ''}
        {props.answer.R_2 != null ? <><br />{parse(props.answer.A_2)}</> : ''}
        {props.answer.R_3 != null ? <><br />{parse(props.answer.A_3)}</> : ''}
        {props.answer.R_4 != null ? <><br />{parse(props.answer.A_4)}</> : ''}
        {props.answer.R_5 != null ? <><br />{parse(props.answer.A_5)}</> : ''}
        {props.answer.R_6 != null ? <><br />{parse(props.answer.A_6)}</> : ''}
        {props.answer.R_7 != null ? <><br />{parse(props.answer.A_7)}</> : ''}
        {props.answer.R_8 != null ? <><br />{parse(props.answer.A_8)}</> : ''}
        {props.answer.R_9 != null ? <><br />{parse(props.answer.A_9)}</> : ''}
        {props.answer.R_10 != null ? <><br />{parse(props.answer.A_10)}</> : ''}
        {props.answer.R_11 != null ? <><br />{parse(props.answer.A_11)}</> : ''}
        {props.answer.R_12 != null ? <><br />{parse(props.answer.A_12)}</> : ''}



        {(props.answer.Comments != null && props.answer.Comments) ? 
        <>
            <br /><br />
            <span className="whitespace-pre-line">
                <b>Comments:</b>
                <br />
                {props.answer.R_Text}
            </span>
        </> : ''}
    </>
    );
});


export default AnswerSingleAnswer;