import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {useUser} from '../UserContext';
import {useCompany} from '../CompanyContext';
import CandidatesService from '../../services/CandidatesService';
import Loader from "react-loader-spinner";
import Header from '../Shared/Header';
import CandidateCard from './CandidateCard';
import InfiniteScroll from "react-infinite-scroll-component";
import Filters from './Filters';
import AlertTrialExpired from '../Shared/AlertTrialExpired';
import AlertUpgradePlan from '../Shared/AlertUpgradePlan';
//import {useCompany} from '../../hooks/useCompany';

export default function CandidateList (props) {
    const { user } = useUser();
    const { company } = useCompany();    
    const [candidates, setCandidates] = useState([]);
    const [hasMoreCandidates, setHasMoreCandidates] = useState(true);
    //const [totalCandidates, setTotalCandidates] = useState(0);
    const [mounted, setMounted] = useState(false);
    const [mountedActions, setMountedActions] = useState(false);
    const [filter, setFilter] = useState({action: '', audience: 'my', testId: null, query: null}); 
    const [page, setPage] = useState(1);
    //const [company, setCompany] = useState();
    //const company = useCompany()

    const fetchCandidates = async(force) => {
        //console.log(filter)
        let ret;
        if (filter.action != 'search')
            ret = await CandidatesService.getCandidates(user.accessToken.token, filter.audience, filter.testId, candidates && !force? page : 1);
        else 
            ret = await CandidatesService.searchCandidates(user.accessToken.token, filter.query);
        //console.log(ret)
        
        if (ret){
            // need to concat the arrays for infinite scroll to work (we need to add the new items to the existing array)
            if (candidates && !force) {
                if (ret.length > 0) {
                // adding more candidates
                const prevcandidates = [...candidates];
                const items = prevcandidates.concat(ret);
                setCandidates(items); 
                //
                    //maxCandidates = totalCandidates;
                //
                setPage(page+1);
                }
                else {
                    setHasMoreCandidates(false)
                }
                
            }
            else {                
                // new candidates filter
                if (ret.length > 0 ) {
                    //setTotalCandidates(ret[0].TotalCandidates);
                    //maxCandidates = ret[0].TotalCandidates;
                    setCandidates(ret); // first time run (force = true) or projects is null
                    if (filter.action == 'search') {
                        setHasMoreCandidates(false)
                    }
                    else {
                        setHasMoreCandidates(true) // added this as a fix
                        setPage(2); // next page is 2
                    }
                }
                else {
                    //setTotalCandidates(0);
                    //maxCandidates = 0;
                    setHasMoreCandidates(false)
                    setCandidates([])
                }
            }
            // check if has More items to load for infinite scroll
                /*
                let maxCurrent = 0;
                if (ret.length > 0) maxCurrent = Math.max.apply(Math, ret.map(function(o) { return o.intRow; }))
                if (maxCurrent < maxCandidates)
                    setHasMoreCandidates(true);
                else
                    setHasMoreCandidates(false);
                */
            /*
            if (ret.length < 10) 
                setHasMoreCandidates(false); 
            else 
                setHasMoreCandidates(true);*/

        }
        else {
            setCandidates(null);
            //setTotalCandidates(0);
            setHasMoreCandidates(false);
        }

    }


    useEffect( async () => {

        setMountedActions(false);
        
        //const comp = await CompaniesService.getCompany(user.accessToken.token);
        //console.log (comp)
        //setCompany(comp);
  
        setMountedActions(true);

    }, []);

    useEffect( async () => {

        await fetchCandidates(true);

        setMounted(true);
    }, [filter]);


    const changeFilter = async (val) => {     
        //console.log(val)   
        setFilter(val);
        setMounted(false) // will trigger useEffect cause filter changes so setting mounted to false to show loader while fetching data
    }

    const canAddCandidates = () => {
        if (company && company.Subscription == 4 && (company.Credits ? company.Credits : 0 ) <= 0)
        {
            return false;
        }

        return true;
    }
  
    const deleteCandidate = async (id) => {
        await CandidatesService.deleteCandidate(user.accessToken.token, id);
        let list = [...candidates];
        list.splice(list.findIndex(v => v.CandidateId === id), 1);
        setCandidates(list);
    }    
    return ( 
        <>
        <Header nav='Candidates'/>   

        <div className="md:ml-64 flex flex-col flex-1 w-auto ">
            {/* Page title & actions */}
            <div className="relative">
                <div className="py-6 px-4 sm:px-6 md:px-8 w-full fixed bg-title flex md:pr-64 shadow-sm">
                    <h1 className="font-semibold text-gray-900 text-xl">Candidates</h1>
                    {mountedActions ?
                    (( company && company.SubscriptionStartDate == null && company.CompanyId != 16 ) && company.Subscription != 4) || !canAddCandidates()? '' : 
                    <div className='w-full  flex justify-end relative'>
                        <Link to={'/candidates/add'} className=" items-center mr-6 px-3 py-2 border text-sm rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none ">
                            Add candidate 
                        </Link>                    
                        
                        <Link to={'/candidates/import'} className=" items-center mr-6 px-3 py-2 border text-sm rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none ">
                            Import candidates 
                        </Link> 

                    </div>
                    : ''}
                </div>
            </div>
            
            {/* Page */}
            
            <div className="bg-body w-full min-h-screen mx-auto px-4 sm:px-6 md:px-8 pt-20">
                <div className="py-4">
                    
                <Filters filter={filter} changeFilter={changeFilter}/>

                { mounted ? 

                <>

                {/* trial expired message */}
                {company != null && company.SubscriptionStartDate == null && company.Subscription != 4 ?
                <div>
                  <AlertTrialExpired title='YOUR TRIAL PERIOD HAS EXPIRED!' message='Please update your billing info so you can add more candidates.' subscription={company.Subscription} currency={company.Currency.toLowerCase()}/>
                </div> 
                : ''  
                }
                {/* plan limit (credits)  */}
                {!canAddCandidates() ?
                <div>
                  <AlertUpgradePlan limit='candidates' action='Buy credits'/>
                </div> 
                : ''  
                }

                {candidates.length == 0 ? <p className='text-center text-gray-400 text-xl mt-10'>Ugh... there's no candidate to show.</p> 
                : 
                <InfiniteScroll
                    className=''
                    dataLength={candidates.length}
                    next={() => {fetchCandidates(false)}}
                    hasMore={hasMoreCandidates}
                    loader={<div className='flex justify-center mt-10'><Loader type="ThreeDots" color="#000000" width={50}/></div>}
                    endMessage={
                        <p className='text-center'>
                          <b>You reached the end of the list!</b>
                        </p>
                      }
                    
                >
                    {
                    candidates.map(function(candidate){
                    return (
                        <CandidateCard key={candidate.CandidateId} candidateId={candidate.CandidateId} firstName={candidate.First_Name} lastName={candidate.Last_Name} email={candidate.Email} pinCode={candidate.PinCode} createdDate={candidate.CreatedDate} testName={candidate.TestName} result={candidate.Passed} showResult={candidate.TestDate ? true:false} testDate={candidate.TestDate} testEndDate={candidate.TestEndDate} testDuration={candidate.testDuration} seenResult={candidate.SeenResult} deleteCandidate={deleteCandidate}/>

                        )
                    }
                    )}

                </InfiniteScroll>
                }
                </>
                : 
                <div className="bg-body w-full h-screen mx-auto px-4 sm:px-6 md:px-8 pt-20">
                <div className='flex justify-center mt-24'><Loader type="ThreeDots" color="#000000" width={50}/></div>
                </div>
                }



                </div>
            </div>

        </div>

        </>
    );


}    
