import React from 'react';
import AnswerSingleAnswer from './AnswerSingleAnswer';
import AnswerMultiAnswer from './AnswerMultiAnswer';
import AnswerExactMatch from './AnswerExactMatch';
import AnswerFreeText from './AnswerFreeText';
import parse from "html-react-parser";
import AnswerPictureChoice from './AnswerPictureChoice';
import AnswerVideoRecording from './AnswerVideoRecording';
import AnswerRanking from './AnswerRanking';
import AnswerFileUpload from './AnswerFileUpload';
import AnswerTypingSpeed from './AnswerTypingSpeed';
import AnswerProgramming from './AnswerProgramming';
import MarkAnswer from './MarkAnswer';

export default function Answer (props) {

    //console.log(props.answer)
    const markCorrectWrong = (correct) => {
        props.updateAnswer(props.answer.ResponseId, correct)
    }

    return ( 
        <div className={'mb-4 py-4 px-4 rounded-md ' + ((props.answer.QuestionType == 1 || props.answer.QuestionType == 9 || props.answer.QuestionType == 11)  && !props.publicMode && props.answer.Scored && props.answer.A_1_Correct == null ? 'bg-yellow-50' : 'bg-white')} key={props.index}>     
            <div className='flex'>
                <span className='font-bold'>QUESTION {props.index+1} - {props.answer.Scored ? "SCORED" : "NOT SCORED"}</span>

                { (props.answer.QuestionType == 1 || props.answer.QuestionType == 9 || props.answer.QuestionType == 11)  && !props.publicMode && props.answer.Scored && props.answer.A_1_Correct == null ? <MarkAnswer answer={props.answer} markCorrectWrong={markCorrectWrong}/> : ''}
            </div>                           
            <div className="sm:pt-5">
                {parse(props.answer.Q_Title)}
            </div>                    
            <div className="sm:pt-5">
                {props.answer.QuestionType == 1 ? <AnswerFreeText answer={props.answer} /> : ''}                
                {props.answer.QuestionType == 2 || props.answer.QuestionType == 4 ? <AnswerSingleAnswer answer={props.answer} /> : ''}
                {props.answer.QuestionType == 3 ? <AnswerMultiAnswer answer={props.answer} /> : ''}                
                {props.answer.QuestionType == 5 ? <AnswerRanking answer={props.answer} /> : ''}                
                {props.answer.QuestionType == 6 ? <AnswerExactMatch answer={props.answer} /> : ''}                
                {props.answer.QuestionType == 7 ? <AnswerPictureChoice answer={props.answer} /> : ''}           
                {props.answer.QuestionType == 8 ? <AnswerProgramming answer={props.answer} /> : ''}         
                {props.answer.QuestionType == 9 ? <AnswerFileUpload answer={props.answer} /> : ''}         
                {props.answer.QuestionType == 10 ? <AnswerTypingSpeed answer={props.answer} /> : ''}                
                {props.answer.QuestionType == 11 ? <AnswerVideoRecording answer={props.answer} /> : ''}       


                {/* 
                {answer.QuestionType == 3 ? <QuestionMultipleAnswer question={question} setQuestion={setQuestion} ref={refs.qanswers}/> : ''} 
                {answer.QuestionType == 5 ? <QuestionRanking question={question} setQuestion={setQuestion} ref={refs.qanswers}/> : ''}
                {
                
                {answer.QuestionType == 7 ? <QuestionPictureChoice question={question} setQuestion={setQuestion} ref={refs.qanswers}/> : ''}
                {answer.QuestionType == 8 ? <QuestionProgramming question={question} setQuestion={setQuestion} ref={refs.qanswers}/> : ''}
                {answer.QuestionType == 9 ? <QuestionFileUpload question={question} setQuestion={setQuestion} ref={refs.qanswers}/> : ''}
                {answer.QuestionType == 10 ? <QuestionTypingSpeed question={question} setQuestion={setQuestion} ref={refs.qanswers}/> : ''}
                {answer.QuestionType == 11 ? <QuestionVideoRecording question={question} setQuestion={setQuestion} ref={refs.qanswers}/> : ''}
                */}
            </div>                         
        </div>

    );
    
}