import axios from "axios";


class CandidatesService {

  
    getCandidates = async (token, filterAction, filterTestId, offset) => {
        const ret= await axios.get(process.env.REACT_APP_SKILLMETER_API + "/candidates/" + filterAction + "/" + offset + (filterTestId == null ? "" : "?testId=" + filterTestId), { headers: {"Authorization" : `Bearer ${token}`, "Content-type": "multipart/form-data"} });
        return ret.data;
      }

    searchCandidates = async (token, searchText) => {
      const ret= await axios.get(process.env.REACT_APP_SKILLMETER_API + "/candidates/search?query=" + searchText, { headers: {"Authorization" : `Bearer ${token}`, "Content-type": "multipart/form-data"} });
      return ret.data;
    }
      
    getTestsCountByUser = async (token) => {
      const ret= await axios.get(process.env.REACT_APP_SKILLMETER_API + "/candidates/testscountbyuser/", { headers: {"Authorization" : `Bearer ${token}`} });
      return ret.data;
    }
  
    getCandidatesCountByUser = async (token) => {
      const ret= await axios.get(process.env.REACT_APP_SKILLMETER_API + "/candidates/countbyuser/", { headers: {"Authorization" : `Bearer ${token}`} });
      return ret.data;
    }

    getCandidatesCountByCompany = async (token) => {
      const ret= await axios.get(process.env.REACT_APP_SKILLMETER_API + "/candidates/countbycompany/", { headers: {"Authorization" : `Bearer ${token}`} });
      return ret.data;
    }

    addCandidate = async (token, firstName, lastName, email, tests, scheduledDate, multipleSessions, allowResume) => {
      let fd = new FormData();
      fd.append('firstName', firstName);
      fd.append('lastName', lastName);
      fd.append('email', email);
      fd.append('tests', JSON.stringify(tests));
      if (scheduledDate) fd.append('scheduleDate', scheduledDate)
      fd.append('multipleSessions', multipleSessions ? 1 : 0)
      fd.append('allowResume', allowResume ? 1 : 0);
  
      const ret = await axios.post(process.env.REACT_APP_SKILLMETER_API + "/candidates" , fd, { headers: {"Content-Type": "multipart/form-data", "Authorization" : `Bearer ${token}`} });
      return ret;
    }  
  
    deleteCandidate = async(token, candidateId) => {

      const res = await axios.delete(process.env.REACT_APP_SKILLMETER_API + "/candidates/" + candidateId, { headers: {"Authorization" : `Bearer ${token}`, "Content-type": "multipart/form-data"}});
      return res.data;
    }  

    getCandidateAnswers = async (token, candidateId) => {
      const ret= await axios.get(process.env.REACT_APP_SKILLMETER_API + "/candidates/" + candidateId + '/answers', { headers: {"Authorization" : `Bearer ${token}`, "Content-type": "multipart/form-data"} });
      return ret.data;
    }

    getCandidateAnswersPublic = async (publicToken) => {
      const ret= await axios.get(process.env.REACT_APP_SKILLMETER_API + "/candidates/public/answers", { headers: {"Authorization" : `Bearer ${publicToken}`, "Content-type": "multipart/form-data"} });
      return ret.data;
    }

    getCandidate = async (token, candidateId) => {
      const ret= await axios.get(process.env.REACT_APP_SKILLMETER_API + "/candidates/" + candidateId, { headers: {"Authorization" : `Bearer ${token}`, "Content-type": "multipart/form-data"} });
      return ret.data;
    }

    getCandidatePublic = async (publicToken) => {
      const ret= await axios.get(process.env.REACT_APP_SKILLMETER_API + "/candidates/public", { headers: {"Authorization" : `Bearer ${publicToken}`, "Content-type": "multipart/form-data"} });
      return ret.data;
    }

    getTestsDetails = async (token, candidateId) => {
      const ret= await axios.get(process.env.REACT_APP_SKILLMETER_API + "/candidates/" + candidateId + '/tests', { headers: {"Authorization" : `Bearer ${token}`, "Content-type": "multipart/form-data"} });
      return ret.data;
    }

    getTestsDetailsPublic = async (publicToken) => {
      const ret= await axios.get(process.env.REACT_APP_SKILLMETER_API + "/candidates/public/tests", { headers: {"Authorization" : `Bearer ${publicToken}`, "Content-type": "multipart/form-data"} });
      return ret.data;
    }

    markSeenResult = async (token, candidateId) => {
      let fd = new FormData();
      const ret = await axios.patch(process.env.REACT_APP_SKILLMETER_API + "/candidates/" + candidateId + '/seen' , fd, { headers: {"Content-Type": "multipart/form-data", "Authorization" : `Bearer ${token}`} });
      return ret;
    }      

    setAllowResume = async (token, candidateId) => {
      let fd = new FormData();
      const ret = await axios.patch(process.env.REACT_APP_SKILLMETER_API + "/candidates/" + candidateId + '/allowresume' , fd, { headers: {"Content-Type": "multipart/form-data", "Authorization" : `Bearer ${token}`} });
      return ret;
    }      

    saveResponse = async (token, response) => {

      let fd = new FormData();
      fd.append('response', JSON.stringify(response));
  
      return axios.post(process.env.REACT_APP_SKILLMETER_API + "/candidates/" + response.CandidateId + '/answers' , fd,  { headers: {"Authorization" : `Bearer ${token}`, "Content-type": "multipart/form-data"} })
        .then ( (res) => {
            return res.data;
        });
    }  

    updateResult = async (token, candidateId, result) => {
      let fd = new FormData();
      fd.append('status', result);
  
      return axios.post(process.env.REACT_APP_SKILLMETER_API + "/candidates/" + candidateId + '/result' , fd,  { headers: {"Authorization" : `Bearer ${token}`, "Content-type": "multipart/form-data"} })
        .then ( (res) => {
            return res.data;
        });

    }

    getGlobalResult = async (token, testId, score) => {
      const ret= await axios.get(process.env.REACT_APP_SKILLMETER_API + "/candidates/globalresult?testId=" + testId + "&score=" + score, { headers: {"Authorization" : `Bearer ${token}`, "Content-type": "multipart/form-data"} });
      return ret.data;
    }

}

export default new CandidatesService();
