import React, {useState, useEffect, useRef} from 'react';
import {useParams, useHistory, Link} from 'react-router-dom';
import {useUser} from '../UserContext';
import Loader from "react-loader-spinner";
import Header from '../Shared/Header';
import Question from './Question/Question';
import QuestionExactMatch from './Question/QuestionExactMatch';
import QuestionTrueFalse from './Question/QuestionTrueFalse';
import QuestionSingleAnswer from './Question/QuestionSingleAnswer';
import QuestionMultipleAnswer from './Question/QuestionMultipleAnswer';
import QuestionRanking from './Question/QuestionRanking';
import QuestionTypingSpeed from './Question/QuestionTypingSpeed';
import QuestionPictureChoice from './Question/QuestionPictureChoice';
import QuestionVideoRecording from './Question/QuestionVideoRecording';
import QuestionFileUpload from './Question/QuestionFileUpload';
import QuestionProgramming from './Question/QuestionProgramming';
import TestsService from '../../services/TestsService';
import AlertError from '../Shared/AlertError';
import Comments from './Question/Comments';
import Score from './Question/Score';

export default function AddEditQuestion (props) {
    const { user } = useUser();
    const [mounted, setMounted] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const history = useHistory();
    const {id, questionId } = useParams();
    const [test, setTest] = useState();
    const [question, setQuestion] = useState({
        A_1: null,
        A_1_Correct: null,
        A_2: null,
        A_2_Correct: null,
        A_3: null,
        A_3_Correct: null,
        A_4: null,
        A_4_Correct: null,
        A_5: null,
        A_5_Correct: null,
        A_6: null,
        A_6_Correct: null,
        A_7: null,
        A_7_Correct: null,
        A_8: null,
        A_8_Correct: null,
        A_9: null,
        A_9_Correct: null,
        A_10: null,
        A_10_Correct: null,
        A_11: null,
        A_11_Correct: null,
        A_12: null,
        A_12_Correct: null,
        CodeLanguage: null,
        Comments: false,
        Q_Title: null,
        QuestionId: null,
        QuestionOrder: null,
        QuestionType: null,
        Score: null,
        Scored: null,
        TestId: null,

    });

    const refs = {
        qtitle: useRef(),
        qanswers: useRef(),
        qscore: useRef(),
      }
    const [errors, setErrors] = useState();
    const [errorList, setErrorList] = useState([]);
  

    useEffect( async () => {
        let search = window.location.search;
        let params = new URLSearchParams(search);

        // find the test to check scoring
        let t = await TestsService.getTest(user.accessToken.token, id);
        setTest(t);

        if (questionId > 0 ) {
            // get question values from DB
            let q = await TestsService.getQuestion(user.accessToken.token, id, questionId);
            setQuestion(q);

        }
        else {
            // new question so preset some values
            let q = question;
            q.QuestionType = params.get('type');
            q.TestId = id;
    
            // predefined values for True/False
            if (params.get('type') == 4) {
                // true false
                q.A_1 = 'True';
                q.A_2 = 'False';
            }

            //console.log('scored')
            // mark the questions as scored if the test is scored
            if (t.Scoring >=1 && params.get('type') != 31) {
                // question instructions cannot be scored
                q.Scored = true;
            }
            setQuestion(q);
        }
        setMounted(true);

        
    }, []);

    const doSave = async(event) => {
        setSubmitting(true);

        let err = [];
        let hasError = false;

        //console.log(question)

        // validate question & answers      
        const validators = {
            title : refs.qtitle.current.isValid(),
            answers: (question.QuestionType == 1 || question.QuestionType == 31 || question.QuestionType == 11 || question.QuestionType == 9) ? {valid: true} : refs.qanswers.current.isValid(), // do not run validators on answers for free text & instructions & video recording & file upload
            score : (test.Scoring == 2 && (question.QuestionType != 1 && question.QuestionType != 31 && question.QuestionType != 11 && question.QuestionType != 9)? refs.qscore.current.isValid() : {valid: true}),

        }
        //console.log(validators)

        if (!validators.title.valid) {
            err.push (validators.title.message)
            hasError = true;            
        }
        if (!validators.answers.valid) {
            err.push (validators.answers.message)
            hasError = true;            
        }
        if (test.Scoring == 2 && !validators.score.valid) {
            err.push (validators.score.message)
            hasError = true;            
        }


        // save question if no validation error
        if (!hasError) {
            if (question.QuestionId)
                await TestsService.updateQuestion(user.accessToken.token, id, question)
            else
                await TestsService.addQuestion(user.accessToken.token, id, question);

            // redirect to questions list
            history.push('/tests/' + id + '/questions')
        }
        else
            window.scrollTo(0, 0)

        setErrorList(err);
        setErrors(hasError);

        setSubmitting(false); 
        //console.log(time)

    }


    return ( 
        <>
        <Header nav='Tests'/>   

        <div className="md:ml-64 flex flex-col flex-1 w-auto ">
            {/* Page title & actions */}
            <div className="relative z-10">
                <div className="py-6 px-4 sm:px-6 md:px-8 w-full fixed bg-title flex md:pr-64">
                    <h1 className="font-semibold text-gray-900 text-xl">{questionId > 0 ? 'Edit' : 'Add'} question</h1>
                </div>
            </div>
            
            {/* Page */}
            { mounted ? 
            <div className="bg-body w-full min-h-screen mx-auto px-4 sm:px-6 md:px-8 pt-20">
                <div className="py-4">

                    {errors ? <AlertError title='Please correct the following errors' list={errorList}/> : ''}

                    <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                        <div className="flex  sm:pt-5">
                            <Question question={question} setQuestion={setQuestion} ref={refs.qtitle}/>
                        </div>                    

                        {/*  answers - except free text & instructions */}
                        <div className="flex flex-wrap sm:pt-5">
                            
                            {/* {questionType == 1 ? '' : ''} */}
                            {question.QuestionType == 2 ? <QuestionSingleAnswer question={question} setQuestion={setQuestion} ref={refs.qanswers}/> : ''}
                            {question.QuestionType == 3 ? <QuestionMultipleAnswer question={question} setQuestion={setQuestion} ref={refs.qanswers}/> : ''} 
                            {question.QuestionType == 4 ? <QuestionTrueFalse question={question} setQuestion={setQuestion} ref={refs.qanswers}/> : ''} 
                            {question.QuestionType == 5 ? <QuestionRanking question={question} setQuestion={setQuestion} ref={refs.qanswers}/> : ''}
                            {question.QuestionType == 6 ? <QuestionExactMatch question={question} setQuestion={setQuestion} ref={refs.qanswers}/> : ''}
                            
                            {question.QuestionType == 7 ? <QuestionPictureChoice question={question} setQuestion={setQuestion} ref={refs.qanswers}/> : ''}
                            {question.QuestionType == 8 ? <QuestionProgramming question={question} setQuestion={setQuestion} ref={refs.qanswers}/> : ''}
                            {question.QuestionType == 9 ? <QuestionFileUpload question={question} setQuestion={setQuestion} ref={refs.qanswers}/> : ''}
                            {question.QuestionType == 10 ? <QuestionTypingSpeed question={question} setQuestion={setQuestion} ref={refs.qanswers}/> : ''}
                            {question.QuestionType == 11 ? <QuestionVideoRecording question={question} setQuestion={setQuestion} ref={refs.qanswers}/> : ''}
                            {question.QuestionType == 31 ? '' : ''}

                        </div>                    

                        {/*  comments - beware some questiontypes do not have comments atm */}
                        {(
                            question.QuestionType == 1 || 
                            question.QuestionType == 2 || 
                            question.QuestionType == 3 || 
                            question.QuestionType == 4 || 
                            question.QuestionType == 5 || 
                            question.QuestionType == 6 || 
                            question.QuestionType == 9 || 
                            question.QuestionType == 10 
                        ) ? 
                        <div className="flex sm:pt-5">
                            <Comments question={question} setQuestion={setQuestion}  /> 
                        </div>                    
                        : ''
                        }

                        {/*  comments - beware some questiontypes do not have comments atm */}
                        {( test.Scoring == 1 || test.Scoring == 2) &&  question.QuestionType != 31 ?
                        <div className="flex sm:pt-5">
                            <Score scoring={test.Scoring} question={question} setQuestion={setQuestion}  ref={refs.qscore}/>
                        </div>                    
                        : ''}

                        <div className='flex sm:pt-2 sm:mt-px'>
                            <button type="button" onClick={doSave} className='items-center flex mr-6 px-3 py-2 border text-sm rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none cursor-pointer'>
                                Submit
                                {submitting ? 
                                <svg className="animate-spin h-5 w-5 mt-1 ml-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                                </svg>                
                                : ''}
                            </button>

                            <Link to={'/tests/' + id + '/questions'} className='items-center flex mr-6 px-3 py-2 border text-sm rounded-md shadow-sm text-white bg-gray-400 hover:bg-gray-700 focus:outline-none cursor-pointer'>Cancel</Link>
                        </div>

                    </div>

                </div>
            </div>
            :
            <div className="bg-body w-full h-screen mx-auto px-4 sm:px-6 md:px-8 pt-20">
            <div className='flex justify-center mt-24'><Loader type="ThreeDots" color="#000000" width={50}/></div>
            </div>
            }

        </div>

        </>
    );


}