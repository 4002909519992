import { CheckCircleIcon } from '@heroicons/react/solid';
import {Link} from 'react-router-dom';

export default function AlertSuccessCandidate(props) {

  return (
    <div className="rounded-md bg-green-300 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <CheckCircleIcon className="h-5 w-5 text-black" aria-hidden="true" />
        </div>
        <div className="ml-3 ">
          <h3 className="text-sm font-medium text-black">{props.title}</h3>
          <div className="text-green-700 mt-2">
            <button type='button' onClick={() => window.location.reload()} className="mr-6 px-3 py-2 border text-sm rounded-md border-none  text-white bg-indigo-600 hover:bg-indigo-700  ">Add another candidate</button>
            <Link to='/candidates' className=" px-3 py-2 border text-sm rounded-md border-none text-white bg-gray-600 hover:bg-gray-700 ">Go back</Link>
          </div>
        </div>
      </div>
    </div>
  )
}
