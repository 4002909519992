import React, {useState, useEffect} from 'react';
import Loader from "react-loader-spinner";
import {FormatDate} from '../../../utils/dates'
import MarkPassed from './MarkPassed';
import ResultSummaryScore from './ResultSummaryScore';

export default function ResultSummary (props) {
    const [mounted, setMounted] = useState(false);
    const [result, setResult] = useState();
    const [timeTaken, setTimeTaken] = useState(props.candidate.TestDuration);

    useEffect( async () => {

        if (props.candidate.Passed == false)
            setResult('Failed');
        else if (props.candidate.Passed == true)
            setResult('Passed');
        else
            setResult('N/A')

        // compute time taken by candidate
        
        if (timeTaken > 0) {
            const durationSpent = props.answers.reduce(function (sum, item) {
                return sum + item.ResponseDuration;
            }, 0);
            const duration = Math.ceil(durationSpent/60);
            setTimeTaken(duration);
        }
        setMounted(true);
    }, [props.candidate]);

    const updatePassed = (correct) => {
        props.updatePassed(props.candidate.CandidateId, correct)
    }    

    return ( 
        <>
            {/* Page */}
            { mounted ? 
            <div className='bg-white mb-4 py-4 px-4 rounded-md'>
                <span className='font-bold text-lg'>RESULT SUMMARY</span>
                <br /> <br />
                Test result: 
                <span className={"inline-flex items-center font-bold rounded px-1 py-0.5  bg-gray-300 text-gray-800 " + (result=='Failed' ? 'bg-red-300 text-red-800' : '') +  (result=='Passed' ? ' bg-green-300 text-green-800' : '')}>{result}</span>  
                {result == 'N/A' && props.public != true? <MarkPassed updatePassed={updatePassed}/> : '' }
                <br />
                Assigned on: {FormatDate(props.candidate.ScheduleDate)}
                <br />
                Taken on: {FormatDate(props.candidate.TestDate)}
                <br />
                Time taken: {timeTaken} {timeTaken > 1 || timeTaken == 0 ? 'minutes' : 'minute' }
                <br /><br />

                {
                        props.tests.map( (test, index) => {
                            
                            return <ResultSummaryScore test={test} key={index} />
                            /* isolate this into a component and decorate with score stats 

                            return (
                                <div key={index} className='mb-2'>
                                    <span className='font-bold text-lg'>{test.TestName}</span>
                                    {test.Scoring !=0 ? 
                                    <>
                                    <br />
                                    <div className="overflow-hidden rounded-full bg-gray-200">
                                        <div className={"h-4 pt-0 mt-0 text-center rounded-full " + (test.TestResult >= test.Pass_Score ? 'bg-green-600' : (test.TestResult && test.TestResult > 0 ? 'bg-red-600' : 'bg-gray-200')) } style={{ width: `${test.TestResult ? test.TestResult : 5}%` }}>
                                            <span className={'text-xs h-4 align-top ' + (test.TestResult > 0 ? 'text-white':'') }>{test.TestResult ? test.TestResult : 0 } %</span>
                                        </div>
                                    </div>                                    
                                    </>
                                    : ''}
                                </div>
                            )
                            */
                        })
                    }                
            </div>
            :
            <div className="bg-body w-full h-screen mx-auto px-4 sm:px-6 md:px-8 pt-20">
            <div className='flex justify-center mt-24'><Loader type="ThreeDots" color="#000000" width={50}/></div>
            </div>
            }

        </>
    );
    
}