import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import {useParams, useHistory} from 'react-router-dom';
import {useUser} from '../UserContext';
import TestsService from '../../services/TestsService';
import CompaniesService from '../../services/CompaniesService';
import Loader from "react-loader-spinner";
import Header from '../Shared/Header';
import { Switch } from '@headlessui/react'
import {classNames} from '../../utils/general'
import AlertError from '../Shared/AlertError';
import AlertSuccess from '../Shared/AlertSuccess';
import CreatableSelect from 'react-select/creatable';


export default function AddTest (props) {
    const { user } = useUser();
    const [mounted, setMounted] = useState(false);
    const history = useHistory();
    const {id } = useParams();
    const [categories, setCategories] = useState ([]);
    const [test, setTest] = useState();
    const [testName, setTestName] = useState();
    const [testCategory, setTestCategory] = useState();
    const [testTimed, setTestTimed] = useState(false);
    const [testDuration, setTestDuration] = useState();
    const [testScoring, setTestScoring] = useState(0);
    const [testPassScore, setTestPassScore] = useState(0);
    const [testQuestionPooling, setTestQuestionPooling] = useState();
    const [testQuestionPoolingNumber, setTestQuestionPoolingNumber] = useState();
    const [testPrivate, setTestPrivate] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const [optionsTime, setOptionsTime] = useState([]);
    const [optionsPass, setOptionsPass] = useState([]);
    const [success, setSuccess] = useState();
    const [errors, setErrors] = useState();
    const [errorList, setErrorList] = useState([]);
    const [currentPlan, setCurrentPlan]= useState();
    
    useEffect( async () => {
        const comp = await CompaniesService.getCompany(user.accessToken.token);
        setCurrentPlan(comp.Subscription);
        if (comp.Subscription == 1 || comp.Subscription == 4) {
            setTestPrivate(1); // make test private if not on premium or ultimate 
        }

        const list = await TestsService.getCategories(user.accessToken.token);
        setCategories(list);

        // generate the time options dynamically
        let options= [];
        let name = '';
        for (let i = 10; i <= 480; i += 5) {
            name = '';
            if (i / 60 >= 1 && i / 60 < 2 ) name = '1 hour';
            if (i / 60 >= 2 ) name = Math.floor(i/60) + ' hours';
            if (Math.floor(i / 60) > 0 && i % 60 > 0) name += ' and ';
            if (i % 60 > 0) name += (i % 60) + ' minutes';
            options.push ({ val: i, name: name })

        }
        setOptionsTime(options)

        // generate pass score
        for (let i=100; i >=1; i-- ) {
            optionsPass.push ({val: i, name: i + '%'})
        }
        setOptionsPass(optionsPass);

        //
        if (id) {
            //console.log('edit test ' + id);
            const ret = await TestsService.getTest(user.accessToken.token, id);
            //console.log(ret)
            if (ret) {
                setTestName(ret.Name);
                setTestCategory(ret.Category);
                setTestTimed(ret.Timed);
                setTestDuration(ret.Duration);
                setTestScoring(ret.Scoring);
                setTestPassScore(ret.Pass_Score);
                setTestQuestionPooling(ret.QuestionPooling);
                setTestQuestionPoolingNumber(ret.QuestionPoolingNumber);
                setTestPrivate(ret.Private ? 1: 0);

            }
            setTest(ret);
        }
        //
        setMounted(true);

    }, []);

    const onSubmit = async() => {
        //console.log(data);
        setSubmitting(true);

        // validation
        const err = validateForm();

        if (err.length > 0 ) {
            // we have errors
            setErrorList(err);
            setErrors(true);

        }
        else {
            // no errors => do the api calls
            let ret;
            if (!id ) {
    
                try {
                    ret = await TestsService.addTest(user.accessToken.token, testName, testCategory, testTimed, testTimed ==true ? testDuration: null, testScoring, testScoring > 0 ? testPassScore : null, testQuestionPooling, testQuestionPooling == 2 ? testQuestionPoolingNumber: null, testPrivate);
                } catch (e) {
                    //console.log(e);
                    setErrors(true);
    
                }
            }
            else 
                try {
                    ret= await TestsService.updateTest(user.accessToken.token, id, testName, testCategory, testTimed, testTimed ==true ? testDuration: null, testScoring, testScoring > 0 ? testPassScore : null, testQuestionPooling, testQuestionPooling == 2 ? testQuestionPoolingNumber: null, testPrivate);
                } catch (e) {
                    setErrors(true);
                }
    
            if (ret) {
                setSuccess(true);
                setErrors(false)
            }
        
        }

        setSubmitting(false);

        window.scrollTo(0, 0)

    }  


    const validateForm = () => {
        let err = [];
        if (!testName || testName =='') err.push('Test name is required');
        if (!testCategory || testCategory =='') err.push('Category is required');
        if (!testQuestionPooling || testQuestionPooling == '') err.push('Number of questions is required')
        if (testTimed && (!testDuration || testDuration == '')) err.push('Duration is required')
        if (testScoring > 0 && (!testPassScore || testPassScore == '')) err.push('Passing score is required')
        if (testQuestionPooling==2 && (!testQuestionPoolingNumber || testQuestionPoolingNumber == '')) err.push('Number of questions is required')
        if (testPrivate === '') err.push('Security is required');
        return err;
    }
    return ( 
        <>
        <Header nav='Tests'/>   

        <div className="md:ml-64 flex flex-col flex-1 w-auto ">
            {/* Page title & actions */}
            <div className="relative">
                <div className="py-6 px-4 sm:px-6 md:px-8 w-full fixed bg-title flex md:pr-64">
                    <h1 className="font-semibold text-gray-900 text-xl">{id ? 'Edit' : 'Add'} test</h1>
                </div>
            </div>
            
            {/* Page */}
            { mounted ? 
            <div className="bg-body w-full min-h-screen mx-auto px-4 sm:px-6 md:px-8 pt-20">
                <div className="py-4">

                    {success ? <AlertSuccess title='Saved successfully' /> : ''}

                    {errors ? <AlertError title='Please correct the following errors' list={errorList}/> : ''}

                    <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                        <div className="flex  sm:pt-5">
                            <label htmlFor="testName" className="w-1/5 font-medium text-gray-700 ">
                                Name
                            </label>
                            <div className="w-3/5">
                                <input id='testName' onChange={(e) => setTestName(e.target.value)} defaultValue={test ? test.Name: ''} className='p-1 rounded-md w-full' required={true} />
                            </div>
                        </div>                    

                        <div className="flex  sm:pt-5">
                            <label htmlFor="testCategory" className="w-1/5 font-medium text-gray-700 ">
                                Category
                            </label>
                            <div className="w-3/5">
                                {/* 
                                <select id='testCategory' defaultValue={test ? test.Category : ''} onChange={(e) => setTestCategory(e.target.value)} className='p-1 rounded-md w-full' >
                                    <option>Select</option>
                                    {categories.map((category, index) => (
                                        <option key={index} >{category.name}</option>
                                        ))}                                    
                                </select>*/}

                                <CreatableSelect 
                                    onChange={(val) => setTestCategory(val ? val.value : '')}
                                    defaultValue={test ? {value: test.Category, label: test.Category }: {value: '', label: ''}}
                                    styles={{
                                        control: (provided, state) => ({
                                            ...provided,
                                            boxShadow: "none",
                                            border: "none",
                                            padding: "0px",
                                            margin: "0px",
                                            color: 'black'
                                            
                                        }),
                                        menu: (provided, state) => ({
                                            ...provided,
                                            border: "none",
                                            boxShadow: "none",
                                            padding: "0px",
                                            margin: "0px",
                                            color: 'black'
                                        }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isFocused && "lightgray",
                                            color: state.isFocused && "black",
                                            padding: "0 0 0 2px"
                                        }),
                                        placeholder: (provided, state) => ({
                                            ...provided,
                                            padding: "0px",
                                            margin: "0px",
                                            color: "black"
                                          }),     
                                          singleValue: (provided, state) => ({
                                            ...provided,
                                            padding: "0px",
                                            margin: "0px",
                                            color: "black"
                                          })            

                                    }}                                
                                    isClearable
                                    options={categories.map((category, index) => (
                                        {value: category.name, label: category.name}
                                        ))}
                                />                                
                            </div>
                        </div>                    

                        <div className="flex  sm:pt-5">
                            <label  className="w-1/5 font-medium text-gray-700 mt-1">
                                Time limit
                            </label>
                            <div className="w-3/5 flex">
                                <div className='mt-1'>
                                <Switch
                                    checked={testTimed}
                                    onChange={() => {setTestTimed(!testTimed); }}
                                    className={classNames(
                                        testTimed ? 'bg-green-600' : 'bg-gray-600',
                                        'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none '
                                    )}
                                    >
                                    <span
                                        aria-hidden="true"
                                        className={classNames(
                                            testTimed ? 'translate-x-5' : 'translate-x-0',
                                        'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                                        )}
                                    />
                                </Switch>
                                </div>
                                <select onChange={(e) => setTestDuration(e.target.value)} defaultValue={test ? test.Duration : ''} className={'ml-4 p-1 rounded-md ' + (testTimed ? '' : 'hidden')}>
                                    <option>Select</option>
                                    {optionsTime.map( (time, index) => (                                       
                                        <option key={index} value={time.val}>{time.name}</option>
                                    ))}

                                </select>

                            </div>
                        </div>                    

                        <div className="flex  sm:pt-5">
                            <label htmlFor="testName" className="w-1/5 font-medium text-gray-700 ">
                                Scoring
                            </label>
                            <div className="w-3/5">
                                <select onChange={(e) => setTestScoring(e.target.value)} defaultValue={test ? test.Scoring : ''} className={'p-1 rounded-md '}>
                                    <option value='0'>No scoring</option>
                                    <option value='1'>Completion percentage</option>
                                    <option value='2'>Assign points to each question</option>

                                </select>
                            </div>
                        </div>  

                        {testScoring > 0 ?
                        <div className="flex  sm:pt-5">
                            <label className="w-1/5 font-medium text-gray-700 ">
                                Passing score
                            </label>
                            <div className="w-3/5">
                                <select onChange={(e) => setTestPassScore(e.target.value)} defaultValue={test ?  test.Pass_Score : ''} className={'p-1 rounded-md '}>
                                    <option>Select</option>
                                    {optionsPass.map( (score, index) => (                                       
                                        <option key={index} value={score.val}>{score.name}</option>
                                    ))}

                                </select>
                            </div>
                        </div>  : '' }

                        <div className="flex  sm:pt-5">
                            <label className="w-1/5 font-medium text-gray-700 ">
                                Number of questions
                            </label>
                            <div className="w-3/5">
                                <select onChange={(e) => setTestQuestionPooling(e.target.value)} defaultValue={test ? test.QuestionPooling : ''} className={'p-1 rounded-md '}>
                                    <option value=''>Select</option>
                                    <option value='1'>Select all questions</option>
                                    <option value='2'>Select by number</option>
                                </select>

                                <input type='text' onChange={(e) => setTestQuestionPoolingNumber(e.target.value)} defaultValue={test ? test.QuestionPoolingNumber : ''} className={'ml-4 p-1 rounded-md w-20 ' + (testQuestionPooling != 2 ? 'hidden' : '')} />
                            </div>
                        </div>  


                        {currentPlan == 1 || currentPlan == 4 ? 
                        <div className="flex  sm:pt-5">
                            <label className="w-1/5 font-medium text-gray-700 ">
                                Private
                            </label>
                            <div className="w-3/5 bg-red-200 rounded-md p-1">
                            This test is private but with a <Link to='/billing' className="text-bold underline text-blue-900">Premium</Link> or <Link to='/billing' className="text-bold underline text-blue-900">Ultimate</Link> account you can create public tests.
                            <Link to='/billing' className="ml-2 text-bold underline text-blue-900">Upgrade</Link> your account today to access this feature.

                            </div>
                        </div>  

                        :
                        <div className="flex  sm:pt-5">
                            <label className="w-1/5 font-medium text-gray-700 ">
                                Security
                            </label>
                            <div className="w-3/5">
                                <select onChange={(e) => setTestPrivate(e.target.value)} defaultValue={test ? (test.Private ? '1': '0') : ''} className={'p-1 rounded-md '}>
                                    <option value=''>Select</option>
                                    <option value='1'>Private access with PIN code</option>
                                    <option value='0'>Public on the web (Open access)</option>
                                </select>

                            </div>
                        </div>  
                        
                        }

                        <div className='flex sm:pt-2 sm:mt-px'>
                            <button type="button" onClick={onSubmit} className='items-center flex mr-6 px-3 py-2 border text-sm rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none cursor-pointer'>
                                Submit
                                {submitting ? 
                                <svg className="animate-spin h-5 w-5 mt-1 ml-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                                </svg>                
                                : ''}
                            </button>

                            <button type='button' onClick={() => history.push('/tests')} className='items-center flex mr-6 px-3 py-2 border text-sm rounded-md shadow-sm text-white bg-gray-400 hover:bg-gray-700 focus:outline-none cursor-pointer'>Cancel</button>

                        </div>

                    </div>

                </div>
            </div>
            :
            <div className="bg-body w-full h-screen mx-auto px-4 sm:px-6 md:px-8 pt-20">
            <div className='flex justify-center mt-24'><Loader type="ThreeDots" color="#000000" width={50}/></div>
            </div>
            }

        </div>

        </>
    );


}