import React, {useState, useEffect, useRef} from 'react';
import { Switch } from '@headlessui/react'
import { classNames } from '../../../utils/general';


export default function Comments (props) {

    useEffect( async () => {
        //console.log('useEffect')
        //console.log(props.question.Comments)
    }, [props.question.Comments]);


    return (
        <>
        <label htmlFor="title" className="w-1/5 font-medium text-gray-700 ">
            Comments
        </label>
        <div className="w-3/5">
            <Switch
                checked={props.question.Comments}
                onChange={() => {
                    let q = {...props.question};
                    //console.log('before : ' + q.Comments)
                    q.Comments = !q.Comments;
                    //console.log('after ' + q.Comments)
                    props.setQuestion(q); 
                }}
                className={classNames(
                    props.question.Comments ? 'bg-green-600' : 'bg-red-600',
                    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none '
                )}
                >
                <span
                    aria-hidden="true"
                    className={classNames(
                    props.question.Comments ? 'translate-x-5' : 'translate-x-0',
                    'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                    )}
                />
            </Switch>
            <p className='text-sm text-gray-400 pt-2'>Allow candidate to add other comments to their answer.</p>

        </div>
    </>

    )
}