import { forwardRef, useImperativeHandle } from 'react';

const QuestionExactMatch = forwardRef((props, ref) => {


    // useImperativeHandle is needed to be able to access isValid function from the parent component
    useImperativeHandle(ref, () => ({

        // Validator for exact answer
        isValid : () => {
            if (props.question.A_1 && props.question.A_1.trim() != '')
            {
                // for answer type = number make sure the value entered is valid number
                if (props.question.A_2 == 'number') {
                    if (isNaN(props.question.A_1)){
                        return {
                            valid: false,
                            message: 'Answer must be a number'
                        }
                    }
                }

                // all is valid
                return {
                    valid: true,
                    message: null
                }
            }
            else            
                return {
                    valid: false,
                    message: 'Answer is required'
                } 
        }
    }));

    return (
    <>
        <div className='w-full flex'>
            <label htmlFor="title" className="w-1/5 font-medium text-gray-700 ">
                Answer
            </label>
            <div className="w-3/5">
                <input id='title' value={props.question.A_1} onChange={(e) =>{
                    let q = {...props.question};
                    q.A_1 = e.target.value;
                    props.setQuestion(q)
                    }} className='p-1 rounded-md w-full' required={true} />
            </div>
        </div>

        <div className='w-full flex sm:pt-10'>
            <label htmlFor="title" className="w-1/5 font-medium text-gray-700 ">
                Format
            </label>
            <div className="w-3/5">
                <select defaultValue={props.question.A_2} onChange={(e) => {
                    let q = {...props.question};
                    q.A_2 = e.target.value;
                    props.setQuestion(q)

                }} className='p-1 rounded-md w-full' required={true}>
                    <option value="text">Text</option> 
                    <option value="number" >Number</option>
                </select>
                <p className='text-sm text-gray-400 pt-2'>Is the answer text or a number? For text, a correct answer must be an exact match. For numbers, different formats representing the same value (e.g., 6 and 6.00) are considered equal.</p>

            </div>
        </div>
    </>
    );
});

export default QuestionExactMatch;