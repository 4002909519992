import React, {useState, useEffect} from 'react';
import {Link, useParams} from 'react-router-dom';
import {useUser} from '../UserContext';
import BillingService from '../../services/BillingService';
import CompaniesService from '../../services/CompaniesService';
import Loader from "react-loader-spinner";
import Header from '../Shared/Header';
var dayjs = require('dayjs')

export default function Receipt (props) {
    const { user } = useUser();
    const {id } = useParams();
    const [company, setCompany] = useState();
    const [payment, setPayment] = useState();
    const [mounted, setMounted] = useState(false);

    const [plans, setPlans] = useState([
      { id: 1, name: 'Standard', description: 'Plan includes: 5 users, 50 candidates per month, private tests, custom subdomain' },
      { id: 2, name: 'Premium', description: 'Plan includes: 10 users, 100 candidates, private, public and private tests, custom subdomain' },
      { id: 3, name: 'Ultimate', description: 'Plan includes: unlimited users, unlimited candidates, code challenges, private & public tests, custom subdomain ' },
    ]);

    useEffect( async () => {
        const comp = await CompaniesService.getCompany(user.accessToken.token);
        setCompany(comp);
        //console.log({comp})
     
        //if (comp.Subscription == 4) {
          // company is on Demand so make this visible
          const ppp = [...plans];
          ppp.push({id:4, name: 'On demand', description: 'Plan includes: 5 users, private tests, custom domains and prepaid credits.'})
          setPlans(ppp);  
        //}
        
        const ret = await BillingService.getPayment(user.accessToken.token, id);
        setPayment(ret);
        // if current plan = on demand then make it visibile in the list
        setMounted(true);
    }, []);


    const getPlanName = (id) => {
      switch (id){
        case "1":
        case 1: 
          return 'Standard';
        case "2":          
        case 2:
          return 'Premium';
        case "3":          
        case 3:
          return 'Ultimate';
        case "4":
        case 4:
          return 'On-demand'
      }

    }

    const getCurrency = (currency) => {
      if (currency.toLowerCase() == 'eur')
        return '€';
      else
        return '$'
    }


    return ( 
        <>
        <Header nav='Billing'/>

        <div className="md:pl-64 flex flex-col flex-1 w-auto">
            {/* Page title & actions */}
            <div className="relative">
                <div className="py-6 px-4 sm:px-6 md:px-8 w-full fixed bg-title flex md:pr-64 shadow-sm">
                    <h1 className="font-semibold text-gray-900 text-xl whitespace-nowrap">View Receipt</h1>
                    {payment && payment.invoice_pdf ? 
                    <div className='w-full flex justify-end relative'>
                        <a href={payment.invoice_pdf} className="absolute items-center mr-6 px-3 py-2 border text-sm rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none ">
                            Download invoice
                        </a>                    
                    </div>
                    : '' }
                </div>
            </div>
            
            {/* Page */}
            {mounted ? 
            <div className="w-full min-h-screen bg-body px-4 sm:px-6 md:px-8 pt-20 shadow-sm">
                <div className="py-4">
                  <p className='text-center'>
                    <img className="h-10 w-auto mx-auto mb-2" src="/favicon-sm-512.png" alt="Skillmeter" />

                      <span className='border-b-2 border-gray-200 text-2xl'>Receipt</span>
                    </p>
                  <p className='mt-10'>We received the payment for your Skillmeter.com subscription. Thanks for your business! </p>
                  <p>Questions? Contact us anytime at <a href='mailto:support@skillmeter.com' className='underline text-blue-600 hover:text-blue-800'>support@skillmeter.com</a></p>

                  <div className="mt-5 ">
                    
                      <div className="py-2 flex border-b-2 border-gray-200">
                        <div className="font-medium text-gray-500 w-1/3">Date</div>
                        <div className=" text-gray-900 w-2/3">
                          <span className="font-bold">{dayjs(payment.PaymentDate).format('YYYY-MM-DD')}</span>
                        </div>
                      </div>

                      <div className="py-2 flex border-b-2 border-gray-200">
                        <div className=" font-medium text-gray-500 w-1/3">Account Billed</div>
                        <div className=" text-gray-900 w-2/3">
                          <span className="font-bold">{company.CompanyName}</span>
                        </div>
                      </div>

                      <div className="py-2 flex border-b-2 border-gray-200">
                        <div className=" font-medium text-gray-500 w-1/3">Item</div>
                        <div className=" text-gray-900 w-2/3">
                          <span className="font-bold">{payment.Description}</span>
                        </div>
                        </div>

                      <div className="py-2 flex border-b-2 border-gray-200">
                          <div className=" font-medium text-gray-500 w-1/3">Amount</div>
                          <div className=" text-gray-900 w-2/3">
                            <span className="font-bold">{payment.Amount} {getCurrency(company.Currency)}</span>
                          </div>
                      </div>                    

                      <div className="py-2 flex border-b-2 border-gray-200">
                          <div className=" font-medium text-gray-500 w-1/3">Charged to</div>
                          <div className=" text-gray-900 w-2/3">
                            <span className="font-bold">{payment.PaymentMethod}</span>
                          </div>
                      </div>   

                    </div>         
                    <div className='mt-4'>* EU customers: VAT not applicable, article 293B of CGI.</div>                             
                    <div className='mt-10 text-2xl text-gray-400 font-bold'>Thank you.</div>                             

                    <div className=' mt-4'>
                        <b>Skillmeter</b> is owned and operated by:
                        <br />
                        ALGORITHMA SAS
                        <br />
                        RCS Registration Number: 882 871 775
                        <br />
                        Address: 2 rue de la Libération, 94130 Nogent sur Marne, France                            
                    </div>
                </div>
            </div>
            :
            <div className="bg-body w-full h-screen mx-auto px-4 sm:px-6 md:px-8 pt-20">
            <div className='flex justify-center mt-24'><Loader type="ThreeDots" color="#000000" width={50}/></div>
            </div>

            }

        </div>


        </>

    );

}