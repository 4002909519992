export function GetRoleName(roleId) {
    let roleName = '';
    switch (roleId) {
        case 1: 
            roleName = 'Recruiter';
            break;
        case 2:
            roleName = 'Admin';
            break;
        case 3:
            roleName = 'Owner';
            break;
        
    }

    return roleName;
}