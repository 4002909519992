import React, {useState, useEffect} from 'react';
import {useUser} from '../UserContext';
import {Link, Redirect} from 'react-router-dom';
import UsersService from '../../services/UsersService';
import Loader from "react-loader-spinner";
import Header from '../Shared/Header';
import AuthenticationService from '../../services/AuthenticationService';


export default function DeleteAccount (props) {
    const { user, setUser } = useUser();
    const [reasons, setReasons] = useState();
    const [mounted, setMounted] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    //console.log(user)
    const onSubmit = async() => {
        setSubmitting(true);

        await UsersService.cancelAccount(user.accessToken.token, reasons);

        // delete cookies
        AuthenticationService.signOut();
        setUser(null);
  
        // redirect to home
        return <Redirect to={'/'} />;

    }

    useEffect( async () => {
        setMounted(true);
    }, []);


    return ( 
        <>
        <Header nav=''/>   

        <div className="md:ml-64 flex flex-col flex-1 w-auto ">
            {/* Page title & actions */}
            <div className="relative">
                <div className="py-6 px-4 sm:px-6 md:px-8 w-full fixed bg-title flex md:pr-64 shadow-sm">
                    <h1 className="font-semibold text-gray-900 text-xl">Delete account</h1>
                </div>
            </div>
            
            {/* Page */}
            { mounted ? 
            <div className="bg-body w-full min-h-screen mx-auto px-4 sm:px-6 md:px-8 pt-20">
                <div className='py-4'>
                    <span className='font-bold'>You are almost done ...</span>
                    <br /> <br />
                    {user.role == 3 ? 
                        <div>
                        If you wish to cancel because your hiring volume does not justify a monthly plan, then you can switch to the <Link to={'/billing?showOndemand=yes'} className='underline text-blue-600 hover:text-blue-800'>On-Demand plan</Link> (prepaid credits, no monthly subscription).
                        <br /> <br />
                        If this is our final goodbye, thank you for trying Skillmeter. Please take a moment to share why Skillmeter wasn't a good fit.
                        </div>
                    :
                        <div>
                        Click the button below to confirm this action. We will remove your account from our system and you will not be able to sign in again.
                        </div>
                    }
                </div>

                <div className="py-4">

                    <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                        {user.role == 3 ? 
                        <div className=" sm:pt-5">
                            <span  className="font-bold text-gray-700 ">
                            Please help us improve Skillmeter by stating the reason for cancelling 
                            </span>
                            <textarea id='reasons' onChange={e => setReasons(e.target.value)} required={true} className='p-1 w-full rounded-md' />
                        </div>                    
                        : ''}
                         <div className='flex sm:pt-2 sm:mt-px'>
                            <button onClick={onSubmit} className='items-center flex mr-6 px-3 py-2 border text-sm rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none cursor-pointer'>
                            Delete account
                            {submitting ? 
                            <svg className="animate-spin h-5 w-5 mt-1 ml-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                            </svg>                
                            : ''}

                            </button>

                            <Link to={'/settings'} className='mr-6 px-3 py-2 border text-sm rounded-md shadow-sm text-black focus:outline-none cursor-pointer'>
                            Nevermind.  Take me back.
                            </Link>

                        </div>

                    </div>    

                </div>
            </div>
            :
            <div className="bg-body w-full h-screen mx-auto px-4 sm:px-6 md:px-8 pt-20">
            <div className='flex justify-center mt-24'><Loader type="ThreeDots" color="#000000" width={50}/></div>
            </div>
            }

        </div>

        </>
    );


}