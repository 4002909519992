import axios from "axios";


class TestsService {

  
    getTests = async (token) => {
        const ret= await axios.get(process.env.REACT_APP_SKILLMETER_API + "/tests", { headers: {"Authorization" : `Bearer ${token}`, "Content-type": "multipart/form-data"} });
        return ret.data;
      }

    getTest = async (token, id) => {
        const ret= await axios.get(process.env.REACT_APP_SKILLMETER_API + "/tests/" + id, { headers: {"Authorization" : `Bearer ${token}`, "Content-type": "multipart/form-data"} });
        return ret.data;
      }

    getCategories = async (token) => {
      const ret= await axios.get(process.env.REACT_APP_SKILLMETER_API + "/tests/categories", { headers: {"Authorization" : `Bearer ${token}`, "Content-type": "multipart/form-data"} });
      return ret.data;
    }
      
    addTest = async (token, name, category, timeLimit, duration, scoring, passScore, questionPooling, questionPoolingNumber, privateTest) => {
      let fd = new FormData();
      fd.append('name', name);
      fd.append('category', category);
      fd.append('timeLimit', timeLimit);
      fd.append('duration', duration);
      fd.append('scoring', scoring);
      fd.append('passScore', passScore);
      fd.append('questionPooling', questionPooling);
      fd.append('questionPoolingNumber', questionPoolingNumber);
      fd.append('privateTest', privateTest);
  
      const ret = await axios.post(process.env.REACT_APP_SKILLMETER_API + "/tests" , fd, { headers: {"Content-Type": "multipart/form-data", "Authorization" : `Bearer ${token}`} });
      return ret;
    }  

    updateTest = async (token, testId, name, category, timeLimit, duration, scoring, passScore, questionPooling, questionPoolingNumber, privateTest) => {
      let fd = new FormData();
      fd.append('name', name);
      fd.append('category', category);
      fd.append('timeLimit', timeLimit);
      fd.append('duration', duration);
      fd.append('scoring', scoring);
      fd.append('passScore', passScore);
      fd.append('questionPooling', questionPooling);
      fd.append('questionPoolingNumber', questionPoolingNumber);
      fd.append('privateTest', privateTest);
  
      const ret = await axios.patch(process.env.REACT_APP_SKILLMETER_API + "/tests/" + testId , fd, { headers: {"Content-Type": "multipart/form-data", "Authorization" : `Bearer ${token}`} });
      return ret;
    }  

    deleteTest = async (testId, token) => {
      const ret= await axios.delete(process.env.REACT_APP_SKILLMETER_API + "/tests/" + testId, { headers: {"Authorization" : `Bearer ${token}`} });
      return ret.data;
    }

    cloneTest = async (testId, token) => {
      const ret= await axios.post(process.env.REACT_APP_SKILLMETER_API + "/tests/" + testId + '/clone', null, { headers: {"Authorization" : `Bearer ${token}`} });
      return ret.data;
    }

    updateCategory = async (token, oldName, newName) => {
      let fd = new FormData();
      fd.append('oldName', oldName);
      fd.append('newName', newName);
  
      const ret = await axios.post(process.env.REACT_APP_SKILLMETER_API + "/tests/category" , fd, { headers: {"Content-Type": "multipart/form-data", "Authorization" : `Bearer ${token}`} });
      return ret;
    }  

    getQuestions = async (token, testId) => {
      const ret= await axios.get(process.env.REACT_APP_SKILLMETER_API + "/tests/" + testId + "/questions", { headers: {"Authorization" : `Bearer ${token}`, "Content-type": "multipart/form-data"} });
      return ret.data;
    }

    getQuestion = async (token, testId, questionId) => {
      const ret= await axios.get(process.env.REACT_APP_SKILLMETER_API + "/tests/" + testId + "/questions/" + questionId, { headers: {"Authorization" : `Bearer ${token}`, "Content-type": "multipart/form-data"} });
      return ret.data;
    }

    addQuestion = async (token, testId, question) => {
      let fd = new FormData();
      fd.append('question', JSON.stringify(question));
  
      const ret = await axios.post(process.env.REACT_APP_SKILLMETER_API + "/tests/" + testId + "/questions" , fd, { headers: {"Content-Type": "multipart/form-data", "Authorization" : `Bearer ${token}`} });
      return ret;
    }  

    updateQuestion = async (token, testId, question) => {
      let fd = new FormData();
      fd.append('question', JSON.stringify(question));
  
      const ret = await axios.patch(process.env.REACT_APP_SKILLMETER_API + "/tests/" + testId + "/questions/" + question.QuestionId , fd, { headers: {"Content-Type": "multipart/form-data", "Authorization" : `Bearer ${token}`} });
      return ret;
    }  

    deleteQuestion = async (testId, questionId, token) => {
      const ret= await axios.delete(process.env.REACT_APP_SKILLMETER_API + "/tests/" + testId + "/questions/" + questionId, { headers: {"Authorization" : `Bearer ${token}`} });
      return ret.data;
    }

    updateQuestionsOrder = async (token, testId, questions) => {
      let fd = new FormData();
      fd.append('array', JSON.stringify(questions));
  
      const ret = await axios.post(process.env.REACT_APP_SKILLMETER_API + "/tests/" + testId + "/questionsorder" , fd, { headers: {"Content-Type": "multipart/form-data", "Authorization" : `Bearer ${token}`} });
      return ret;
    }  

    cloneQuestion = async (token, questionId, testId) => {
      let fd = new FormData();
  
      const ret = await axios.post(process.env.REACT_APP_SKILLMETER_API + "/tests/" + testId + "/questions/" + questionId + '/clone', fd, { headers: {"Content-Type": "multipart/form-data", "Authorization" : `Bearer ${token}`} });
      return ret;
    }  

}

export default new TestsService();
