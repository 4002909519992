import { useState, useEffect, forwardRef, useImperativeHandle } from 'react';


const AnswerPictureChoice = forwardRef((props, ref) => {

    const [correct, setCorrect] = useState("Wrong");

    useEffect( async() => {

        if (
            (props.answer.R_1 == null ? false : props.answer.R_1) == (props.answer.A_1_Correct == null ? false : props.answer.A_1_Correct)  &&
            (props.answer.R_2 == null ? false : props.answer.R_2) == (props.answer.A_2_Correct == null ? false : props.answer.A_2_Correct) &&
            (props.answer.R_3 == null ? false : props.answer.R_3) == (props.answer.A_3_Correct == null ? false : props.answer.A_3_Correct) &&
            (props.answer.R_4 == null ? false : props.answer.R_4) == (props.answer.A_4_Correct == null ? false : props.answer.A_4_Correct) &&
            (props.answer.R_5 == null ? false : props.answer.R_5) == (props.answer.A_5_Correct == null ? false : props.answer.A_5_Correct) &&
            (props.answer.R_6 == null ? false : props.answer.R_6) == (props.answer.A_6_Correct == null ? false : props.answer.A_6_Correct) &&
            (props.answer.R_7 == null ? false : props.answer.R_7) == (props.answer.A_7_Correct == null ? false : props.answer.A_7_Correct) &&
            (props.answer.R_8 == null ? false : props.answer.R_8) == (props.answer.A_8_Correct == null ? false : props.answer.A_8_Correct) &&
            (props.answer.R_9 == null ? false : props.answer.R_9) == (props.answer.A_9_Correct == null ? false : props.answer.A_9_Correct) &&
            (props.answer.R_10 == null ? false : props.answer.R_10) == (props.answer.A_10_Correct == null ? false : props.answer.A_10_Correct) &&
            (props.answer.R_11 == null ? false : props.answer.R_11) == (props.answer.A_11_Correct == null ? false : props.answer.A_11_Correct) &&
            (props.answer.R_12 == null ? false : props.answer.R_12) == (props.answer.A_12_Correct == null ? false : props.answer.A_12_Correct) 
        )
        { 
            setCorrect("Correct"); 
        }
        else {
            if (((props.answer.R_1 == null ? false : props.answer.R_1) == props.answer.A_1_Correct) && props.answer.A_1_Correct == true) setCorrect("Partially correct");
            if (((props.answer.R_2 == null ? false : props.answer.R_2) == props.answer.A_2_Correct) && props.answer.A_2_Correct == true) setCorrect("Partially correct");
            if (((props.answer.R_3 == null ? false : props.answer.R_3) == props.answer.A_3_Correct) && props.answer.A_3_Correct == true) setCorrect("Partially correct");
            if (((props.answer.R_4 == null ? false : props.answer.R_4) == props.answer.A_4_Correct) && props.answer.A_4_Correct == true) setCorrect("Partially correct");
            if (((props.answer.R_5 == null ? false : props.answer.R_5) == props.answer.A_5_Correct) && props.answer.A_5_Correct == true) setCorrect("Partially correct");
            if (((props.answer.R_6 == null ? false : props.answer.R_6) == props.answer.A_6_Correct) && props.answer.A_6_Correct == true) setCorrect("Partially correct");
            if (((props.answer.R_7 == null ? false : props.answer.R_7) == props.answer.A_7_Correct) && props.answer.A_7_Correct == true) setCorrect("Partially correct");
            if (((props.answer.R_8 == null ? false : props.answer.R_8) == props.answer.A_8_Correct) && props.answer.A_8_Correct == true) setCorrect("Partially correct");
            if (((props.answer.R_9 == null ? false : props.answer.R_9) == props.answer.A_9_Correct) && props.answer.A_9_Correct == true) setCorrect("Partially correct");
            if (((props.answer.R_10 == null ? false : props.answer.R_10) == props.answer.A_10_Correct) && props.answer.A_10_Correct == true) setCorrect("Partially correct");
            if (((props.answer.R_11 == null ? false : props.answer.R_11) == props.answer.A_11_Correct) && props.answer.A_11_Correct == true) setCorrect("Partially correct");
            if (((props.answer.R_12 == null ? false : props.answer.R_12) == props.answer.A_12_Correct) && props.answer.A_12_Correct == true) setCorrect("Partially correct");

        }        
        
    }, []); 
        
    return (
    <>
        <b>Answer: <span className={"rounded px-1 py-1 " + ((correct=='Wrong' || correct=='Partially correct')? 'bg-red-300 text-red-800' : '') +  (correct=='Correct' ? ' bg-green-300 text-green-800' : '')}>{correct}</span></b>
        {props.answer.R_1 != null ? <><br /><img src={props.answer.A_1.indexOf('d2mwjjtum67wgo.cloudfront.net') >=0 ? props.answer.A_1 :process.env.REACT_APP_CDN_URL + '/picturechoice/' + props.answer.A_1} className={'mr-4 mb-4 rounded border-none'}/></> : ''}
        {props.answer.R_2 != null ? <><br /><img src={props.answer.A_2.indexOf('d2mwjjtum67wgo.cloudfront.net') >=0 ? props.answer.A_2 :process.env.REACT_APP_CDN_URL + '/picturechoice/' + props.answer.A_2} className={'mr-4 mb-4 rounded border-none'}/></> : ''}
        {props.answer.R_3 != null ? <><br /><img src={props.answer.A_3.indexOf('d2mwjjtum67wgo.cloudfront.net') >=0 ? props.answer.A_3 :process.env.REACT_APP_CDN_URL + '/picturechoice/' + props.answer.A_3} className={'mr-4 mb-4 rounded border-none'}/></> : ''}
        {props.answer.R_4 != null ? <><br /><img src={props.answer.A_4.indexOf('d2mwjjtum67wgo.cloudfront.net') >=0 ? props.answer.A_4 :process.env.REACT_APP_CDN_URL + '/picturechoice/' + props.answer.A_4} className={'mr-4 mb-4 rounded border-none'}/></> : ''}
        {props.answer.R_5 != null ? <><br /><img src={props.answer.A_5.indexOf('d2mwjjtum67wgo.cloudfront.net') >=0 ? props.answer.A_5 :process.env.REACT_APP_CDN_URL + '/picturechoice/' + props.answer.A_5} className={'mr-4 mb-4 rounded border-none'}/></> : ''}
        {props.answer.R_6 != null ? <><br /><img src={props.answer.A_6.indexOf('d2mwjjtum67wgo.cloudfront.net') >=0 ? props.answer.A_6 :process.env.REACT_APP_CDN_URL + '/picturechoice/' + props.answer.A_6} className={'mr-4 mb-4 rounded border-none'}/></> : ''}
        {props.answer.R_7 != null ? <><br /><img src={props.answer.A_7.indexOf('d2mwjjtum67wgo.cloudfront.net') >=0 ? props.answer.A_7 :process.env.REACT_APP_CDN_URL + '/picturechoice/' + props.answer.A_7} className={'mr-4 mb-4 rounded border-none'}/></> : ''}
        {props.answer.R_8 != null ? <><br /><img src={props.answer.A_8.indexOf('d2mwjjtum67wgo.cloudfront.net') >=0 ? props.answer.A_8 :process.env.REACT_APP_CDN_URL + '/picturechoice/' + props.answer.A_8} className={'mr-4 mb-4 rounded border-none'}/></> : ''}
        {props.answer.R_9 != null ? <><br /><img src={props.answer.A_9.indexOf('d2mwjjtum67wgo.cloudfront.net') >=0 ? props.answer.A_9 :process.env.REACT_APP_CDN_URL + '/picturechoice/' + props.answer.A_9} className={'mr-4 mb-4 rounded border-none'}/></> : ''}
        {props.answer.R_10 != null ? <><br /><img src={props.answer.A_10.indexOf('d2mwjjtum67wgo.cloudfront.net') >=0 ? props.answer.A_10 :process.env.REACT_APP_CDN_URL + '/picturechoice/' + props.answer.A_10} className={'mr-4 mb-4 rounded border-none'}/></> : ''}
        {props.answer.R_11 != null ? <><br /><img src={props.answer.A_11.indexOf('d2mwjjtum67wgo.cloudfront.net') >=0 ? props.answer.A_11 :process.env.REACT_APP_CDN_URL + '/picturechoice/' + props.answer.A_11} className={'mr-4 mb-4 rounded border-none'}/></> : ''}
        {props.answer.R_12 != null ? <><br /><img src={props.answer.A_12.indexOf('d2mwjjtum67wgo.cloudfront.net') >=0 ? props.answer.A_12 :process.env.REACT_APP_CDN_URL + '/picturechoice/' + props.answer.A_12} className={'mr-4 mb-4 rounded border-none'}/></> : ''}



        {(props.answer.Comments != null && props.answer.Comments) ? 
        <>
            <br /><br />
            <span className="whitespace-pre-line">
                <b>Comments:</b>
                <br />
                {props.answer.R_Text}
            </span>
        </> : ''}
    </>
    );
});

export default AnswerPictureChoice;