/* This example requires Tailwind CSS v2.0+ */
import { ExclamationIcon } from '@heroicons/react/solid'

export default function AlertError(props) {
  return (
    <div className="rounded-md bg-red-300 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <ExclamationIcon className="h-5 w-5 text-black" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-black">{props.title}</h3>
          {( props.message || ( props.list && props.list.length > 0))?
          <div className="mt-2 text-sm text-red-700">
            {props.message ? props.message: ''}
            {props.list ? 
            <ul>
                {props.list.map((item, index) => (
                <li key={index}>{item}</li>
                ))}              
            </ul> : ''} 
          </div>
          : '' }
        </div>
      </div>
    </div>
  )
}