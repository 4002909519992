import {useState, useEffect} from 'react';
import {useUser} from '../components/UserContext';
import CompaniesService from '../services/CompaniesService';


export default function useCompany () {
    const { user } = useUser();
    const [company, setCompany] = useState(null);


    useEffect( async () => {
        const comp = await CompaniesService.getCompany(user.accessToken.token);
        setCompany(comp);
        //console.log(comp)


    }, []);

    return company;


}    
