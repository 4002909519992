import React, {useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import {useUser} from '../UserContext';
import UsersService from '../../services/UsersService';
import Loader from "react-loader-spinner";
import Header from '../Shared/Header';
import AlertError from '../Shared/AlertError';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {useHistory} from 'react-router-dom';


export default function AddEditUser (props) {
    const { user } = useUser();
    const [mounted, setMounted] = useState(false);
    const validationSchema = Yup.object().shape({
        firstName: Yup.string(),
        email: Yup.string()
            .required('Email is required')
            .matches(/\S+@\S+\.\S+/, 'Email is invalid'),
        role: Yup.string()
            .required('Role is required')
    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    const {register, formState: {errors}, handleSubmit} = useForm(formOptions);
    const history = useHistory()
    const {id } = useParams();
    const [person, setPerson] = useState();
    const [apiError, setApiError] = useState()

    //console.log({errors})


    const onSubmit = async(data) => {
        //console.log(data);

        let isError = false;
        if (!person) {
            const x = await UsersService.addUser(user.accessToken.token, data.firstName, data.email, data.role);
            if (x.error) {
                setApiError(x.error)
                isError = true
            }
        }
        else {
            await UsersService.updateUser(user.accessToken.token, person.UserId, data.firstName, data.email, data.role);
        }

        if (!isError) {
            history.push('/team'); 
        }

    }



    useEffect( async () => {
        //console.log({user})
        if (id) {
            const u = await UsersService.getUser(user.accessToken.token, id);
            setPerson(u);
            //console.log(u)
        }
        setMounted(true);
    }, []);

  
    return ( 
        <>
        <Header nav='Team'/>   

        <div className="md:ml-64 flex flex-col flex-1 w-auto ">
            {/* Page title & actions */}
            <div className="relative">
                <div className="py-6 px-4 sm:px-6 md:px-8 w-full fixed bg-title flex md:pr-64 shadow-sm">
                    <h1 className="font-semibold text-gray-900 text-xl">{id ? 'Edit': 'Add'} user</h1>
                </div>
            </div>
            
            {/* Page */}
            { mounted ? 
            <div className="bg-body w-full min-h-screen mx-auto px-4 sm:px-6 md:px-8 pt-20">
                <div className="py-4">

                    {(errors?.email || errors?.role)  ? <AlertError title='Your form has errors' list={[errors.email?.message, errors.role?.message]}/> : ''}
                    {apiError? <AlertError title='Error executing the request' list={[apiError]}/> : ''}

                    <form onSubmit={handleSubmit(onSubmit)}>

                    <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                        <div className="flex  sm:pt-5">
                            <label htmlFor="firstName" className="w-1/5 font-medium text-gray-700 sm:mt-px sm:pt-2">
                                First name
                            </label>
                            <div className="w-3/5">
                                <input id='firstName' {...register("firstName", {value : (person ? person.FirstName : "")})} placeholder="First name" className='p-1 rounded-md w-full' />

                            </div>
                        </div>                    
                        <div className="flex  sm:pt-5">
                            <label htmlFor="email" className="w-1/5 font-medium text-gray-700 sm:mt-px sm:pt-2">
                                Email
                            </label>
                            <div className="w-3/5">
                                <input id='email' {...register("email", { value:(person ? person.Email : "")})} placeholder="Email" className='p-1 rounded-md w-full' />
                            </div>
                        </div>                    
                        <div className="flex  sm:pt-5">
                            <label htmlFor="role" className="w-1/5 font-medium text-gray-700 sm:mt-px sm:pt-2 ">
                                Role
                            </label>
                            <div className="w-3/5">
                            <select id='role' {...register("role", {value: (person ? person.Role : '')})} className='p-1 rounded-md w-full'>
                                <option value=""></option>
                                <option value="1">Recruiter</option>
                                <option value="2">Admin</option>
                                <option value="3">Owner</option>
                            </select>                                   
                            <p className='text-sm text-gray-400 mt-2'>
                            <b>Owner</b> - can manage tests/candidates/team/company/billing
                            <br /><b>Admin</b> - can manage tests/candidates/team
                            <br /><b>Recruiter</b> - can manage tests/candidates                                
                            </p>

                            </div>
                        </div>                    

                        <div className='flex sm:pt-2 sm:mt-px'>
                    <input type="submit" className='items-center mr-6 px-3 py-2 border text-sm rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none cursor-pointer' value='Submit' />
                    <input type="button" onClick={() => history.push('/team')} className='items-center mr-6 px-3 py-2 border text-sm rounded-md shadow-sm text-white bg-gray-400 hover:bg-gray-700 focus:outline-none cursor-pointer' value='Cancel' />
                    </div>

                    </div>    
                    </form>


                </div>
            </div>
            :
            <div className="bg-body w-full h-screen mx-auto px-4 sm:px-6 md:px-8 pt-20">
            <div className='flex justify-center mt-24'><Loader type="ThreeDots" color="#000000" width={50}/></div>
            </div>
            }

        </div>

        </>
    );


}