import React, {useState} from 'react';
import ConfirmDelete from '../Shared/ConfirmDelete';
import {GetRoleName} from '../../utils/users'
import {useUser} from '../UserContext';

export default function UserCard (props) {
    const { user } = useUser();

    const [del, setDel] = useState(false);

    const doDelete = (id) => {
        props.deleteUser(id)
    }

    return (
        <>
        <div className='bg-white mb-4 py-4 px-4 rounded-md grid grid-cols-3'>
            <div>
                <span>{props.email}</span>
            </div>
            <div>
                <span>{GetRoleName(props.role)}</span>
            </div>

            
            <div className='content-end text-right'>
                {user.role > 1 ?
                    <>
                    <a href={"/team/user/" + props.userId} className="text-indigo-600 hover:text-indigo-900">Edit</a>                    
                    {props.userId != user.userId ? 
                    <button onClick={() => setDel(true)} className="ml-4 text-indigo-600 hover:text-indigo-900 ">Delete</button>
                    : ''}
                    </>
                    : ''}
            </div>           

        </div>
        <ConfirmDelete open={del} setOpen={setDel} id={props.userId} doDelete={doDelete} object='user'/>

        </>
    );
}