import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {QuestionLabel} from '../../utils/questions'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline'
import ConfirmDelete from '../Shared/ConfirmDelete';

export default function QuestionCard (props) {
    const [showTitle, setShowTitle]= useState(props.showTitles);
    const [del, setDel] = useState(false);

    //console.log(props.showTitles)

    const doDelete = () => {
        props.deleteQuestion(props.questionId)
    }
    
    useEffect( () => {
        setShowTitle(props.showTitles);
    }, [props.showTitles]);

    return (
        <>
        <div id={props.questionId} className='bg-white mb-4 py-4 px-4 rounded-md grid grid-cols-2' draggable={true} onDragOver={(e) => e.preventDefault()} onDragStart={props.handleDrag} onDrop={props.handleDrop}>
            <div className=''>
                <div className='flex'>
                    <span className='font-bold'>Question {props.questionOrder}</span><span className='ml-1'>({QuestionLabel(props.questionType, props.scored)})</span> 
                    <button onClick={()=>setShowTitle(!showTitle)} className='ml-1'>
                        {showTitle ? 
                            <ChevronUpIcon className='h-6 w-6'/>
                            :
                            <ChevronDownIcon className='h-6 w-6'/>
                        }
                    </button>
                </div>
                <div className={'mt-2 ' + (showTitle ? 'block' : 'hidden')} dangerouslySetInnerHTML={{ __html: props.title }} >

                </div>
            </div>

            
            <div className='content-end right-0 w-full justify-end text-right'>
                <Link to={"/tests/" + props.testId + "/questions/edit/" + props.questionId} className="text-indigo-600 hover:text-indigo-900 ">Edit</Link>
                {/*<Link to={"/tests/" + props.testId + "/questions/preview/" + props.questionId} className="ml-4 text-indigo-600 hover:text-indigo-900 ">Preview</Link>*/}
                <button onClick={() => props.cloneQuestion(props.questionId)} className="ml-4 text-indigo-600 hover:text-indigo-900 ">Clone</button>
                <button onClick={() => setDel(true)} className="ml-4 text-indigo-600 hover:text-indigo-900 ">Delete</button>

            </div>            
            
        </div>

        <ConfirmDelete open={del} setOpen={setDel} doDelete={doDelete} id={props.questionId} object='question'/>
        </>
    );
}