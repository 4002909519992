import axios from "axios";
import Cookies from 'js-cookie';


class AuthenticationService {

  
  getMagicLink = async (email) => {
    if (email.trim() === '') return "OK";

    return axios.post(process.env.REACT_APP_SKILLMETER_API + "/users/send-magic-link", {email})
      .then(() => {
        return "OK";
      })
      .catch(err => {
        console.log(err);
        throw err;
      });
  }

  signIn = async (token) => {
    //console.log('signing in with token magic: ' + token);
    return axios.post(process.env.REACT_APP_SKILLMETER_API + "/users/sign-in", null, {headers: {"Authorization" : `Bearer ${token}`}})
      .then((response) => {
        // check if response.data.accessToken is valid??
        return response.data;
      })
      .catch(err => {
        /*
        if (err.response.status == 401) {
          if (err.response.data == 'Account suspended')
          {
            
          }
        }*/
        //console.log(err);
        throw err;
      });
  }

  signUp = (firstName, email, company, plan, planName, currency) => {
    let fd = new FormData();
    fd.append('firstName', firstName);
    fd.append('email', email);
    fd.append('company', company);
    fd.append('plan', plan);
    fd.append('planName', planName);
    fd.append('currency', currency);

    return axios.post(process.env.REACT_APP_SKILLMETER_API + "/users/sign-up", fd, { headers: {'Content-Type': 'multipart/form-data' }})
      .then(response => {
        return response.data;
      })
      .catch(err => {
        //console.log(err.response.status);
        //console.log(err.response.data);

        throw err;
      });
}

  signOut() {
    Cookies.remove('skillmeterUser');
    //localStorage.removeItem("user");
  }

  getCurrentUser() {
    try {
      //return JSON.parse(localStorage.getItem('user'));;
      return JSON.parse(Cookies.get('skillmeterUser'));

    } catch (err) {
      //console.log("cannot retrieve user from localstorage")
      return null;
    }
  }


  saveCurrentUser(data) {
    // same site cookie (below).  Need to look into cross site cookie ? subdomain to subdomain ?
    //Cookies.set('skillmeterUser', JSON.stringify(data), { expires: 30, sameSite: 'None', secure: true }); // same site = none & secure = true to allow cross domain cookies
    Cookies.set('skillmeterUser', JSON.stringify(data), { expires: 13, domain: '.skillmeter.com' })
    Cookies.set('skillmeterUser', JSON.stringify(data), { expires: 13, domain: 'localhost' })



    //localStorage.setItem("user", JSON.stringify(data));

  }
  checkEmail(email) {
    return axios.post(process.env.REACT_APP_SKILLMETER_API + "/auth/check-email",  { email})
    .then ( (res) => {
        return res.data;
    });

  }

}

export default new AuthenticationService();
