import React, {useState, useEffect} from 'react';
import {Link, useHistory} from 'react-router-dom';
import Header from '../Shared/Header';
import {useUser} from '../UserContext';
import {useCompany} from '../CompanyContext';
import CandidatesService from '../../services/CandidatesService';
import Loader from "react-loader-spinner";
//import CompaniesService from '../../services/CompaniesService';
import AlertTrialExpired from '../Shared/AlertTrialExpired';
import AuthenticationService from '../../services/AuthenticationService';
import UsersService from '../../services/UsersService';


import {
  UsersIcon,
  AcademicCapIcon,
  HashtagIcon,
  DocumentTextIcon
} from '@heroicons/react/outline'

//const AlertTrialExpired = React.lazy(() => import('../Shared/AlertTrialExpired'));

export default function Dashboard (props) {
  const history = useHistory();
  const { user, setUser } = useUser();
  const { company } = useCompany();
  const [usersCount, setUsersCount] = useState(0);
  const [candidatesCountByUser, setCandidatesCountByUser] = useState(0);
  const [testsCountByUser, setTestsCountByUser] = useState(0);
  const [candidatesCountByCompany, setCandidatesCountByCompany] = useState(0);
  //const [company, setCompany] = useState();
  const [mounted, setMounted] = useState(false);
  const [planLimits, setPlanLimits] = useState({candidates:'', users:''});

    useEffect( async () => {

      const verify = await UsersService.verify(user.accessToken.token);
      if (verify == 'NOK') {
        AuthenticationService.signOut();
        setUser(null);
        history.push('/sign-in');
        window.location.reload();      
  
      }

      const users = await UsersService.getUsers(user.accessToken.token);
      setUsersCount(users.length);      

      const testsByUser = await CandidatesService.getTestsCountByUser(user.accessToken.token);
      setTestsCountByUser(testsByUser);

      const candidatesByUser = await CandidatesService.getCandidatesCountByUser(user.accessToken.token);
      setCandidatesCountByUser(candidatesByUser);

      const candidatesByCompany = await CandidatesService.getCandidatesCountByCompany(user.accessToken.token);
      setCandidatesCountByCompany(candidatesByCompany);      
      
      //const comp = await CompaniesService.getCompany(user.accessToken.token);
      //setCompany(comp);

      // calculate limits
      /*
      let limits = {
        candidates:'',
        users:''
      }
      
      if (company)
      switch (company.Subscription) {
        case 1: 
          limits.candidates = ' / 50';
          limits.users = ' / 5';
          break;

        case 2:
          limits.candidates = ' / 100';
          limits.users = ' / 10';          
          break;

        case 3:
          limits.candidates = <span> / &infin;</span>;
          limits.users = <span> / &infin;</span>;          
          break;

      }
      setPlanLimits(limits); */
      setMounted(true);

    }, []);

    useEffect( async () => {

      //const comp = await CompaniesService.getCompany(user.accessToken.token);
      //setCompany(comp);

      // calculate limits
      let limits = {
        candidates:'',
        users:''
      }
      
      if (company)
      switch (company.Subscription) {
        case 1: 
          limits.candidates = ' / 50';
          limits.users = ' / 5';
          break;

        case 2:
          limits.candidates = ' / 100';
          limits.users = ' / 10';          
          break;

        case 3:
          limits.candidates = <span> / &infin;</span>;
          limits.users = <span> / &infin;</span>;          
          break;

      }
      setPlanLimits(limits);


    }, [company]);

    return ( 
        <>
        <Header nav='Dashboard'/>

        <div className="md:pl-64 flex flex-col flex-1">
            <div className="relative">
              <div className="py-6 px-4 sm:px-6 md:px-8 w-full fixed bg-title shadow-sm">
                <h1 className="font-semibold text-gray-900 text-xl">Dashboard</h1>
              </div>

            {/* Page */}
            { mounted ? 

              <div className="bg-body min-h-screen mx-auto px-4 sm:px-6 md:px-8 pt-20">
                <div className="py-4">

                {company.SubscriptionStartDate == null && company.Subscription != 4 ?
                <div>
                  <AlertTrialExpired title='YOUR TRIAL PERIOD HAS EXPIRED!' message='Please update your billing info so you can continue to use the service.' subscription={company.Subscription} currency={company.Currency.toLowerCase()}/>
                </div> 
                : ''  
              }
                <div>
                  <h3 className="text-lg leading-6 font-medium text-gray-900">Test Center</h3>
                  <div className="mt-5"><a href={"https://" + company.SiteName + ".skillmeter.com"} className='underline text-blue-600 hover:text-blue-800' target='_blank'>{"https://" + company.SiteName + ".skillmeter.com"}</a></div>
                </div>

                <div>
                  <h3 className="mt-8 text-lg leading-6 font-medium text-gray-900">My Activity</h3>
                  <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
                      <div  className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
                      <div className="absolute rounded-md bg-blue-600 p-3">
                        <DocumentTextIcon className="h-8 w-8 text-white" aria-hidden="true" />
                      </div>
                        <dt className="ml-16 text-sm font-medium text-gray-500 truncate">Tests taken this month</dt>
                        <dd className="ml-16 mt-1 text-3xl font-semibold text-gray-900">{testsCountByUser}</dd>
                      </div>

                      <div  className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
                        <div className="absolute rounded-md bg-blue-600 p-3">
                          <AcademicCapIcon className="h-8 w-8 text-white" aria-hidden="true" />
                        </div>                        
                        <dt className="ml-16 text-sm font-medium text-gray-500 truncate">Candidates added this month</dt>
                        <dd className="ml-16 mt-1 text-3xl font-semibold text-gray-900">{candidatesCountByUser}</dd>
                      </div>

                  </dl>
                </div>

                <div>
                  <h3 className="mt-8 text-lg leading-6 font-medium text-gray-900">Plan Consumption</h3>
                  <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
                      <div  className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
                        <div className="absolute rounded-md bg-blue-600 p-3">
                            <AcademicCapIcon className="h-8 w-8 text-white" aria-hidden="true" />
                          </div>                        

                        <dt className="ml-16 text-sm font-medium text-gray-500 truncate">All candidates this month</dt>
                        <dd className="ml-16 mt-1 text-3xl font-semibold text-gray-900">{candidatesCountByCompany}{planLimits.candidates}</dd>
                      </div>

                      <div  className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
                        <div className="absolute rounded-md bg-blue-600 p-3">
                          <UsersIcon className="h-8 w-8 text-white" aria-hidden="true" />
                        </div>                        

                        <dt className="ml-16 text-sm font-medium text-gray-500 truncate">Users</dt>
                        <dd className="ml-16 mt-1 text-3xl font-semibold text-gray-900">{usersCount}{planLimits.users}</dd>
                      </div>

                      {company.Subscription === 4 ? 
                      <div  className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
                      <div className="absolute rounded-md bg-blue-600 p-3">
                        <HashtagIcon className="h-8 w-8 text-white" aria-hidden="true" />
                      </div>

                      <dt className="ml-16 text-sm font-medium text-gray-500 truncate">Credits left  <Link to={'/billing'} className='bg-green-200 px-1 py-1 rounded-md hover:text-gray-800'>Get more</Link></dt>
                      <dd className={"ml-16 mt-1 text-3xl font-semibold " + (company.Credits && company.Credits <= 0 ? "text-red-900" :"text-gray-900" )  }>{company.Credits ? company.Credits : 0}</dd>
                    </div>

                      : ''}

                  </dl>
                </div>

                </div>
            </div>
            :
            <div className="bg-body w-full h-screen mx-auto px-4 sm:px-6 md:px-8 pt-20">
            <div className='flex justify-center mt-24'><Loader type="ThreeDots" color="#000000" width={50}/></div>
            </div>
            }

            </div>
        </div>

        </>

    );
}