export function QuestionTypeLabel(id) {

    switch (id) {
        case 1: return 'Free text';
        case 2: return 'Single answer';
        case 3: return 'Multiple answers';
        case 4: return 'True/False';
        case 5: return 'Ranking';
        case 6: return 'Exact match';
        case 7: return 'Picture choice';
        case 8: return 'Programming';
        case 9: return 'File upload';
        case 10: return 'Typing speed';
        case 11: return 'Video recording';
        case 31: return 'Instructions';

    }
}

export function QuestionLabel(questionType, scored) {
    let ret = QuestionTypeLabel(questionType).toUpperCase();

    if (questionType != 31) {
        
        if (scored) 
            ret +=  ', SCORED';
        else
            ret += ', NOT SCORED';
    }

    return ret;
}

export const QuestionType =[
    {id: 1, name: "Blank", title:"Freetext / Essay", desc: "Question with free text answer."},
    {id: 2, name: "SingleAnswer", title: "Multiple choice - single answer", desc: "Question with one correct answer out of up to 12 possible values."},
    {id: 3, name: "MultipleAnswer", title: "Multiple choice - multiple answers", desc: "Question with multiple correct answers out of up to 12 possible values."},
    {id: 4, name: "TrueFalse", title: "True / False", desc: "Question with one possible answer True or False."},
    {id: 5, name: "Ranking", title: "Ranking", desc: "Question with unique weight for each answer choice."},
    {id: 6, name: "ExactMatch", title: "Exact Match", desc: "Question that requires exact spelling of the answer."},
    {id: 7, name: "PictureChoice", title: "Picture Choice", desc: "Question with multiple correct answers as pictures."},
    {id: 8, name: "Programming", title: "Programming", desc: "Question that allows solving a programming challenge."},
    {id: 9, name: "FileUpload", title: "File Upload", desc: "Question that allows uploading a file as answer"},
    {id: 10, name: "TypingSpeed", title: "Typing Speed", desc: "Question that measures the typing speed and accuracy."},
    {id: 11, name: "VideoRecording", title: "Video Recording", desc: "Question that records a video message."},
    {id: 31, name: "Instructions", title: "Instructions", desc: "Static text to provide various instructions."}
]