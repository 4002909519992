import { forwardRef, useImperativeHandle } from 'react';

const QuestionTrueFalse = forwardRef((props, ref) => {


    // useImperativeHandle is needed to be able to access isValid function from the parent component
    useImperativeHandle(ref, () => ({

        // Validator there's one correct answer
        isValid : () => {
            if (props.question.A_1_Correct != null &&  props.question.A_2_Correct != null)
                return {
                    valid: true,
                    message: null
                }
            else            
                return {
                    valid: false,
                    message: 'Select the correct answer'
                } 
        }
    }));



    return (
    <>
        <div className="w-1/5 font-medium text-gray-700 ">
            Answers
        </div>

        <div className='w-full'>
            <div className='w-full flex mb-1'>

                <div className='w-1/5 text-right'>
                    <input type='radio' name={'answer'} className='mr-2 focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300' value={'A_1'} checked={props.question['A_1_Correct'] == true} onChange={e => {
                        let q = {...props.question};
                        q.A_1_Correct = true;
                        q.A_2_Correct = false;
                        props.setQuestion(q)

                    }} />
                </div>
                <div className='w-3/5'>
                    <textarea className={'p-1 rounded-md w-full ' + (props.question['A_1_Correct'] == true ? 'bg-green-100' : '')} 
                    value={props.question['A_1']}
                    onChange={e => {
                        let q = {...props.question};
                        q['A_1'] = e.target.value;

                        props.setQuestion(q)
                    }                
                    }/>

                </div>
            </div>
            <div className='w-full flex mb-1'>

                <div className='w-1/5 text-right'>
                    <input type='radio' name={'answer'} className='mr-2 focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300' value={'A_2'}  checked={props.question['A_2_Correct'] == true} onChange={e => {
                        let q = {...props.question};
                        q.A_1_Correct = false;
                        q.A_2_Correct = true;
                        props.setQuestion(q)

                    }} />
                </div>
                <div className='w-3/5'>
                    <textarea className={'p-1 rounded-md w-full ' + (props.question['A_2_Correct'] == true ? 'bg-green-100' : '')} 
                    value={props.question['A_2']}
                    onChange={e => {
                        let q = {...props.question};
                        q['A_2' ] = e.target.value;
                        props.setQuestion(q)
                    }                
                    }/>
                </div>
            </div>
        </div>

    </>
    );
});

export default QuestionTrueFalse;