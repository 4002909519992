import React, {useState, useEffect} from 'react';
import CandidatesService from '../../../services/CandidatesService';
import { useParams } from 'react-router-dom';
import Loader from "react-loader-spinner";
import ResultSummary from './ResultSummary';
import Answer from '../Answers/Answer';

export default function PublicResult (props) {
    const [mounted, setMounted] = useState(false);
    const {token } = useParams();
    const [candidate, setCandidate] = useState([]);
    const [answers, setAnswers] = useState([]);
    const [tests, setTests] = useState([]);

    useEffect( async () => {
        const retC = await CandidatesService.getCandidatePublic(token);   
        setCandidate(retC);
        //console.log(retC)
        const retA = await CandidatesService.getCandidateAnswersPublic(token);        
        setAnswers(retA);
        //console.log(retA);
        const retT = await CandidatesService.getTestsDetailsPublic(token);
        //console.log(retT);
        setTests(retT);
        setMounted(true);
    }, []);


    return ( 
        <>

        <div className="flex flex-col flex-1 w-auto ">
            { mounted ? 
            <>
            {/* Page title & actions */}
            <div className="">
                <div className="py-6 px-4 sm:px-6 md:px-8 w-full bg-title shadow-sm text-center">
                <img className="h-6 w-auto mx-auto mb-10" src="/sm-logo.png" alt="Skillmeter" />

                    <h1 className="font-bold text-gray-900 text-2xl uppercase">{candidate.First_Name} {candidate.Last_Name} 's results</h1>
                </div>
            </div>
            
            {/* Page */}
            <div className="bg-body w-full min-h-screen mx-auto px-4 sm:px-6 md:px-8 pt-8">

                <ResultSummary candidate={candidate} answers={answers} tests={tests} public={true} />

                <div className='font-bold text-lg py-4 px-4'>ANSWERS</div>

                <div className="py-4">

                    {
                        answers.map( (answer, index) => {
                            return (
                                <Answer answer={answer} index={index} key={index} publicMode={true}/>
                            )
                        })
                    }
                </div>

                {candidate.Feedback ? 
                    <div className='py-4'>
                        <b>CANDIDATE FEEDBACK</b>
                        <br /><br />    
                        <b>Difficulty Level:</b> {candidate.FeedbackLevel == 'E' ? 'Easy':''}{candidate.FeedbackLevel == 'M' ? 'Medium':''}{candidate.FeedbackLevel == 'H' ? 'Difficult':''}
                        {candidate.FeedbackText ? 
                        <>
                            <br />
                            <span className="whitespace-pre-line">
                                <b>Comments:</b>
                                {candidate.FeedbackText}
                            </span>                            
                        </>
                        : ''
                        } 
                    </div>
                    : ''
                }
            </div>
            </>
            :
            <div className="bg-body w-full h-screen mx-auto px-4 sm:px-6 md:px-8 pt-20">
            <div className='flex justify-center mt-24'><Loader type="ThreeDots" color="#000000" width={50}/></div>
            </div>
            }

        </div>

        </>
    );
    
}