import { useState, useEffect, forwardRef, useImperativeHandle } from 'react';

const AnswerTypingSpeed = forwardRef((props, ref) => {

    const [correct, setCorrect] = useState("Wrong");
    
    useEffect( async() => {
        //console.log(props.answer)

        //if (props.answer.Scored && props.answer.A_1_Correct == true) setCorrect("Correct");
        if (props.answer.R_1_Text != null && props.answer.R_2_Text != null && parseFloat(props.answer.R_1_Text) >= parseFloat(props.answer.A_1) && parseFloat(props.answer.R_2_Text) >= parseFloat(props.answer.A_2))
            {setCorrect("Correct");}


    }, []); 
        
    return (
    <>
        <b>Answer: <span className={"rounded px-1 py-1 " + (correct=='Wrong' ? 'bg-red-300 text-red-800' : '') +  (correct=='Correct' ? ' bg-green-300 text-green-800' : '')}>{correct}</span></b>
        <br />
        <b>Typed text:</b> <span className='whitespace-pre-line'>{props.answer.R_Text}</span>
        <br />
        <b>Speed:</b> {props.answer.R_1_Text !=null ? props.answer.R_1_Text + " wpm" : "not defined"}
        <br />
        <b>Accuracy:</b> {props.answer.R_2_Text != null ? props.answer.R_2_Text + " %" : "not defined"}


        {(props.answer.Comments != null && props.answer.Comments) ? 
        <>
            <br /><br />
            <span className="whitespace-pre-line">
                <b>Comments:</b>
                <br />
                {props.answer.R_Text}
            </span>
        </> : ''}
    </>
    );
});


export default AnswerTypingSpeed;