import React, {useState, useEffect} from 'react';
import CandidatesService from '../../../services/CandidatesService';
import {useUser} from '../../UserContext';

export default function ResultSummaryScore (props) {
    const { user } = useUser();
    const [mounted, setMounted] = useState(false)
    const [topCandidates, setTopCandidates] = useState(0)
    const [averageScore, setAverageScore] = useState(0)
    const [showStats, setShowStats] = useState(false)

    useEffect( async () => {
        if (props.test.TestResult != null) // retrieve advanced stats when score is not null
        {
            setShowStats(true)
            const retC = await CandidatesService.getGlobalResult(user.accessToken.token, props.test.TestId, props.test.TestResult);
            setTopCandidates(retC.Percentage)
            setAverageScore(retC.Average)
    
        }

        setMounted(true)

    }, []);


    if (mounted)
    return ( 
        <div className='mb-2'>
            <span className='font-bold text-lg'>{props.test.TestName}</span>
            {props.test.Scoring !=0 ? 
            <>
            <br />
            <div className="overflow-hidden rounded-full bg-gray-200">
                <div className={"h-4 pt-0 mt-0 text-center rounded-full " + (props.test.TestResult >= props.test.Pass_Score ? 'bg-green-600' : (props.test.TestResult && props.test.TestResult > 0 ? 'bg-red-600' : 'bg-gray-200')) } style={{ width: `${props.test.TestResult ? props.test.TestResult : 5}%` }}>
                    <span className={'text-xs h-4 align-top ' + (props.test.TestResult > 0 ? 'text-white':'') }>{props.test.TestResult ? props.test.TestResult : 0 } %</span>
                </div>
            </div>
            {showStats ? 
            <div className='text-center'>
                <span className='text-gray-600'>Scored better than {topCandidates}% of the candidates (Average score is {averageScore})</span>
            </div>                                    
            : ''}
            </>
            : ''}
        </div>
    );
    else
        return '';
    
}