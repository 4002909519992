import { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import parse from "html-react-parser";

const AnswerVideoRecording = forwardRef((props, ref) => {

    const [correct, setCorrect] = useState("Wrong");
    
    useEffect( async() => {
        //console.log(props.answer)
        if (props.answer.Scored && props.answer.A_1_Correct == true) 
            setCorrect("Correct"); 
        else 
            setCorrect('Wrong');

        if (!props.answer.Scored || props.answer.A_1_Correct == null) setCorrect("");



    }, []); 
        
    return (
    <>
        <b>Answer: <span className={"rounded px-1 py-1 " + (correct=='Wrong' ? 'bg-red-300 text-red-800' : '') +  (correct=='Correct' ? ' bg-green-300 text-green-800' : '')}>{correct}</span></b>
        <br />
        { (props.answer.R_Text == null || props.answer.R_Text == '') ?
            <div></div>
            :
            <video src={"https://s3.amazonaws.com/cdn.skillmeter.com/test_answers/" + props.answer.R_Text} width="320" height="240" controls/>

        }



        {(props.answer.Comments != null && props.answer.Comments) ? 
        <>
            <br /><br />
            <span className="whitespace-pre-line">
                <b>Comments:</b>
                <br />
                {props.answer.R_Text}
            </span>
        </> : ''}
    </>
    );
});


export default AnswerVideoRecording;