import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {useUser} from '../UserContext';
import BillingService from '../../services/BillingService';
import getStripe from '../../utils/getStripe';
import Loader from "react-loader-spinner";


export default function BillingOnDemandOffers(props) {
    const { user } = useUser();
    const [waiting, setWaiting]  = useState(0);

    const credits = [
        {id : '10credits', name: '10 CREDITS', priceEurLabel: '20€', priceUsdLabel : '24$', priceEurAmount: '2000', priceUsdAmount : '2400'},
        {id : '30credits', name: '30 CREDITS', priceEurLabel: '57€', priceUsdLabel : '69$', priceEurAmount: '5700', priceUsdAmount : '6900'},
        {id : '100credits', name: '100 CREDITS', priceEurLabel: '170€', priceUsdLabel : '204$', priceEurAmount: '17000', priceUsdAmount : '20400'}
      ];

    async function handleCheckout(id) {
        //console.log(id)
        setWaiting(id);
        const offer = credits.find(x => x.id === id)
        const amount = props.currency == '€' ? offer.priceEurAmount : offer.priceUsdAmount;
        const currency = props.currency == '€' ? 'eur' : 'usd';
        const ret = await BillingService.doCheckout(user.accessToken.token, 'credits', 'On-Demand', 'On-Demand ' + offer.name, id, amount, currency);
        
        //console.log(ret)
        //setSessionId(ret);

        const stripe = await getStripe();
        const { error } = await stripe.redirectToCheckout({
            sessionId: ret
        });
        //console.warn(error.message);
    }  

    return (
        <div className='text-sm ml-7 mt-2'>
        We currently offer test credits in packs of 10, 30, or 100 credits.  Click any of the "buy now" links below to purchase a pack of credits.
        {credits.map((offer) => (
            <div key={offer.id} className='text-sm flex'><span>{offer.name} {props.currency == '€' ? offer.priceEurLabel : offer.priceUsdLabel}</span> <button onClick={e=> handleCheckout(offer.id)} className='underline text-blue-600 hover:text-blue-800 ml-2'>Buy now</button>
            {waiting == offer.id  ? 
            <svg className="animate-spin h-5 w-5 mt-1 ml-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
            </svg>                
            
            : ''}
            </div>
        ))}
        </div>

    );

}