import axios from "axios";


class UsersService {

  
    getUsers = async (token) => {
        const ret= await axios.get(process.env.REACT_APP_SKILLMETER_API + "/users", { headers: {"Authorization" : `Bearer ${token}`, "Content-type": "multipart/form-data"} });
        return ret.data;
      }
  
    getUser = async (token, id) => {
      const ret= await axios.get(process.env.REACT_APP_SKILLMETER_API + "/users/" + id, { headers: {"Authorization" : `Bearer ${token}`, "Content-type": "multipart/form-data"} });
      return ret.data;
    }

    getMe = async (token) => {
      const ret= await axios.get(process.env.REACT_APP_SKILLMETER_API + "/users/me", { headers: {"Authorization" : `Bearer ${token}`, "Content-type": "multipart/form-data"} });
      return ret.data;
    }

    addUser = async(token, firstName, email, role) => {

      let fd = new FormData();
  
      fd.append('firstName', firstName);
      fd.append('email', email);
      fd.append('role', role);
  
      return axios.post(process.env.REACT_APP_SKILLMETER_API + "/users/", fd, { headers: {"Authorization" : `Bearer ${token}`, "Content-type": "multipart/form-data"}})
        .then((response) => {
          return response.data;
        })      
        .catch(err => {
          //console.log(err.response.data)
          return {error : err.response.data}
        });

      //return res.data;



    }      

    updateUser = async(token, userId, firstName, email, role) => {

      let fd = new FormData();
  
      fd.append('firstName', firstName);
      fd.append('email', email);
      fd.append('role', role);
  
      const res = await axios.patch(process.env.REACT_APP_SKILLMETER_API + "/users/" + userId, fd, { headers: {"Authorization" : `Bearer ${token}`, "Content-type": "multipart/form-data"}});
      return res.data;
    }      

    updateMe = async(token, firstName, email) => {

      let fd = new FormData();
  
      fd.append('firstName', firstName);
      fd.append('email', email);
  
      const res = await axios.patch(process.env.REACT_APP_SKILLMETER_API + "/users", fd, { headers: {"Authorization" : `Bearer ${token}`, "Content-type": "multipart/form-data"}});
      return res.data;
    }      

    deleteUser = async(token, userId) => {

      const res = await axios.delete(process.env.REACT_APP_SKILLMETER_API + "/users/" + userId, { headers: {"Authorization" : `Bearer ${token}`, "Content-type": "multipart/form-data"}});
      return res.data;
    }     

    cancelAccount = async(token, reasons) => {

      let fd = new FormData();
  
      fd.append('reasons', reasons);
  
      const res = await axios.post(process.env.REACT_APP_SKILLMETER_API + "/users/me/cancelaccount", fd, { headers: {"Authorization" : `Bearer ${token}`, "Content-type": "multipart/form-data"}});
      return res.data;
    }      


    updateNotifications = async(token, notifSubject, notifBody, notifEnabled) => {

      let fd = new FormData();
  
      fd.append('notifSubject', notifSubject);
      fd.append('notifBody', notifBody);
      fd.append('notifEnabled', notifEnabled);
  
      const res = await axios.patch(process.env.REACT_APP_SKILLMETER_API + "/users/me/notifications", fd, { headers: {"Authorization" : `Bearer ${token}`, "Content-type": "multipart/form-data"}});
      return res.data;
    }      

    updateAlternateNotifications = async(token, alternateNotifications) => {

      let fd = new FormData();
  
      fd.append('alternateNotifications', alternateNotifications);
  
      const res = await axios.patch(process.env.REACT_APP_SKILLMETER_API + "/users/me/alternate_notifications", fd, { headers: {"Authorization" : `Bearer ${token}`, "Content-type": "multipart/form-data"}});
      return res.data;
    }      

    verify = async (token) => {
      return axios.post(process.env.REACT_APP_SKILLMETER_API + "/users/verify", null, {headers: {"Authorization" : `Bearer ${token}`}})
        .then((response) => {
          return "OK";
        })
        .catch(err => {
          return "NOK"
        });
    }
    
}

export default new UsersService();
