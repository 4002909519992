import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import ConfirmDelete from '../Shared/ConfirmDelete';
export default function TestCard (props) {

    const [del, setDel] = useState(false);

    const doDelete = (id) => {
        props.deleteTest(id)
    }

    return (
        <>
        <div className='bg-white mb-4 py-4 px-4 rounded-md grid grid-cols-2'>
            <div>
                <span className='font-bold'>{props.name} </span> 
                {props.isPrivate == false? 
                <>
                <br/>
                Public Url: <a href={'https://' + props.subdomain + '.skillmeter.com/t/' + props.publicUrl} className='underline text-blue-600 hover:text-blue-800 ml-2'>{'https://' + props.subdomain + '.skillmeter.com/t/' + props.publicUrl}</a>
                </> : ''}
                <br />
                <span className='mr-1'>Questions: {props.questions},</span>
                Timed: {props.timed ? 'Yes' : 'No'}
                {props.timed ? ' (' + props.duration + ' min)': ''}

            </div>
            
            <div className='content-end right-0 w-full justify-end text-right'>
                <Link to={"/tests/edit/" + props.testId} className="text-indigo-600 hover:text-indigo-900 ">Edit</Link>
                <Link to={"/tests/" + props.testId + "/questions/" } className="ml-4 text-indigo-600 hover:text-indigo-900 ">Questions</Link>
                <button onClick={() => props.cloneTest(props.testId)} className="ml-4 text-indigo-600 hover:text-indigo-900 ">Clone</button>
                <button onClick={() => setDel(true)} className="ml-4 text-indigo-600 hover:text-indigo-900 ">Delete</button>

            </div>         

        </div>

        <ConfirmDelete open={del} setOpen={setDel} id={props.testId} doDelete={doDelete} object='test'/>
        </>
    );
}