import { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import parse from "html-react-parser";

const AnswerExactMatch = forwardRef((props, ref) => {

    const [correct, setCorrect] = useState("Wrong");
    
    useEffect( async() => {
        //console.log(props.answer)

        if (props.answer.A_2 != "number") 
        {
            if (props.answer.A_1.trim().replace("’", "'")  == (props.answer.R_Text != null ? props.answer.R_Text.trim().replace("’", "'") : "")) { setCorrect("Correct"); }
        }
        else {
            const correct = Number(props.answer.A_1.trim());
            const entered = Number(props.answer.R_Text);

            if (correct == entered) setCorrect("Correct");
        }


    }, []); 
        
    return (
    <>
        <b>Answer: <span className={"rounded px-1 py-1 " + (correct=='Wrong' ? 'bg-red-300 text-red-800' : '') +  (correct=='Correct' ? ' bg-green-300 text-green-800' : '')}>{correct}</span></b>
        <br />{parse(props.answer.R_Text ? props.answer.R_Text : '')}



        {(props.answer.Comments != null && props.answer.Comments) ? 
        <>
            <br /><br />
            <span className="whitespace-pre-line">
                <b>Comments:</b>
                <br />
                {props.answer.R_1_Text}
            </span>
        </> : ''}
    </>
    );
});


export default AnswerExactMatch;