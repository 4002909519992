import React, {useState, useEffect} from 'react';
import CandidatesService from '../../../services/CandidatesService';
import { useParams, useHistory, Link } from 'react-router-dom';
import {useUser} from '../../UserContext';
import Loader from "react-loader-spinner";
import Header from '../../Shared/Header';
import ResultSummary from './ResultSummary';
import Answer from '../Answers/Answer';
import {
    ArrowCircleLeftIcon,
    ShareIcon,
    DownloadIcon,
    PlayIcon
  } from '@heroicons/react/outline'

export default function Result (props) {
    const { user } = useUser();
    const [mounted, setMounted] = useState(false);
    const {id } = useParams();
    const [candidate, setCandidate] = useState([]);
    const [answers, setAnswers] = useState([]);
    const [tests, setTests] = useState([]);
    const [isCopied, setIsCopied] = useState(false);
    const [isAllowResume, setIsAllowResume] = useState(false);

    const history = useHistory();

    useEffect( async () => {
        const retC = await CandidatesService.getCandidate(user.accessToken.token, id);   
        setCandidate(retC);
        //console.log(retC)
        // if seenanswer = 0 make it 1
        if (retC.SeenResult == 0) {
            CandidatesService.markSeenResult(user.accessToken.token, id);
        }
        const retA = await CandidatesService.getCandidateAnswers(user.accessToken.token, id);        
        setAnswers(retA);
        //console.log(retA);
        const retT = await CandidatesService.getTestsDetails(user.accessToken.token, id);
        //console.log(retT);
        setTests(retT);
        setMounted(true);
    }, []);


    const updateAnswer = async (id, correct) => {

        // update answers state
        const newState = answers.map(obj => {
            // 👇️ find the right response and update correct state
            if (obj.ResponseId === id) {
              return {...obj, A_1_Correct: correct};
            }
            // 👇️ otherwise return object as is
            return obj;
          });
            
          //console.log(newState)
          setAnswers(newState);        

        const answer = newState.find(obj => {
            return obj.ResponseId === id;
          });
        
          //console.log(id)
        //console.log(answer)

        // update in DB & recalculate test score
        await CandidatesService.saveResponse(user.accessToken.token, answer);      
        //console.log('refresh tests score');     
        const retC = await CandidatesService.getCandidate(user.accessToken.token, candidate.CandidateId);   
        setCandidate(retC);
        const retT = await CandidatesService.getTestsDetails(user.accessToken.token, candidate.CandidateId);
        setTests(retT);

    }

    const updatePassed = async (candidateId, correct) => {
        // update new status in DB
        await CandidatesService.updateResult(user.accessToken.token, candidateId, correct);      
        let newCandidate = {...candidate};
        newCandidate.Passed = correct;
        setCandidate(newCandidate);
    }

    const setAllowResume = async () => {
        await CandidatesService.setAllowResume(user.accessToken.token, candidate.CandidateId)
        setIsAllowResume(true)
    }    

    return ( 
        <>
        <Header nav='Candidates'/>   

        <div className="md:ml-64 flex flex-col flex-1 w-auto ">
            {/* Page title & actions */}
            <div className="relative">
                <div className="py-6 px-4 sm:px-6 md:px-8 w-full fixed bg-title flex md:pr-64 shadow-sm">
                    <h1 className="font-semibold text-gray-900 text-xl">Results for {candidate.First_Name} {candidate.Last_Name}</h1>
                </div>
            </div>
            
            {/* Page */}
            { mounted ? 
            <div className="bg-body w-full min-h-screen mx-auto px-4 sm:px-6 md:px-8 pt-20">

                <div className='flex'>
                    <button type='button' onClick={() => history.goBack()} className='items-center flex mb-2 mr-6 px-3 py-2 border text-sm rounded-md shadow-sm text-white bg-blue-400 hover:bg-blue-700 focus:outline-none cursor-pointer'> <ArrowCircleLeftIcon className="mr-2 h-5 w-5"  /> Go back</button>

                    <button type='button' onClick={async() =>  {await navigator.clipboard.writeText(candidate.resultsUrl); setIsCopied(true);}} className='items-center flex mb-2 mr-6 px-3 py-2 border text-sm rounded-md shadow-sm text-white bg-blue-400 hover:bg-blue-700 focus:outline-none cursor-pointer'> <ShareIcon className="mr-2 h-5 w-5"  /> Share result</button>
                    
                    {candidate.TestEndDate == null && (!isAllowResume && candidate.AllowResume != true)? 
                    <button type='button' onClick={async() =>  {await setAllowResume()}} className='items-center flex mb-2 mr-6 px-3 py-2 border text-sm rounded-md shadow-sm text-white bg-blue-400 hover:bg-blue-700 focus:outline-none cursor-pointer'> <PlayIcon className="mr-2 h-5 w-5"  /> Resume test</button>
                    : <></>}
                    {isAllowResume ? <div className='py-2 '><span className='bg-green-300 text-white text-sm py-1 px-1 rounded-md'>Candidate is now allowed to resume the test using the same PIN code!</span></div> : ''}

                    {isCopied ? <div className='py-2 '><span className='bg-blue-300 text-white text-sm py-1 px-1 rounded-md'>Results link copied to clipboard!</span></div> : ''}
                    {/* <button type='button' className='items-center flex mb-2 mr-6 px-3 py-2 border text-sm rounded-md shadow-sm text-white bg-gray-400 hover:bg-gray-700 focus:outline-none cursor-pointer'> <DownloadIcon className="mr-2 h-5 w-5"  /> Download PDF</button> */}
                </div>

                <ResultSummary candidate={candidate} answers={answers} tests={tests} updatePassed={updatePassed}/>

                <div className='font-bold text-lg py-4 px-4'>ANSWERS</div>

                <div className="py-4">

                    {
                        answers.map( (answer, index) => {
                            return (
                                <Answer answer={answer} index={index} key={index} updateAnswer={updateAnswer}/>
                            )
                        })
                    }
                </div>

                {candidate.Feedback ? 
                    <div className='py-4'>
                        <b>CANDIDATE FEEDBACK</b>
                        <br /><br />    
                        <b>Difficulty Level:</b> {candidate.FeedbackLevel == 'E' ? 'Easy':''}{candidate.FeedbackLevel == 'M' ? 'Medium':''}{candidate.FeedbackLevel == 'H' ? 'Difficult':''}
                        {candidate.FeedbackText ? 
                        <>
                            <br />
                            <span className="whitespace-pre-line">
                                <b>Comments:</b> {candidate.FeedbackText}
                            </span>                            
                        </>
                        : ''
                        } 
                    </div>
                    : ''
                }
            </div>
            :
            <div className="bg-body w-full h-screen mx-auto px-4 sm:px-6 md:px-8 pt-20">
            <div className='flex justify-center mt-24'><Loader type="ThreeDots" color="#000000" width={50}/></div>
            </div>
            }

        </div>

        </>
    );
    
}