import React , {useEffect, useState} from 'react';
import TestsService from '../../services/TestsService';


export default function Category(props) {

    const [category, setCategory] = useState(null);
    const [newCategory, setNewCategory] = useState(null);
    const [edit, setEdit] = useState(false);

  
    useEffect(() => {

        if (!props.categories.includes(props.category))  {
            props.addCategory(props.category);
            setCategory(props.category);
            setNewCategory(props.category)
        }

    }, []);

    const updateCategory = () => {
        if (newCategory != '') {
            TestsService.updateCategory(props.token, category, newCategory);
            setCategory(newCategory);
            setEdit(false);
        }
    }

    if (!category) 
        return '';
    else 
    return (
        <div className='text-lg mb-10 mt-10 flex'>
            {edit ? 
                <>
                <input className='rounded-md' defaultValue={category} onChange={(e) => setNewCategory(e.target.value)}></input>
                <button className='ml-4 items-center mr-4 px-3 py-2 border text-sm rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none ' onClick={()=>updateCategory()}>Save</button>
                <button className='items-center mr-6 px-3 py-2 border text-sm rounded-md shadow-sm text-white bg-gray-400 hover:bg-gray-700 focus:outline-none cursor-pointer' onClick={()=>setEdit(false)}>Cancel</button>

                </>
                :
                <>
                <span className='font-bold text-xl'>{category}</span>
                <button className='ml-4 text-indigo-400 hover:text-indigo-900 text-md' onClick={()=>setEdit(true)}>Edit</button>
                </>
            }

        </div> 
    );


}

