import { useState, useEffect, forwardRef, useImperativeHandle } from 'react';

const QuestionSingleAnswer = forwardRef((props, ref) => {

    const options = [1, 2, 3, 4, 5, 6];
    const optionsMore = [7, 8, 9, 10, 11, 12]

    const [more, setMore] = useState(props.question.QuestionId ? true : false); // show all values if in edit mode

    // useImperativeHandle is needed to be able to access isValid function from the parent component
    useImperativeHandle(ref, () => ({

        // Validator there's one correct answer
        isValid : () => {
            let valid = false;

            // check that a least one answer is marked correct
            for (let i=1; i<=12; i++) {
                if (props.question['A_' + i + '_Correct'] == true) 
                    valid= true;
            }
            
            if (valid)
                return {
                    valid: true,
                    message: null
                }
            else            
                return {
                    valid: false,
                    message: 'Select the correct answer'
                } 

        }
    }));


    const clearAnswers = (q) => {
        q.A_1_Correct = false;
        q.A_2_Correct = false;
        q.A_3_Correct = false;
        q.A_4_Correct = false;
        q.A_5_Correct = false;
        q.A_6_Correct = false;
        q.A_7_Correct = false;
        q.A_8_Correct = false;
        q.A_9_Correct = false;
        q.A_10_Correct = false;
        q.A_11_Correct = false;
        q.A_12_Correct = false;
        return q;
    }

    useEffect( async() => {
        console.log(props.question)
    }, []); 
        
    return (
    <>

        <label htmlFor="title" className="w-1/5 font-medium text-gray-700 ">
            Answers
        </label>


        
        <div className='w-full '>
        {options.map((i) => (
            <div key={i} className='w-full flex mb-1'>
                <div  className='w-1/5 text-right'>
                    <input type='radio' name={'answer'} className='mr-2 focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300' value={'A_' + i} checked={props.question['A_' + i + '_Correct'] == true} onChange={e => {
                        let q = {...props.question};
                        q = clearAnswers(q);
                        q['A_' + i+ '_Correct'] = true;
                        props.setQuestion(q)

                    }} />
                </div>
                <div className='w-3/5'>
                    <textarea className={'p-1 rounded-md w-full ' + (props.question['A_' + i + '_Correct'] == true ? 'bg-green-100' : '')} 
                    value={props.question['A_' + i]}
                    onChange={e => {
                        let q = {...props.question};
                        q['A_' + i] = e.target.value;
                        props.setQuestion(q)
                    }                
                    }
                    placeholder={i > 2 ? '(optional)' : ''}
                    />
                </div>
            </div>
        ))}
        
        {!more ?
            <div className='w-full flex'>
                <div className='w-1/5'></div>
                <div><button onClick={() => setMore(true)} className='text-gray-500' >More answers</button> </div>
            </div> 
            
            : <></>}
        
        <div className={more ? 'block' : 'hidden'}>
            {optionsMore.map((i) => (
                <div key={i} className='w-full flex mb-1'>

                    <div className='w-1/5 text-right'>
                        <input type='radio' name={'answer'} className='mr-2 focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300' value={'A_' + i} checked={props.question['A_' + i + '_Correct'] == true} onChange={e => {
                            let q = {...props.question};
                            q = clearAnswers(q);
                            q['A_' + i+ '_Correct'] = true;
                            props.setQuestion(q)

                        }} />
                    </div>

                    <div className='w-3/5'>
                        <textarea className={'p-1 rounded-md w-full ' + (props.question['A_' + i + '_Correct'] == true ? 'bg-green-100' : '')}                         value={props.question['A_' + i]}
                        onChange={e => {
                            let q = {...props.question};
                            q['A_' + i] = e.target.value;
                            props.setQuestion(q)

                        }}
                        placeholder={'(optional)'}
                        />
                    </div>
                </div>
            ))}
        </div>

        </div>


    </>
    );
});

export default QuestionSingleAnswer;