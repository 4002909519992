import React, {useState, useEffect} from 'react';
import { Redirect, useLocation, Link } from 'react-router-dom';
import AuthenticationService from '../../services/AuthenticationService';
import {getPlanName, getCurrency, getPlanPrice} from '../../utils/general'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import AlertError from '../Shared/AlertError';

export default function SignUp(props) {

  const [isError, setIsError] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [finalized, setFinalized] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [email, setEmail] = useState("");
  const [email2, setEmail2] = useState("");
  const [company, setCompany] = useState("");
  const [plan, setPlan] = useState(1);  
  const [currency, setCurrency] = useState('usd');
  const [submitting, setSubmitting] = useState(false);
  const location = useLocation();
  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .required('First name is required'),
    email: Yup.string()
        .required('Email is required')
        .matches(/\S+@\S+\.\S+/, 'Email is invalid'),
    email2: Yup.string()
        .required('Repeat email is required')
        .matches(/\S+@\S+\.\S+/, 'Email is invalid')
        .test(
          'equal',
          'Emails do not match',
          function(v) { // Don't use arrow functions
            const ref = Yup.ref('email');
            return v === this.resolve(ref);
          }
        ), 
    company: Yup.string()
        .required('Company name is required')
  });

  const formOptions = { resolver: yupResolver(validationSchema) };
  const {register, formState: {errors}, handleSubmit} = useForm(formOptions);


  useEffect( async () => {
    let paramPlan = new URLSearchParams(location.search).get('plan');
    if (!paramPlan) paramPlan = 1;
    setPlan(paramPlan);
    //setPlan(new URLSearchParams(location.search).get('plan'));

    let paramCurrency = new URLSearchParams(location.search).get('currency');
    if (!paramCurrency) paramCurrency = 'usd';
    setCurrency(paramCurrency);
    //setCurrency(new URLSearchParams(location.search).get('currency'));
  
  }, []);


  const onSubmit = async(data) => {
    //event.preventDefault();
      if (!submitting) { // submit on going so skip
        setSubmitting(true);
        //console.log('signup call')
        await AuthenticationService.signUp(data.firstName, data.email, data.company, plan, getPlanName(plan), currency)
        .then( (response) => {
          // check response code 
          //console.log(response)
          setFinalized(true);

        }).catch ((error) => {
          //console.log("Error = { " + error.toString() + " }");
          console.log(error.response.status);
          console.log(error.response.data);
          if (error.response.status == 403 ) {
            setErrMsg(error.response.data);
          }
          else {
            setErrMsg('Server error.  Please try again later.');
          }
          setIsError(true);
          //setSubmitting(false);

        });      
        console.log('finished ')
        setSubmitting(false)
      }
  }

    return (
<section className="py-12 bg-gray-50 min-h-screen sm:py-16 lg:py-20">
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="relative max-w-md mx-auto lg:max-w-lg">
          {/*
          <div className="absolute -inset-2">
            <div className="w-full h-full mx-auto rounded-3xl opacity-30 blur-lg filter" ></div>
    </div>*/}

          <a href="https://skillmeter.com"><img className="h-8 w-auto mx-auto mb-10" src="/sm-logo.png" alt="Skillmeter" /></a>

          <div className="relative overflow-hidden bg-white shadow-xl rounded-xl">
            <div className="px-4 py-6 sm:px-8">
              <div className="text-center">
                {/*<img className="h-8 w-auto mx-auto" src="/skillmeter_logo.png" alt="Skillmeter" />*/}

                <h1 className="text-xl font-bold text-gray-900">
                {finalized ? 'Welcome to Skillmeter' : 'Create your Skillmeter account'}
                </h1>
              </div>


              <div className='mt-4'>

              {isError ? <AlertError title='There was an error processing the request' list={[errMsg]} /> : ''}


              {(errors.firstName || errors.email || errors.email2 || errors.company  )  ? <AlertError title='Your form has errors' list={[errors.firstName?.message, errors.email?.message, errors.email2?.message, errors.company?.message]}/> : ''}

              {!finalized ? 
              <form onSubmit={handleSubmit(onSubmit)}>

                <div className="space-y-4 mt-4  text-gray-600 bg-green-100 rounded-xl p-2">
                    <b>You have chosen the {getPlanName(plan)} plan.</b>
                    <br />
                    {plan != 4 ? 
                    <span>You will not be charged now. The first 14 days are free. After 14 days, you will be billed {getCurrency(currency)}{getPlanPrice(plan, currency)} per month.</span>
                    : 
                    <span>Your free trial consists of 5 free credits (1 credit = 1 candidate test). You can purchase additional credits anytime.</span>
                    }
                </div>

                <div className="space-y-4 ">
                    <div className="mt-4">
                      <input type="firstName" {...register("firstName", {value : firstName})} onChange={e => { setFirstName(e.target.value);}} placeholder="Your first name" className="block w-full px-4 py-4 text-gray-900 placeholder-gray-600 bg-white border border-gray-400 rounded-xl focus:border-gray-900 focus:ring-gray-900 caret-gray-900" />
                    </div>
                </div>

                <div className="space-y-4 ">
                    <div className="mt-4">
                      <input type="email" {...register("email", {value : email})} onChange={e => { setEmail(e.target.value);}} placeholder="Your work email" className="block w-full px-4 py-4 text-gray-900 placeholder-gray-600 bg-white border border-gray-400 rounded-xl focus:border-gray-900 focus:ring-gray-900 caret-gray-900" />
                    </div>
                </div>
                <div className="space-y-4 ">

                    <div className="mt-4">
                      <input type="email2" {...register("email2", {value : email2})} onChange={e => { setEmail2(e.target.value);}} placeholder="Repeat email" className="block w-full px-4 py-4 text-gray-900 placeholder-gray-600 bg-white border border-gray-400 rounded-xl focus:border-gray-900 focus:ring-gray-900 caret-gray-900" />
                    </div>
                </div>

                <div className="space-y-4 ">
                    <div className="mt-4">
                      <input type="company" {...register("company", {value : company})} onChange={e => { setCompany(e.target.value);}} placeholder="Company name" className="block w-full px-4 py-4 text-gray-900 placeholder-gray-600 bg-white border border-gray-400 rounded-xl focus:border-gray-900 focus:ring-gray-900 caret-gray-900" />
                    </div>
                </div>

                <div className='space-y-4 mt-4'>

                By clicking "Start my free trial", you agree to the <a href='https://skillmeter.com/terms' className='underline text-blue-600 hover:text-blue-800' target='_blank'>Terms of Service</a>, <a href='https://skillmeter.com/privacy' className='underline text-blue-600 hover:text-blue-800' target='_blank'>Privacy</a> and <a href='https://skillmeter.com/refund' className='underline text-blue-600 hover:text-blue-800' target='_blank'>Refund</a> policies.
                </div>
                <div className='flex justify-center'>
                  <button type="submit" disabled={props.maintenance.maintenance} className="flex items-center justify-center px-8 py-4 mt-5 text-base font-bold text-white transition-all duration-200 bg-green-600 border border-transparent rounded-xl focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-600 font-pj hover:bg-green-900">
                    Start my free trial
                    {submitting ? 
                            <svg className="animate-spin h-5 w-5 mt-1 ml-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                            </svg>                
                            : ''}

                  </button>
                </div>

                </form>
                : 
                <div className="rounded-md bg-green-300 p-4">
                  <p>We hope you enjoy your 14-day free trial.  Kick the tires, create one or more tests, add your candidates and see how useful Skillmeter can be. We think you're going to love it.</p>
                  <p className='mt-4'><Link to="/sign-in" className="font-bold rounded hover:underline focus:outline-none focus:ring-1 focus:ring-gray-900 focus:ring-offset-2">👉 Sign in to get started </Link></p>
                  <p className='mt-4'>We want you get the maximum value out of Skillmeter, so if you have questions or feedback, please email us anytime at <a href="mailto:support@skillmeter.com" className='hover:underline hover:text-blue-800 text-blue-600'>support@skillmeter.com</a></p>

                </div>
                }

              </div>



                <p className="text-base font-normal text-gray-900 text-center mt-8">
                  Have an account? <Link to="/sign-in"  className="font-bold rounded hover:underline focus:outline-none focus:ring-1 focus:ring-gray-900 focus:ring-offset-2">Sign In</Link>
                </p>



            </div>
          </div>
        </div>
      </div>
    </section>

    );
}