import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useUser } from "./components/UserContext";

function ProtectedRoute ({ component: Component, ...rest }) {
  const {user} = useUser();

  return (
    <Route
      {...rest}
      render={props =>
        user ? (
          <Component {...props} />
        ) : (
          <Redirect to="/sign-in" />
        )
      }
    />
  );
}

export default ProtectedRoute;
