import { useState, forwardRef, useImperativeHandle } from 'react';

const QuestionRanking = forwardRef((props, ref) => {

    const options = [1, 2, 3, 4, 5, 6];
    const optionsMore = [7, 8, 9, 10, 11, 12]

    const [more, setMore] = useState(props.question.QuestionId ? true : false); // show all values if in edit mode
    
    // useImperativeHandle is needed to be able to access isValid function from the parent component
    useImperativeHandle(ref, () => ({

        // Validator there's one correct answer
        isValid : () => {
            if (props.question.A_1 != null && props.question.A_2 != null && props.question.A_1.trim() != '' &&  props.question.A_2.trim() != '')
                return {
                    valid: true,
                    message: null
                }
            else            
                return {
                    valid: false,
                    message: 'Please add at least 2 answers'
                } 

        }
    }));


    return (
    <>
        <label htmlFor="title" className="w-1/5 font-medium text-gray-700 ">
            Answers
        </label>

        <div className='w-full'>
        {options.map((i) => (
            <div key={i} className='w-full flex mb-1'>

                <div className='w-1/5 text-right'>
                    <span className="mr-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-md font-medium bg-indigo-100 text-indigo-800">
                        {i}
                    </span>                
                </div>
                <div className='w-3/5'>
                    <textarea className='p-1 rounded-md w-full ' 
                        value={props.question['A_' + i]}
                        onChange={e => {
                            let q = {...props.question};
                            q['A_' + i] = e.target.value;
                            props.setQuestion(q)
                        }                
                        }
                        placeholder={i > 2 ? '(optional)' : ''}
                        />

                </div>
            </div>
        ))}
        
        {!more ?
            <div className='w-full flex'>
                <div className='w-1/5'></div>
                <div><button onClick={() => setMore(true)} className='text-gray-500' >More answers</button> </div>
            </div> 
            
            : <></>}

        <div className={more ? 'block' : 'hidden'}>
            {optionsMore.map((i) => (
                <div key={i} className='w-full flex mb-1'>

                    <div className='w-1/5 text-right '>                    
                        <span className="mr-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-md font-medium bg-indigo-100 text-indigo-800">
                            {i}
                        </span> 
                    </div>
                    <div className='w-3/5'>
                        <textarea className='p-1 rounded-md w-full'
                            value={props.question['A_' + i]}
                            onChange={e => {
                                let q = {...props.question};
                                q['A_' + i] = e.target.value;
                                props.setQuestion(q)

                            }}
                            placeholder={'(optional)'}
                            />

                    </div>
                </div>
            ))}
        </div>

        </div>


    </>
    );
});

export default QuestionRanking;