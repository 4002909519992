/* This example requires Tailwind CSS v2.0+ */
import React, {useState, useEffect} from 'react';
import { ExclamationIcon } from '@heroicons/react/solid'
import {useUser} from '../UserContext';
import BillingService from '../../services/BillingService';
import getStripe from '../../utils/getStripe';

export default function StartPaidSubscription(props) {
  const { user } = useUser();
  const [waiting, setWaiting]  = useState(0);

  const offers  = [
    {id : '1', name: 'Standard', priceEur: 49, priceUsd: 59, planEur : 'sm_standard_eur', planUsd : 'sm_standard_usd'},
    {id : '2', name: 'Premium', priceEur: 99, priceUsd: 119, planEur : 'sm_premium_eur', planUsd: 'sm_premium_usd'},
    {id : '3', name: 'Ultimate', priceEur: 199, priceUsd: 239, planEur:'sm_ultimate_eur', planUsd:'sm_ultimate_usd'}
  ];

  async function handleCheckout() {
    //console.log('checkout ' + props.subscription)
    setWaiting(true);
    
    let offerId;
    let amount;
    const offer = offers.find(x => x.id == props.subscription)
    if (props.currency.toLowerCase() == 'eur') {
      offerId = offer.planEur;
      amount = offer.priceEur;
    }
    else {
      offerId = offer.planUsd;
      amount= offer.priceUsd;

    }

    //offerId = 'plan_FeWfRnCHAq7UVi'; // for testing
    const ret = await BillingService.doCheckout(user.accessToken.token, 'subscription', offerId, offer.name, null, amount, props.currency);
    
    //console.log(ret)
    //setSessionId(ret);

    const stripe = await getStripe();
    const { error } = await stripe.redirectToCheckout({
        sessionId: ret
    });
    //console.warn(error.message);
    setWaiting(false)
}  

  return (
          <button onClick={e => handleCheckout()} className='flex items-center px-1 py-1 text-sm rounded-md shadow-sm border-none text-white  focus:outline-none cursor-pointer bg-green-600 hover:bg-green-700'>
            {props.message}
            {waiting == true? 
            <svg className="animate-spin h-5 w-5 mt-1 ml-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
            </svg>                
            
            : ''}

          </button>

  )
}