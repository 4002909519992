import axios from "axios";


class CompaniesService {

  
    getCompany = async (token) => {
      const ret= await axios.get(process.env.REACT_APP_SKILLMETER_API + "/companies/", { headers: {"Authorization" : `Bearer ${token}`, "Content-type": "multipart/form-data"} });
      return ret.data;
    }

    updateCompany = async(token, companyName, subdomain, language, reminderFrequence, logo, newLogo) => {

      let fd = new FormData();
      if (newLogo) fd.append('image', newLogo, newLogo.name);
      fd.append('companyName', companyName);
      fd.append('subdomain', subdomain);
      fd.append('language', language);
      fd.append('reminderFrequence', reminderFrequence);
      fd.append('logo', logo);
  
      const res = await axios.patch(process.env.REACT_APP_SKILLMETER_API + "/companies/", fd, { headers: {"Authorization" : `Bearer ${token}`, "Content-type": "multipart/form-data"}});
      return res.data;
    }      

    getSubdomain = async (subdomain) => {
      const ret= await axios.get(process.env.REACT_APP_SKILLMETER_API + "/companies/subdomain/" + subdomain);
      return ret.data;
    }

    updateSubscriptionPlan = async(token, planId) => {

      let fd = new FormData();
      fd.append('planId', planId);
  
      const res = await axios.post(process.env.REACT_APP_SKILLMETER_API + "/companies/subscription", fd, { headers: {"Authorization" : `Bearer ${token}`, "Content-type": "multipart/form-data"}});
      return res.data;
    }      

}

export default new CompaniesService();
