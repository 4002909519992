import React, {useEffect, forwardRef, useImperativeHandle} from 'react';
import { Switch } from '@headlessui/react'
import { classNames } from '../../../utils/general';


const Score  = forwardRef((props, ref) => {

    // useImperativeHandle is needed to be able to access isValid function from the parent component
    useImperativeHandle(ref, () => ({

        // Validator : required to configure min accuracy & speed
        isValid : () => {
            if (!isNaN(props.question.Score) && !isNaN(parseFloat(props.question.Score)))
                return {
                    valid: true,
                    message: null
                }
            else            
                return {
                    valid: false,
                    message: 'Score is required and must be a number'
                } 
        }
    }));    

    useEffect( async () => {
        //console.log('useEffect')
        //console.log(props.question.Scored)
    }, [props.question.Score]);

    return (
        <>
        <label htmlFor="score" className="w-1/5 font-medium text-gray-700 ">
            Scored
        </label>

        <div className="w-3/5">

            <Switch
                checked={props.question.Scored}
                onChange={() => {
                    let q = {...props.question};
                    //console.log('before : ' + q.Comments)
                    q.Scored = !q.Scored;
                    //console.log('after ' + q.Comments)
                    props.setQuestion(q); 
                }}
                className={classNames(
                    props.question.Scored ? 'bg-green-600' : 'bg-red-600',
                    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none '
                )}
                >
                <span
                    aria-hidden="true"
                    className={classNames(
                    props.question.Scored ? 'translate-x-5' : 'translate-x-0',
                    'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                    )}
                />
            </Switch>


            {props.scoring == 2 && props.question.Scored == true? 
            <>
            <label htmlFor="score" className="ml-4 font-medium text-gray-700 ">
                Points
            </label>

            <input id='score' defaultValue={props.question.Score} onChange={(e) =>{
                let q = {...props.question};
                q.Score = e.target.value;
                props.setQuestion(q)
                }} className='ml-4 p-1 rounded-md w-1/5' required={true} />
            </>                
            : ''}

        </div>
    </>

    )
});

export default Score;