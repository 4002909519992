import { useState, useEffect } from "react";
import TestsService from '../../services/TestsService';
import {useUser} from '../UserContext';
import { SearchCircleIcon } from '@heroicons/react/outline'
  
export default function Filters (props) {
    const [tests, setTests] = useState();
    const { user } = useUser();
    const [mounted, setMounted] = useState(false);
    const [searchText, setSearchText] = useState();

    useEffect( async () => {
        const ret = await TestsService.getTests(user.accessToken.token);
        //console.log(sortByName(ret));
        setTests(sortByName(ret));
        setMounted(true);

    }, []);
    
    const sortByName = (stringTests)=>{      
        return stringTests.sort(function(a,b) {
            if (a.Name.toLowerCase() > b.Name.toLowerCase()) {    
                return 1;    
            } else if (a.Name.toLowerCase() < b.Name.toLowerCase()) {    
                return -1;    
            }    
            return 0;    

        });
      };    
    
    if (mounted)
        return (
            <>
            <div className="w-full flex py-4 bg-yellow-50 rounded-md mb-4">
                <div className="w-2/3 font-medium text-sm text-gray-800 px-3 pt-1">
                    <div>
                        Filter by test <select className='p-1 rounded-md ' onChange={(e) => props.changeFilter({action: '', audience: props.filter.audience, testId: (e.target.value != '' ? e.target.value : null)})}>
                            <option value=''>All</option>
                        {tests.map((test) => (
                            <option key={test.TestId} value={test.TestId} >{test.Name}</option>
                            ))}                                    
                        </select>              
                    </div>
                    <div className="mt-4 flex">
                        <span className="mt-1">Search candidate</span>
                        <input onChange={ (e) => setSearchText(e.target.value)} className='p-1 rounded-md ml-2'/>
                        <button onClick={ () => {if (searchText != null && searchText.trim() != '' && searchText.trim().length >= 4) {props.changeFilter({action: 'search', audience: props.filter.audience, query: searchText})} else {props.changeFilter({action: '', audience: props.filter.audience, testId:  null})}}}><SearchCircleIcon className="h-6 w-6 ml-2 text-gray-600 hover:text-gray-800 "  /></button>
                    </div>
                </div>
                <div className="text-right right-0 w-1/3">
                    <button className={" px-3 py-2 font-medium text-sm rounded-md " + (props.filter.audience == 'my' ? 'bg-gray-200 text-gray-800' : 'text-gray-600 hover:text-gray-800')} onClick={() => props.changeFilter({audience:'my', testId: (props.filter.testId != null ? props.filter.testId :null)})}>My candidates</button>
                    <button className={"ml-4  px-3 py-2 font-medium text-sm rounded-md " + (props.filter.audience == 'all' ? 'bg-gray-200 text-gray-800' : 'text-gray-600 hover:text-gray-800')} onClick={() => props.changeFilter({audience: 'all', testId: (props.filter.testId !=null ? props.filter.testId : null)})}>All candidates</button>            

                </div>

            </div>
            {/* 
            <div className="w-full text-center p-4">
                <button className={"text-sm hover:text-blue-800 " + (props.filter.action == 'my' ? 'text-blue-800 font-bold ' : 'text-blue-500')} onClick={() => props.changeFilter({action:'my', testId: (props.filter.testId != null ? props.filter.testId :null)})}>My candidates</button>
                <button className={"ml-4 text-sm hover:text-blue-800 " + (props.filter.action == 'all' ? 'text-blue-800 font-bold' : 'text-blue-500')} onClick={() => props.changeFilter({action: 'all', testId: (props.filter.testId !=null ? props.filter.testId : null)})}>All candidates</button>            
            </div>
            <div className="w-full text-sm text-center p-4">
                Filter by test <select className='p-1 rounded-md ' onChange={(e) => props.changeFilter({action: props.filter.action, testId: (e.target.value != '' ? e.target.value : null)})}>
                    <option value=''>All</option>
                {tests.map((test) => (
                    <option key={test.TestId} value={test.TestId} >{test.Name}</option>
                    ))}                                    
                </select>              
            </div>
            */}
            </>

        );
    else
        return ('')
}