import React, {useState, useEffect} from 'react';

export default function MarkPassed (props) {

    useEffect( async () => {
    }, []);


    return ( 
        <>
        <span className='ml-4 bg-yellow-100 p-1 py-1 rounded-md'>
            This test is not scored.  You can mark it as <button onClick={() => props.updatePassed(true)} className='rounded px-1 mx-1 bg-green-300 font-bold  text-green-800 hover:bg-green-400'>PASSED</button> or <button onClick={() => props.updatePassed(false)}  className='rounded px-1 mx-1 font-bold bg-red-300 text-red-800 hover:bg-red-400'>FAILED</button>
        </span>
        </>
    );
    
}