//import { loadStripe } from '@stripe/stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure'; // this allows for lazy loading! https://github.com/stripe/stripe-js/issues/43


//console.log('loading stripe')
//throw new Error('Loading stripe');
 

let stripePromise;
const getStripe = () => {
  if (!stripePromise) {
    //console.log(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)
    stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
  }
  return stripePromise;
};

export default getStripe;