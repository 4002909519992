import axios from "axios";


class BillingService {

  
    getPayments = async (token) => {
        const ret= await axios.get(process.env.REACT_APP_SKILLMETER_API + "/billing", { headers: {"Authorization" : `Bearer ${token}`, "Content-type": "multipart/form-data"} });
        return ret.data;
      }

    getPayment = async (token, id) => {
      const ret= await axios.get(process.env.REACT_APP_SKILLMETER_API + "/billing/" + id, { headers: {"Authorization" : `Bearer ${token}`, "Content-type": "multipart/form-data"} });
      return ret.data;
    }

    doCheckout = async (token, type, planId, what, pack, amount, currency) => {

      let fd = new FormData();
      fd.append('type', type);
      fd.append('planId', planId);
      fd.append('what', what);
      fd.append('amount', amount);
      fd.append('currency', currency);
      fd.append('pack', pack);

      const ret= await axios.post(process.env.REACT_APP_SKILLMETER_API + "/billing/checkout", fd, { headers: {"Authorization" : `Bearer ${token}`, "Content-type": "multipart/form-data"} });
      return ret.data;
    }

    doCheckoutSuccess = async (token, sessionId) => {

      let fd = new FormData();
      fd.append('sessionId', sessionId);
      const ret= await axios.post(process.env.REACT_APP_SKILLMETER_API + "/billing/success", fd, { headers: {"Authorization" : `Bearer ${token}`, "Content-type": "multipart/form-data"} });

      return ret.data;
    }
    
}

export default new BillingService();
