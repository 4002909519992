import { useEffect, forwardRef, useImperativeHandle } from 'react';

const QuestionTypingSpeed = forwardRef((props, ref) => {

    const speed = Array.from(Array(96), (_, i) => 100 - i);
    const accuracy = Array.from(Array(51), (_, i) => 100 - i);



    // useImperativeHandle is needed to be able to access isValid function from the parent component
    useImperativeHandle(ref, () => ({

        // Validator : required to configure min accuracy & speed
        isValid : () => {
            if (
                (props.question.A_1 && props.question.A_1.trim() != '')
                &&
                (props.question.A_2 && props.question.A_2.trim() != '')
            )
                return {
                    valid: true,
                    message: null
                }
            else            
                return {
                    valid: false,
                    message: 'Min. Speed & Min. Accuracy are required'
                } 
        }
    }));

    useEffect( async() => {
        console.log(props.question)
    }, []);     
    return (
    <>
        <label htmlFor="title" className="w-1/5 font-medium text-gray-700 ">
            Correct Answer
        </label>
        <div className="w-3/5">

            <div className='mb-2'>
                <span className='mr-2'>Min. Speed </span>
                <select className='p-1 rounded-md' defaultValue={props.question.A_1 ? props.question.A_1 : 0} onChange={(e) => {
                    let q = {...props.question};
                    if (e.target.value == 0)
                        q.A_1 = null;
                    else
                        q.A_1 = e.target.value;
                    props.setQuestion(q)
                }}            
                >
                    <option value='0'></option>
                    {speed.map((i) => (
                        <option value={i}>{i} wpm</option>
                    ))}
                </select>
            </div>
            <div className='mb-2'>
                <span className='mr-2'>Min. Accuracy </span>
                <select className='p-1 rounded-md' defaultValue={props.question.A_2 ? props.question.A_2 : 0} onChange={(e) => {
                    let q = {...props.question};
                    if (e.target.value == 0)
                        q.A_2 = null;
                    else
                        q.A_2 = e.target.value;
                    props.setQuestion(q)
                }}
                >
                    <option value='0'></option>
                    {accuracy.map((i) => (
                        <option value={i}>{i} %</option>
                    ))}
                </select>
            </div>
        </div>
    </>
    );
});

export default QuestionTypingSpeed;