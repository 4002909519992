import {Link} from 'react-router-dom';

import React, {useState, useEffect} from 'react';
import Header from '../Shared/Header';
import {useUser} from '../UserContext';


export default function NotFound404 (props) {

  const { user } = useUser();
  
    if (user) // if authenticated show a 404 page in the app
        return ( 
            <>
            <Header nav='Dashboard'/>

            <div className="md:pl-64 flex flex-col flex-1">
                <div className="relative">
                <div className="py-6 px-4 sm:px-6 md:px-8 w-full fixed bg-title shadow-sm">
                    <h1 className="font-semibold text-gray-900 text-xl">Page not found</h1>
                </div>


                <div className="bg-body min-h-screen mx-auto px-4 sm:px-6 md:px-8 pt-20">
                    <div className="py-4">
                    <div className='container mx-auto mt-10 text-center p-4 lg:text-lg'>
                        <p>{props.message ? props.message : 'Oops... This page does not exist.'}</p>
                        <p><Link to='/' className='underline text-blue-600 hover:text-blue-800'>Go back</Link></p>
                    </div>

                    </div>
                </div>

                </div>
            </div>

            </>

        );
    else // show a simplified version to non authenticated uses
    return (
        <div className='container mx-auto mt-10 text-center p-4 lg:text-lg'>
        <p>{props.message ? props.message : 'Oops... The page you were looking for does not exist.'}</p>
        <p><Link to='/' className='underline text-blue-600 hover:text-blue-800'>Go back</Link></p>
        </div>

    );
}




