import { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import {useUser} from '../../UserContext';
import axios from "axios";
import Loader from "react-loader-spinner";

const QuestionPictureChoice = forwardRef((props, ref) => {
    const { user } = useUser();

    const options = [1, 2, 3, 4, 5, 6];
    const optionsMore = [7, 8, 9, 10, 11, 12];
    const [uploading, setUploading] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]); // keep an array wiht the state of uploading to show the loader or not

    const [more, setMore] = useState(props.question.QuestionId ? true : false); // show all values if in edit mode
    
    // useImperativeHandle is needed to be able to access isValid function from the parent component
    useImperativeHandle(ref, () => ({

        // Validator there's one correct answer
        isValid : () => {
            if (props.question.A_1 != null && props.question.A_2 != null && props.question.A_1.trim() != '' &&  props.question.A_2.trim() != '')
                return {
                    valid: true,
                    message: null
                }
            else            
                return {
                    valid: false,
                    message: 'Please add at least 2 answers'
                } 

        }

    }));

    useEffect( async() => {
  
    }, []); 
  
    const handleImageChange = async (e, i) => {        

        var file = e.target.files[0];
        //console.log(i)
        //console.log(file);

        let waiting = {...uploading};
        waiting[i-1] = 1;
        setUploading(waiting);

        let fd = new FormData();
        fd.append('file', file, file.name);
        axios.post(process.env.REACT_APP_SKILLMETER_API + '/assets', fd, { headers: {"Authorization" : `Bearer ${user.accessToken.token}`, "Content-type": "multipart/form-data"} })
        .then(function(response){
            const fileLocation = process.env.REACT_APP_CDN_URL + '/assets/' + user.companyId + '/' + response.data.location;

            // update state object with the new response / image
            let q = {...props.question};
            q['A_' + i] = fileLocation;
            props.setQuestion(q)            


            // clearing upload status 
            waiting = {...uploading};
            waiting[i-1] = 0;
            setUploading(waiting);
    
        })
        .catch(function (error) {
            console.log(error);

            // clearing upload status 
            waiting = {...uploading};
            waiting[i-1] = 0;
            setUploading(waiting);
            
        });

        
      };    
  
    const removeImage = (e, i) => {
            e.preventDefault();  // ensure the click is not propagated further (i.e. start the file change event)

            // update state object with the new response / image
            let q = {...props.question};
            q['A_' + i] = null;
            props.setQuestion(q)            

    }
    return (
    <>
        <label htmlFor="title" className="w-1/5 font-medium text-gray-700 ">
            Answer
        </label>

        <div className='w-full'>
        {options.map((i) => (         
            <div key={i} className='w-full flex mb-1'>   
                <div className='w-1/5 text-right' >
                    <input type='checkbox' className='mr-2 focus:ring-indigo-500 w-4 text-indigo-600 border-gray-300 rounded' value={'A_' + i} checked={props.question['A_' + i + '_Correct'] == true} onChange={e => {
                        let q = {...props.question};
                        if (q['A_' + i+ '_Correct'] == true)
                            q['A_' + i+ '_Correct'] = false;
                        else
                            q['A_' + i+ '_Correct'] = true
                        props.setQuestion(q)

                    }} />
                </div>

                <div className='w-3/5 flex'>
                    <label htmlFor={'image' + i} className='inline-flex cursor-pointer focus:outline-none'>
                        {
                        uploading[i-1] == 1 ?
                            <Loader type="ThreeDots" color="#000000"  />
                            :
                            props.question['A_' + i] ? 
                                <div class='relative'>
                                    <img src={props.question['A_' + i]} className={'w-32 ' + (props.question['A_' + i + '_Correct'] == true ? 'border-4 border-green-800' : '')}/>
                                    <button onClick={(e)=>{removeImage(e, i)}} className='absolute top-2 right-2 bg-white opacity-75 text-gray-600 hover:text-red-800 focus:outline-none'>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                                    </svg>                        
                                    </button>
                                </div>
                                :
                                <svg xmlns="http://www.w3.org/2000/svg"  className='h-32 w-32 text-gray-400' fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" /></svg>
                                
                        }
                    </label>
                    <input type="file" id={'image' + i} accept="image/*" onChange={(e) => handleImageChange(e, i)} className='hidden'/>
                </div>


            </div>
        ))}
        
        {!more ?
            <div className='w-full flex'>
                <div className='w-1/5'></div>
                <div><button onClick={() => setMore(true)} className='text-gray-500' >More answers</button> </div>
            </div> 
            
            : <></>}


        <div className={more ? 'block' : 'hidden'}>
            {optionsMore.map((i) => (
                <div key={i} className='w-full flex mb-1'>

                    <div className='w-1/5 text-right' >
                        <input type='checkbox' className='mr-2 focus:ring-indigo-500 w-4 text-indigo-600 border-gray-300 rounded' value={'A_' + i} checked={props.question['A_' + i + '_Correct'] == true} onChange={e => {
                            let q = {...props.question};
                            if (q['A_' + i+ '_Correct'] == true)
                                q['A_' + i+ '_Correct'] = false;
                            else
                                q['A_' + i+ '_Correct'] = true

                            props.setQuestion(q)

                        }} />
                    </div>

                    <div className='w-3/5 flex'>
                        <label htmlFor={'image' + i} className='inline-flex cursor-pointer focus:outline-none'>
                            {
                            uploading[i-1] == 1 ?
                                <Loader type="ThreeDots" color="#000000"  />
                                :
                                props.question['A_' + i] ? 
                                    <div class='relative'>
                                        <img src={props.question['A_' + i]} className={'w-32 ' + (props.question['A_' + i + '_Correct'] == true ? 'border-4 border-green-800' : '')}/>
                                        <button onClick={(e)=>{removeImage(e, i)}} className='absolute top-2 right-2 bg-white opacity-75 text-gray-600 hover:text-red-800 focus:outline-none'>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                                        </svg>                        
                                        </button>
                                    </div>
                                    :
                                    <svg xmlns="http://www.w3.org/2000/svg"  className='h-32 w-32 text-gray-400' fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" /></svg>
                                    
                            }
                        </label>
                        <input type="file" id={'image' + i} accept="image/*" onChange={(e) => handleImageChange(e, i)} className='hidden'/>
                    </div>

                </div>
            ))}
        </div>

        </div>

    </>
    );
});

export default QuestionPictureChoice;