import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {useUser} from '../UserContext';
import UsersService from '../../services/UsersService';
import Loader from "react-loader-spinner";
import Header from '../Shared/Header';
import UserCard from './UserCard';
//import CompaniesService from '../../services/CompaniesService';
import AlertUpgradePlan from '../Shared/AlertUpgradePlan';
import { useCompany } from '../CompanyContext';

export default function UserList (props) {
    const { user } = useUser();
    const { company } = useCompany();
    const [users, setUsers] = useState();
    //const [company, setCompany] = useState();
    const [mounted, setMounted] = useState(false);

    useEffect( async () => {
        const ret = await getUsers();
        setUsers(ret);

        //const comp = await CompaniesService.getCompany(user.accessToken.token);
        //setCompany(comp);
  
        setMounted(true);

    }, []);

    const deleteUser = async (id) => {
        await UsersService.deleteUser(user.accessToken.token, id);

        const ret = await getUsers();
        setUsers(ret);

    }

    const getUsers = async () => {
        const ret = await UsersService.getUsers(user.accessToken.token);
        const ret2 = ret.sort((a,b) => b.Role - a.Role); // order based on Role
        return ret2;

    }
    const canAddUsers = () => {
        if (company) {
            switch (company.Subscription ) {
                case 1:
                    if (users.length >= 5) 
                        return false;
                    else
                        return true;
                case 2:
                    if (users.length >= 10) 
                        return false;
                    else
                        return true;

                case 3:
                    return true;
                case 4:
                    return true;

            }
        }
    }

    return ( 
        <>
        <Header nav='Team'/>   

        <div className="md:ml-64 flex flex-col flex-1 w-auto ">
            {/* Page title & actions */}
            <div className="relative">
                <div className="py-6 px-4 sm:px-6 md:px-8 w-full fixed bg-title flex md:pr-64 shadow-sm">
                    <h1 className="font-semibold text-gray-900 text-xl">Team</h1>                    
                    {
                    mounted ? 
                    user.role > 1 && canAddUsers() ? 
                    <div className='w-full  flex justify-end relative'>
                        <Link to={'/team/add'} className="absolute items-center mr-6 px-3 py-2 border text-sm rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none ">
                            Add user
                        </Link>                    
                    </div>
                    : ''
                    : ''
                }
                </div>
            </div>
            
            {/* Page */}
            { mounted ? 
                <div className="bg-body w-full min-h-screen mx-auto px-4 sm:px-6 md:px-8 pt-20">
                    <div className="py-4">
                        { !canAddUsers() ? <AlertUpgradePlan limit='users' action='Upgrade now'/> : ''}

                        {users.map((person) => (
                                <UserCard key={person.UserId} userId={person.UserId} role={person.Role} email={person.Email} deleteUser={deleteUser}/>
                        ))}
                    </div>
                </div>
            :
            <div className="bg-body w-full h-screen mx-auto px-4 sm:px-6 md:px-8 pt-20">
            <div className='flex justify-center mt-24'><Loader type="ThreeDots" color="#000000" width={50}/></div>
            </div>
            }

        </div>

        </>
    );


}