import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {useUser} from '../UserContext';
import TestsService from '../../services/TestsService';
import CompaniesService from '../../services/CompaniesService';
import Loader from "react-loader-spinner";
import Header from '../Shared/Header';
import TestCard from './TestCard';
import Category from './Category';
import { useCompany } from '../CompanyContext';

export default function TestList (props) {
    const { user } = useUser();
    const { company} = useCompany()
    const [tests, setTests] = useState();
    const [categories, setCategories] = useState([]);
    const [subdomain, setSubdomain] = useState('test');

    const [mounted, setMounted] = useState(false);


    useEffect( async () => {
        const ret = await TestsService.getTests(user.accessToken.token);
        setTests(ret);
        //console.log(ret)
        //const company = await CompaniesService.getCompany(user.accessToken.token);
        //if (company && company.SiteName) setSubdomain(company.SiteName);

        setMounted(true);
    }, []);

    useEffect( async () => {
        if (company && company.SiteName) setSubdomain(company.SiteName);

    }, [company]);


    const deleteTest = async (id) => {
        await TestsService.deleteTest(id, user.accessToken.token);
        let list = [...tests];
        list.splice(list.findIndex(v => v.TestId === id), 1);
        setTests(list);
    }

    const cloneTest = async (id) => {
        console.log('clone test ' +id);
        await TestsService.cloneTest(id, user.accessToken.token);
        const ret = await TestsService.getTests(user.accessToken.token);
        setTests(ret);
    }

    function addCategory(newValue) {
        let tempCat = categories;
        tempCat.push (newValue);
        setCategories(tempCat);

      }


    return ( 
        <>
        <Header nav='Tests'/>

        <div className="md:pl-64 flex flex-col flex-1 w-auto">
            {/* Page title & actions */}
            <div className="relative">
                <div className="py-6 px-4 sm:px-6 md:px-8 w-full fixed bg-title flex md:pr-64 shadow-sm">
                    <h1 className="font-semibold text-gray-900 text-xl">Tests</h1>
                    <div className='w-full  flex justify-end relative'>
                        <Link to={'/tests/add'} className="absolute items-center mr-6 px-3 py-2 border text-sm rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none ">
                            Add test
                        </Link>                    
                    </div>
                </div>
            </div>
            
            {/* Page */}
            {mounted ? 
            <div className="w-full min-h-screen bg-body px-4 sm:px-6 md:px-8 pt-20 shadow-sm">
                <div className="py-4">

                        {tests.length == 0 ? 
                            <>
                            <p className='text-center text-gray-400 text-xl mt-10'>Ugh... there is no test set up yet.</p> 
                            <p className='text-center'><Link className='text-indigo-600 hover:text-indigo-900 text-lg underline' to={'/tests/add'}>Add first test</Link></p>
                            </>
                        : ''}

                        {tests.map((test) => (
                            <div key={test.TestId}>
                            <Category categories={categories} category={test.Category} addCategory={addCategory} token={user.accessToken.token}/>

                            <TestCard key={test.TestId} testId={test.TestId} name={test.Name} category={test.Category} isPrivate={test.Private} publicUrl={test.PublicUrl} timed={test.Timed} duration={test.Duration} questions={test.Questions} subdomain={subdomain} deleteTest={deleteTest} cloneTest={cloneTest}/>
                            </div>
                        ))}

                </div>
            </div>
            :
            <div className="bg-body w-full h-screen mx-auto px-4 sm:px-6 md:px-8 pt-20">
            <div className='flex justify-center mt-24'><Loader type="ThreeDots" color="#000000" width={50}/></div>
            </div>

            }

        </div>


        </>

    );

}