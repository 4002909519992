/* This example requires Tailwind CSS v2.0+ */
import React from 'react';
import { ExclamationIcon } from '@heroicons/react/solid';
import {Link} from 'react-router-dom';

export default function AlertUpgradePlan(props) {

  return (
    <div className="rounded-md bg-red-300 p-4 mb-8">
      <div className="flex">
        <div className="flex-shrink-0">
          <ExclamationIcon className="h-5 w-5 text-black" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-bold text-black">{'YOU REACHED YOUR PLAN LIMITS!'}</h3>
          <div className="mt-2 text-sm text-red-700">
            <p>You’re at the {props.limit} limit for your current plan. <Link to={"/billing"} className='text-indigo-600 hover:text-indigo-900'>{props.action}</Link> for more {props.limit}!</p>

          </div>
        </div>
      </div>
    </div>
  )
}