import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {useUser} from '../UserContext';
import UsersService from '../../services/UsersService';
import Loader from "react-loader-spinner";
import Header from '../Shared/Header';
import AlertError from '../Shared/AlertError';
import AlertSuccess from '../Shared/AlertSuccess';


export default function Settings (props) {
    const { user, setUser } = useUser();
    const [name, setName] = useState();
    const [email, setEmail] = useState();
    const [mounted, setMounted] = useState(false);
    const [success, setSuccess] = useState();
    const [submitting, setSubmitting] = useState(false);
    const [errors, setErrors] = useState();
    const [errorList, setErrorList] = useState([]);

    const onSubmit = async() => {
        //console.log(data);
        setSubmitting(true);

        const err = validateForm();

        if (err.length > 0 ) {
            // we have errors
            setErrorList(err);
            setErrors(true);
            setSuccess(false);

        }        
        else {

            UsersService.updateMe(user.accessToken.token, name, email, null);

            // update auth session with the new name
            const userUpdate = user;
            userUpdate.firstName = name;
            setUser(userUpdate);
            setSuccess(true);
            setErrors(false);
            setErrorList([])

        }
        
        setSubmitting(false);

    }

    const validateForm = () => {
        let err = [];
        if (!email || email =='') err.push('Email is required');

        return err;
    }

    useEffect( async () => {
        //console.log({user})
        const me = await UsersService.getMe(user.accessToken.token);
        //console.log({me})
        setName(me.FirstName);
        setEmail(me.Email);
        setMounted(true);
    }, []);


    return ( 
        <>
        <Header nav=''/>   

        <div className="md:ml-64 flex flex-col flex-1 w-auto ">
            {/* Page title & actions */}
            <div className="relative">
                <div className="py-6 px-4 sm:px-6 md:px-8 w-full fixed bg-title flex md:pr-64 shadow-sm">
                    <h1 className="font-semibold text-gray-900 text-xl">Account settings</h1>
                </div>
            </div>
            
            {/* Page */}
            { mounted ? 
            <div className="bg-body w-full min-h-screen mx-auto px-4 sm:px-6 md:px-8 pt-20">
                <div className="py-4">

                    {success ? <AlertSuccess title='Saved successfully' /> : ''}

                    {errors ? <AlertError title='Your form has errors' list={errorList}/> : ''}

                    <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                        <div className="flex  sm:pt-5">
                            <label htmlFor="name" className="w-1/5 font-medium text-gray-700 ">
                                Name
                            </label>
                            <div className="w-3/5">
                            <input id='name' onChange={e => setName(e.target.value)} required={true} className='p-1 rounded-md w-full' defaultValue={name} />

                            </div>
                        </div>                    
                        <div className="flex  sm:pt-5">
                            <label htmlFor="email" className="w-1/5 font-medium text-gray-700">
                                Email
                            </label>
                            <div className="w-3/5">
                                <input id='email' onChange={e => setEmail(e.target.value)} type='email' required={true}  className='p-1 rounded-md w-full' defaultValue={email} />
                            </div>
                        </div>                    
 
                         <div className='flex sm:pt-2 sm:mt-px'>
                            <button onClick={onSubmit} className='items-center flex mr-6 px-3 py-2 border text-sm rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none cursor-pointer'>
                            Update
                            {submitting ? 
                            <svg className="animate-spin h-5 w-5 mt-1 ml-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                            </svg>                
                            : ''}

                            </button>
                        </div>

                    </div>    

                    <div className='mt-8'>
                            <Link to={'/deleteaccount'} className=' underline text-red-600 hover:text-red-800'>Delete my Skillmeter account</Link>
                    </div>

                </div>
            </div>
            :
            <div className="bg-body w-full h-screen mx-auto px-4 sm:px-6 md:px-8 pt-20">
            <div className='flex justify-center mt-24'><Loader type="ThreeDots" color="#000000" width={50}/></div>
            </div>
            }

        </div>

        </>
    );


}