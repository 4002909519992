import React, {useState, useEffect} from 'react';
import ConfirmDelete from '../Shared/ConfirmDelete';
import {Link} from 'react-router-dom';
import {FormatDate, FormatDateShort} from '../../utils/dates'

export default function CandidateCard (props) {
    const [del, setDel] = useState(false);

    const doDelete = (id) => {
        props.deleteCandidate(id)
    }

    const CalculateStatusValue = (result, testDate, testEndDate) => {
        if (result == null && testDate != null) {
            if (testEndDate != null)
                return 'Not scored';
            else {
                // check if it's in progress , or abandoned
                const diff = testDate
                return 'In progress';
            }
        }

        if (result == true) {
            return 'Passed';
        }

        if (result == false) {
            return 'Failed';
        }
    }

    const CalculateStatusStyle = (result, testDate, testEndDate) => {
        const status = CalculateStatusValue(result, testDate, testEndDate);

        if (status == 'Passed')
            return 'bg-green-300  text-green-800';

        if (status == 'Failed')
            return 'bg-red-300  text-red-800';

        if (status == 'Not scored')
            return 'bg-blue-300  text-blue-800';

        if (status == 'In progress')
            return 'bg-gray-300  text-gray-800';

    }

    const ShowEmail = (email) => {
        if (email.includes('_bounced_')) {
            return <><span title='Invalid email, notification bounced back' className='cursor-pointer'>🚫</span> {email.replace('_bounced_', '')}</>;
        }
        else {
            return email;
        }
    }

    return (
        <>
        <div className={'bg-white mb-4 py-4 px-4 rounded-md flex w-full ' + (props.seenResult == 0 ? 'bg-blue-100 shadow-lg': '') }>
            <div className='w-3/6 flex'>
                <div className='w-1/2'>
                    <span className='font-bold'>{props.firstName} {props.lastName}</span>
                    <br />
                    <span>{ShowEmail(props.email)}</span>

                </div>

                <div className='w-1/2'>
                <span>{props.testName}</span>
                </div>


            </div>


            <div className='w-1/6 text-center'>
                <span>PIN: {props.pinCode}</span>
                <br />
                <span className='text-sm text-gray-400'>Assigned on {FormatDateShort(props.createdDate)}</span>
            </div>

            <div className='w-1/6 text-center'>
                <span className={"uppercase inline-flex items-center rounded px-2 py-0.5 text-xs font-medium " + CalculateStatusStyle(props.result, props.testDate, props.testEndDate)}>{CalculateStatusValue(props.result, props.testDate, props.testEndDate)}</span>
            </div>
            
            <div className={'w-1/6 text-right  '}>
                {props.showResult == true ? <Link to={'/candidates/' + props.candidateId + '/result'} className={"text-indigo-600 hover:text-indigo-900 " + (props.seenResult == 0 ? 'font-bold': '')}>View result</Link> : ''} 
                <button onClick={() => setDel(true)} className={"ml-4 text-indigo-600 hover:text-indigo-900 " + (props.seenResult == 0 ? 'font-bold': '')}>Delete</button>
            </div>           

        </div>
        <ConfirmDelete open={del} setOpen={setDel} id={props.candidateId} doDelete={doDelete} object='candidate'/>

        </>
    );
}