import React, {useState, useEffect} from 'react';
  
export default function MarkAnswer (props) {

    useEffect( async () => {
    }, []);


    return ( 
        <div className='ml-4 flex'>
            Mark answer <button onClick={() => props.markCorrectWrong(true)} className='rounded px-1 mx-1 bg-green-300  text-green-800 hover:bg-green-400'>Correct</button> or <button onClick={() => props.markCorrectWrong(false)}  className='rounded px-1 mx-1 bg-red-300 text-red-800 hover:bg-red-400'>Wrong</button>
        </div>
    );
    
}