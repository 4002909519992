import { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import parse from "html-react-parser";


const AnswerRanking = forwardRef((props, ref) => {

    const [correct, setCorrect] = useState("Wrong");

    useEffect( async() => {
        //console.log(props.answer)

        if (
            (props.answer.A_1 == props.answer.R_1_Text) && 
            (props.answer.A_2 == props.answer.R_2_Text) &&
            ((props.answer.A_3 != null && props.answer.A_3 == props.answer.R_3_Text) || (props.answer.A_3 == null && props.answer.R_3_Text == null)) && 
            ((props.answer.A_4 != null && props.answer.A_4 == props.answer.R_4_Text) || (props.answer.A_4 == null && props.answer.R_4_Text == null)) && 
            ((props.answer.A_5 != null && props.answer.A_5 == props.answer.R_5_Text) || (props.answer.A_5 == null && props.answer.R_5_Text == null)) && 
            ((props.answer.A_6 != null && props.answer.A_6 == props.answer.R_6_Text) || (props.answer.A_6 == null && props.answer.R_6_Text == null)) && 
            ((props.answer.A_7 != null && props.answer.A_7 == props.answer.R_7_Text) || (props.answer.A_7 == null && props.answer.R_7_Text == null)) && 
            ((props.answer.A_8 != null && props.answer.A_8 == props.answer.R_8_Text) || (props.answer.A_8 == null && props.answer.R_8_Text == null)) && 
            ((props.answer.A_9 != null && props.answer.A_9 == props.answer.R_9_Text) || (props.answer.A_9 == null && props.answer.R_9_Text == null)) && 
            ((props.answer.A_10 != null && props.answer.A_10 == props.answer.R_10_Text) || (props.answer.A_10 == null && props.answer.R_10_Text == null)) && 
            ((props.answer.A_11 != null && props.answer.A_11 == props.answer.R_11_Text) || (props.answer.A_11 == null && props.answer.R_11_Text == null)) && 
            ((props.answer.A_12 != null && props.answer.A_12 == props.answer.R_12_Text) || (props.answer.A_12 == null && props.answer.R_12_Text == null)) 
        )
         { setCorrect("Correct"); }

        
    }, []); 
        
    return (
    <>
        <b>Answer: <span className={"rounded px-1 py-1 " + (correct=='Wrong' ? 'bg-red-300 text-red-800' : '') +  (correct=='Correct' ? ' bg-green-300 text-green-800' : '')}>{correct}</span></b>
        {props.answer.R_1_Text != null ? <><br />{parse(props.answer.R_1_Text)}</> : ''}
        {props.answer.R_2_Text != null ? <><br />{parse(props.answer.R_2_Text)}</> : ''}
        {props.answer.R_3_Text != null ? <><br />{parse(props.answer.R_3_Text)}</> : ''}
        {props.answer.R_4_Text != null ? <><br />{parse(props.answer.R_4_Text)}</> : ''}
        {props.answer.R_5_Text != null ? <><br />{parse(props.answer.R_5_Text)}</> : ''}
        {props.answer.R_6_Text != null ? <><br />{parse(props.answer.R_6_Text)}</> : ''}
        {props.answer.R_7_Text != null ? <><br />{parse(props.answer.R_7_Text)}</> : ''}
        {props.answer.R_8_Text != null ? <><br />{parse(props.answer.R_8_Text)}</> : ''}
        {props.answer.R_9_Text != null ? <><br />{parse(props.answer.R_9_Text)}</> : ''}
        {props.answer.R_10_Text != null ? <><br />{parse(props.answer.R_10_Text)}</> : ''}
        {props.answer.R_11_Text != null ? <><br />{parse(props.answer.R_11_Text)}</> : ''}
        {props.answer.R_12_Text != null ? <><br />{parse(props.answer.R_12_Text)}</> : ''}

        {(props.answer.Comments != null && props.answer.Comments) ? 
        <>
            <br /><br />
            <span className="whitespace-pre-line">
                <b>Comments:</b>
                <br />
                {props.answer.R_Text}
            </span>
        </> : ''}
    </>
    );
});

export default AnswerRanking;