// return date like YYYY-MM-DD
export function FormatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}


// return date like Month Day, (Year), e.g. Jun 10
export function FormatDateShort(date) {

    const formatter = new Intl.DateTimeFormat('en', { month: 'short' });
    
    var today = new Date();

    var d = new Date(date),
        day = '' + d.getDate();
        
    if (today.getFullYear() == d.getFullYear())
        return formatter.format(d) + ' ' +  day;
    else
        return formatter.format(d) + ' ' +  day + ', ' + d.getFullYear(); 
}