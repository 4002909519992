import { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import parse from "html-react-parser";

const AnswerFreeText = forwardRef((props, ref) => {

    const [correct, setCorrect] = useState("Wrong");
    
    useEffect( async() => {
        //console.log(props.answer)

        if (props.answer.Scored && props.answer.A_1_Correct == true) 
            setCorrect("Correct"); 
        else 
            setCorrect('Wrong');
        if (!props.answer.Scored || props.answer.A_1_Correct === null) setCorrect("");

    }, [props.answer]); 
        
    return (
    <>
        <b>Answer: <span className={"rounded px-1 py-1 " + (correct=='Wrong' ? 'bg-red-300 text-red-800' : '') +  (correct=='Correct' ? ' bg-green-300 text-green-800' : '')}>{correct}</span></b>
        <br />
        <span className="whitespace-pre-line" dangerouslySetInnerHTML={{ __html: props.answer.R_Text ? props.answer.R_Text.replaceAll('<', '&lt;') : ''}}/>

        {/* <span className="whitespace-pre-line">{parse(props.answer.R_Text ? props.answer.R_Text : '')}</span> */}

        {(props.answer.Comments != null && props.answer.Comments) ? 
        <>
            <br /><br />
            <span className="whitespace-pre-line">
                <b>Comments:</b>
                <br />
                {props.answer.R_1_Text}
            </span>
        </> : ''}
    </>
    );
});


export default AnswerFreeText;