import React, {useState, useEffect} from 'react';
import {Link, useParams} from 'react-router-dom';
import {useUser} from '../UserContext';
import TestsService from '../../services/TestsService';
import Loader from "react-loader-spinner";
import Header from '../Shared/Header';
import QuestionCard from './QuestionCard';
import QuestionSelector from './QuestionSelector';
import AlertSuccess from '../Shared/AlertSuccess';

export default function QuestionList (props) {
    const { user } = useUser();
    const [questions, setQuestions] = useState();
    const [mounted, setMounted] = useState(false);
    const {id } = useParams();
    const [showTitles, setShowTitles] = useState(false);
    const [questionSelector, setQuestionSelector] = useState(false);
    const [dragId, setDragId] = useState();
    const [dropped, setDropped] = useState(); // purpose of this is to know if an item has been dropped to show save order button, that's all
    const [orderSaved, setOrderSaved] = useState();

    const handleDrag = (ev) => {
        setDragId(ev.currentTarget.id);
      };

      const handleDrop = (ev) => {
        setDropped(true);
        setOrderSaved(false);
        const dragItem = questions.find((q) => q.QuestionId == dragId);
        const dropItem = questions.find((q) => q.QuestionId == ev.currentTarget.id);
   
        console.log(dragItem)
        console.log(dropItem)
        console.log(dragId)
        console.log(ev.currentTarget.id);

        const dragItemOrder = dragItem.QuestionOrder;
        const dropItemOrder = dropItem.QuestionOrder;
    
        const newItemState = questions.map((q) => {
          if (q.QuestionId == dragId) {
            q.QuestionOrder = dropItemOrder;
          }
          if (q.QuestionId == ev.currentTarget.id) {
            q.QuestionOrder = dragItemOrder;
          }
          return q;
        });
    
        setQuestions(newItemState);
      };

    useEffect( async () => {
        
        await fetchQuestions();
        setMounted(true);
    }, []);

    const fetchQuestions = async ()  => {
        const ret = await TestsService.getQuestions(user.accessToken.token, id);
        setQuestions(ret);

    }
    const deleteQuestion =  (questionId) => {
        // to do : delete  from db
        let list = [...questions];
        list.splice(list.findIndex(v => v.QuestionId === questionId), 1);
        setQuestions(list);
        TestsService.deleteQuestion(id, questionId, user.accessToken.token);
    }

    const cloneQuestion = async (questionId) => {
        await TestsService.cloneQuestion(user.accessToken.token, questionId, id);
        // refresh questions
        fetchQuestions();
    }


    const saveOrder = async () => {
        TestsService.updateQuestionsOrder(user.accessToken.token, id, questions);
        setOrderSaved(true);
        setDropped(false);
    }

    return ( 
        <>
        <Header nav='Tests'/>

        <div className="md:pl-64 flex flex-col flex-1 w-auto">
            {/* Page title & actions */}
            <div className="relative">
                <div className="py-6 px-4 sm:px-6 md:px-8 w-full fixed bg-title flex md:pr-64 shadow-sm">
                    <h1 className="font-semibold text-gray-900 text-xl">Questions</h1>
                    <div className='w-full  flex justify-end relative'>
                        <button onClick={() => setQuestionSelector(true)} className="absolute items-center mr-6 px-3 py-2 border text-sm rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none ">
                            Add question
                        </button>                    
                    </div>
                </div>
            </div>
            
            {/* Page */}
            {mounted ? 
            
            <div className="w-full min-h-screen bg-body px-4 sm:px-6 md:px-8 pt-20 shadow-sm">
                <div className="py-4">

                
                    {questions.length == 0 ? 
                        <>
                        <p className='text-center text-gray-400 text-xl mt-10'>Ugh... this test doesn't have any question yet.</p> 
                        <p className='text-center'><button className='text-indigo-600 hover:text-indigo-900 text-lg underline' onClick={() => setQuestionSelector(true)}>Add first question</button></p>
                        </>
                    : ''}

                    {questions.length >= 2 ? 
                        <button onClick={() => setShowTitles(!showTitles)} className='mb-4 text-indigo-600 hover:text-indigo-900 text-right w-full'>{!showTitles ? 'Expand all questions' : 'Collapse all questions'}</button>
                        : ''
                    }

                    {dropped ? <button onClick={saveOrder} className="items-center mr-6 mb-6 px-3 py-2 border text-sm rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none ">Save new order</button> : ''}
                    {orderSaved ? <AlertSuccess title='Order saved' /> : ''}
                    {questions.sort((a, b) => a.QuestionOrder - b.QuestionOrder).map((question, index) => (
                        <div key={question.QuestionId}>
                        <QuestionCard key={question.QuestionId} testId={id} index={index} questionId={question.QuestionId} questionType={question.QuestionType} questionOrder={question.QuestionOrder} scored={question.Scored} title={question.Q_Title} deleteQuestion={deleteQuestion} cloneQuestion={cloneQuestion} handleDrag={handleDrag} handleDrop={handleDrop} showTitles={showTitles}/>
                        </div>
                    ))}

                    {questions.length > 1 ? 
                    <div className='text-sm'>Note: Reorder your questions by clicking on the question and dragging to the new position.</div> 
                    :''}
                </div>
            </div>
            :
            <div className="bg-body w-full h-screen mx-auto px-4 sm:px-6 md:px-8 pt-20">
            <div className='flex justify-center mt-24'><Loader type="ThreeDots" color="#000000" width={50}/></div>
            </div>

            }

        </div>
        <QuestionSelector open={questionSelector} setOpen={setQuestionSelector} testId={id} />


        </>

    );

}