export function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  export const getPlanName = (id) => {
    switch (id){
      case "1":
      case 1: 
        return 'Standard';
      case "2":          
      case 2:
        return 'Premium';
      case "3":          
      case 3:
        return 'Ultimate';
      case "4":
      case 4:
        return 'On-Demand'
    }

  }

export const getCurrency = (currency) => {
    if (currency.toLowerCase() == 'eur')
      return '€';
    else
      return '$'
  }

  export const getPlanPrice = (id, currency) => {
    switch (id){
      case "1":
      case 1: 
        return (currency == 'eur' ? 49 : 59);
      case "2":          
      case 2:
        return (currency == 'eur' ? 99 : 119);
      case "3":          
      case 3:
        return (currency == 'eur' ? 199 : 239);
    }

  }
