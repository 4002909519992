import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {useUser} from '../UserContext';
import BillingService from '../../services/BillingService';
import CompaniesService from '../../services/CompaniesService';
import Loader from "react-loader-spinner";
import Header from '../Shared/Header';
import { InformationCircleIcon } from '@heroicons/react/solid'
import BillingOnDemandOffers from './BillingOnDemandOffers';
import AlertTrialExpired from '../Shared/AlertTrialExpired';
import AlertSuccess from '../Shared/AlertSuccess';
import {getPlanName, getCurrency} from '../../utils/general'
import { useCompany } from '../CompanyContext';

//const BillingOnDemandOffers = React.lazy(() => import('./BillingOnDemandOffers'));

var dayjs = require('dayjs')

export default function Billing (props) {
    const { user } = useUser();
    const {setCompany} = useCompany();
    const [payments, setPayments] = useState();
    const [comp, setComp] = useState();    
    const [mounted, setMounted] = useState(false);
    const [newPlan, setNewPlan] = useState();
    const [currentPlan, setCurrentPlan] = useState();
    const [showOndemand, setShowOndemand] = useState(false);
    const [changedPlan, setChangedPlan] = useState(false);

    const [plans, setPlans] = useState([
      { id: 1, name: 'Standard', description: 'Plan includes: 5 users, 50 candidates per month, private tests, custom subdomain' },
      { id: 2, name: 'Premium', description: 'Plan includes: 10 users, 100 candidates, private, public and private tests, custom subdomain' },
      { id: 3, name: 'Ultimate', description: 'Plan includes: unlimited users, unlimited candidates, code challenges, private & public tests, custom subdomain ' },
    ]);



    useEffect( async () => {
        const c = await CompaniesService.getCompany(user.accessToken.token);
        setComp(c);
        //console.log({c})
        setCurrentPlan(c.Subscription);
        
        //if (comp.Subscription == 4) {
          // company is on Demand so make this visible
          const ppp = [...plans];
          ppp.push({id:4, name: 'On-Demand', description: 'Plan includes: 5 users, private tests, custom domains and prepaid credits.'})
          setPlans(ppp);  
        //}
        
        const ret = await BillingService.getPayments(user.accessToken.token);
        setPayments(ret);
        //console.log(ret)
        // if current plan = on demand then make it visibile in the list

        let search = window.location.search;
        let params = new URLSearchParams(search);
        if (params.get('showOndemand')) setShowOndemand(true);

        setMounted(true);
    }, []);


    const changePlan = async() => {
      await CompaniesService.updateSubscriptionPlan(user.accessToken.token, newPlan);
      setCurrentPlan(newPlan);
      setChangedPlan(true);
      setCompany()
    }



    return ( 
        <>
        <Header nav='Billing'/>

        <div className="md:pl-64 flex flex-col flex-1">
            {/* Page title & actions */}
            <div className="relative">
                <div className="py-6 px-4 sm:px-6 md:px-8 w-full fixed bg-title  shadow-sm">
                <h1 className="font-semibold text-gray-900 text-xl">Billing</h1>
                </div>
            </div>
            
            {/* Page */}
            {mounted ? 
            <div className="w-full min-h-screen bg-body px-4 sm:px-6 md:px-8 pt-20 shadow-sm">
                <div className="py-4">

                {/* Trial ended message */}
                {comp.SubscriptionStartDate == null && comp.Subscription != 4 ?
                <div className=''>
                  <AlertTrialExpired title='YOUR 14-DAY TRIAL PERIOD HAS EXPIRED!' message='Please update your billing info so you can continue to use the service.' subscription={comp.Subscription} currency={comp.Currency.toLowerCase()}/>
                </div> 
                : ''  
              }

                  {/* Current plan details */}

                  {props.action == 'checkout_success' ? 
                  
                  <AlertSuccess title='Payment successful' message='We received your payment, thank you!' />
                  :
                  
                  <div className={"rounded-md p-4 " + (changedPlan ? 'bg-green-200' : 'bg-blue-200')}>                
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <InformationCircleIcon className="h-5 w-5 text-blue-400"  />
                      </div>                  
                      <div className="ml-3 flex-1 md:flex md:justify-between">
                        <p className="text-sm text-blue-800">You subscribed to <b>{getPlanName(currentPlan)}</b> plan.</p>
                      </div>                  
                    </div>
                  </div>

                  }


                  {/* Plan selection */}
                  {plans.filter(p => p.id <= (currentPlan == 4 || showOndemand? 4: 3)).map((plan) => (
                    <div key={plan.id} className="flex items-start mt-4">
                      <div className="flex items-center h-5">
                        <input
                          id={plan.id}
                          name="plan"
                          type="radio"
                          onChange={(e) => setNewPlan(e.target.id)}
                          defaultChecked={plan.id == currentPlan}
                          className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                        />
                      </div>
                      <div className="ml-3 text-sm">
                        <label htmlFor={plan.id} className="font-medium text-black">
                          {plan.name}
                        </label>
                        <p id={`${plan.id}-description`} className="text-gray-500">
                          {plan.description}
                        </p>
                      </div>
                    </div>
                  ))} 

                  {/* payment options for On Demand */}   
                  {currentPlan == 4 || showOndemand ? <BillingOnDemandOffers currency={getCurrency(comp.Currency)}/> : ''}

                  <button onClick={changePlan} className='items-center mt-2 px-3 py-2 border text-sm rounded-md shadow-sm text-white  focus:outline-none cursor-pointer bg-indigo-600 hover:bg-indigo-700'>Save</button>

                </div>
                <div className="py-4">
                <p className='font-bold text-lg pb-4'>Payment History</p>
                <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:pl-8"
                    >
                      DATE
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      AMOUNT
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      DESCRIPTION
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      PAYMENT METHOD
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-right text-sm font-semibold text-gray-900">
                      RECEIPT
                    </th>                    
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {payments.map((payment) => (
                    <tr key={payment.PaymentId}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8">
                        {dayjs(payment.PaymentDate).format('YYYY-MM-DD')}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{payment.Amount} {getCurrency(comp.Currency)}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{payment.Description}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{payment.PaymentMethod}</td>
                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 lg:pr-8">
                        <Link to={'/billing/receipt/' + payment.PaymentId } className="text-indigo-600 hover:text-indigo-900">
                          View
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

                </div>
            </div>
            :
            <div className="bg-body w-full h-screen mx-auto px-4 sm:px-6 md:px-8 pt-20">
            <div className='flex justify-center mt-24'><Loader type="ThreeDots" color="#000000" width={50}/></div>
            </div>

            }

        </div>


        </>

    );

}