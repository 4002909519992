import { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import AceEditor from "react-ace";
import 'ace-builds/webpack-resolver';
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/ext-language_tools";


const QuestionProgramming = forwardRef((props, ref) => {

    const [template, setTemplate] = useState('');

    // useImperativeHandle is needed to be able to access isValid function from the parent component
    useImperativeHandle(ref, () => ({

        // Validator for exact answer
        isValid : () => {
            if (props.question.CodeLanguage && props.question.CodeLanguage != '')
                return {
                    valid: true,
                    message: null
                }
            else            
                return {
                    valid: false,
                    message: 'Please select the programming language'
                } 
        }
    }));

    useEffect( async() => {
        console.log(props.question)
        if (props.question.A_1) setTemplate(props.question.A_1);
    }, []); 
    
    
    const setLanguage = (e) => {
        let q = {...props.question};
        q.CodeLanguage = e.target.value;
        props.setQuestion(q);

        // set code template
        let code;

        // c#
        if (e.target.value == 27) 
            code = `
using System;

public class Test
{
    public static void Main()
    {
        // your code goes here        
    }
}`;

        // c++
        if (e.target.value == 44) 
            code = `
#include <iostream>
using namespace std;

int main() {
    // your code goes here
    return 0;
}`;

        // java
        if (e.target.value == 10) 
            code = `
import java.util.*;
import java.lang.*;
import java.io.*;

class Test
{
    public static void main (String[] args) throws java.lang.Exception
    {
        // your code goes here
    }
}`;

        // javascript, php
        if (e.target.value == 112 || e.target.value == 29) 
            code = `// your code goes here`;            

        // python, ruby
        if (e.target.value == 116 || e.target.value == 17) 
            code =`# your code goes here`;            

        // perl
        if (e.target.value == 3) 
            code = `
#!/usr/bin/perl
# your code goes here`;            

        // vb.net
        if (e.target.value == 101) 
            code = `
Imports System

Public Class Test
    Public Shared Sub Main()
        ' your code goes here
    End Sub
End Class`;     

        // objective-c
        if (e.target.value == 43) 
            code =`
@implementation TestObj
int main()
{
// your code goes here
return 0;
}
@end`;

    setTemplate(code);
    setCodeTemplate(code);
    }

    const setCodeTemplate = (val) => {
        let q = {...props.question};
        q.A_1 = val;
        props.setQuestion(q)
        setTemplate(val); // preserve the template for setting the value in ace editor

    }

    // stdIn
    const setInput = (e) => { 
        let q = {...props.question};
        q.A_2 = e.target.value;
        props.setQuestion(q)

    }

    // stdOut
    const setOutput = (e) => {
        let q = {...props.question};
        q.A_3 = e.target.value;
        props.setQuestion(q)

    }


    return (
    < >
            <label htmlFor="title" className="w-1/5 mt-6 sm:mt-5 font-medium text-gray-700 ">
                Programming Language
            </label>
            <div className="w-3/5 mt-6 sm:mt-5">
                <select defaultValue={props.question.CodeLanguage} onChange={(e) => setLanguage(e)} className='p-1 rounded-md w-full' required={true}>
                    <option value="">Select</option> 
                    <option value="27" >C#</option>
                    <option value="44" >C++</option>
                    <option value="10" >Java</option>
                    <option value="112" >Javascript</option>
                    <option value="43" >Objective-C</option>
                    <option value="3" >Perl</option>
                    <option value="29" >PHP</option>
                    <option value="116" >Python</option>
                    <option value="17" >Ruby</option>
                    <option value="101" >VB.NET</option>                
                </select>

            </div>
            <div className='w-1/5'></div>


            <label className="w-1/5 mt-6 sm:mt-5 font-medium text-gray-700 ">Code template</label>   
            <div className="w-3/5 mt-6 sm:mt-5">         
            <AceEditor
                width={ '100%' }
                mode="javascript"
                theme="github"
                onChange={val=> {setCodeTemplate(val)}}
                fontSize={14}
                showPrintMargin={true}
                showGutter={true}
                highlightActiveLine={true}          
                value={template}     
                setOptions={{
                    useWorker: false,
                    enableBasicAutocompletion: false,
                    enableLiveAutocompletion: false,
                    showLineNumbers: true,
                    tabSize: 2,
                }}
            />
            </div>
            <div className='w-1/5'></div>


            <label htmlFor="title" className="w-1/5 mt-6 sm:mt-5 font-medium text-gray-700 ">
                Standard input
            </label>
            <div className="w-3/5 mt-6 sm:mt-5">
                <span className='text-gray-500'>(if applicable)</span>
                <textarea value={props.question.A_2} onChange={e=> {setInput(e)}} className='w-full h-40  rounded'></textarea>
            </div>
            <div className='w-1/5'></div>

            <label htmlFor="title" className="w-1/5 mt-6 sm:mt-5 font-medium text-gray-700 ">
                Expected output
            </label>
            <div className="w-3/5 mt-6 sm:mt-5">
                <span className='text-gray-500'>(if applicable, will be used to verify the solution and will not be displayed to candidates)</span>
                <textarea value={props.question.A_3} onChange={e=> {setOutput(e)}} className='w-full h-40 bo rounded'></textarea>
            </div>
            <div className='w-1/5'></div>

    </>
    );
});

export default QuestionProgramming;