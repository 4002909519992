import React, {useState, useEffect} from 'react';
import {useUser} from '../UserContext';
import CompaniesService from '../../services/CompaniesService';
import Loader from "react-loader-spinner";
import Header from '../Shared/Header';
import AlertError from '../Shared/AlertError';
import AlertSuccess from '../Shared/AlertSuccess';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useCompany } from '../CompanyContext';


export default function Settings (props) {
    const { user } = useUser();
    const {setCompany} = useCompany();

    const [mounted, setMounted] = useState(false);
    const validationSchema = Yup.object().shape({
        companyName: Yup.string().required('Company name is required'),
        //subdomain: Yup.string().required('Subdomain is required'),            
    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    const {register, setError, formState: {errors}, handleSubmit} = useForm(formOptions);
    const [comp, setComp] = useState();
    const [language, setLanguage] = useState();
    const [reminderFrequence, setReminderFrequence] = useState(0);
    const [logo, setLogo] = useState();
    const [newLogo, setNewLogo] = useState (null);
    const [newLogoBlob, setNewLogoBlob] = useState (null);
    const [success, setSuccess] = useState();
    const [submitting, setSubmitting] = useState(false);
    //const taken = ['app', 'www', 'admin', 'test', 'demo', 'dev', 'api', 'developer', 'help', 'kb', 'mail', 'docs', 'calendar', 'site', 'support', 'blog', 'testcenter', 'contact', 'register', 'login', 'signin'];

    const languages = [
        {name : 'English', value: 'en'},
        {name : 'Dutch', value: 'nl'},
        {name : 'French', value: 'fr'},
        {name : 'German', value: 'de'},
        {name : 'Hungarian', value: 'hu'},
        {name : 'Romanian', value: 'ro'},
        {name : 'Spanish', value: 'es'},
        {name : 'Swedish', value: 'sv'}
    ];

    const onSubmit = async(data) => {
        //console.log(data);
        setSuccess(null);

        // check that subdomain is not taken
        /*
        const ret = await CompaniesService.getSubdomain(data.subdomain);
        if (ret && ret.CompanyId != user.companyId ) {
            setError('subdomain', { type: 'focus', message: 'Subdomain already in use' }, { shouldFocus: true });
        }
        else if (taken.indexOf(data.subdomain) >=0) {
            setError('subdomain', { type: 'focus', message: 'Subdomain already in use' }, { shouldFocus: true });
        }
        else {
            setSubmitting(true);
            await CompaniesService.updateCompany(user.accessToken.token, data.companyName, data.subdomain, language, reminderFrequence, logo, newLogo);

            setSubmitting(false);
            setSuccess(true);
        }*/
        setSubmitting(true);
        await CompaniesService.updateCompany(user.accessToken.token, data.companyName, comp.SiteName, language, reminderFrequence, logo, newLogo);

        setSubmitting(false);
        setSuccess(true);

        setCompany(); // force update refresh company data in the context

    }

    const handleImageChange = async (e) => {        
        setNewLogo(e.target.files[0]); 
        setNewLogoBlob(URL.createObjectURL(e.target.files[0]));
  
      };    
  
    useEffect( async () => {
        const c = await CompaniesService.getCompany(user.accessToken.token);
        setComp(c);
        setLanguage(c.Language);
        setReminderFrequence(c.ReminderFrequence);
        setLogo(c.Logo);
        //console.log(u)
        setMounted(true);
    }, []);


    return ( 
        <>
        <Header nav='Company'/>   

        <div className="md:ml-64 flex flex-col flex-1 w-auto ">
            {/* Page title & actions */}
            <div className="relative">
                <div className="py-6 px-4 sm:px-6 md:px-8 w-full fixed bg-title flex md:pr-64 shadow-sm">
                    <h1 className="font-semibold text-gray-900 text-xl">Company settings</h1>
                </div>
            </div>
            
            {/* Page */}
            { mounted ? 
            <div className="bg-body w-full min-h-screen mx-auto px-4 sm:px-6 md:px-8 pt-20">
                <div className="py-4">

                    {success ? <AlertSuccess title='Saved successfully' /> : ''}

                    {(errors?.companyName || errors?.subdomain)  ? <AlertError title='Your form has errors' list={[errors.companyName?.message, errors.subdomain?.message]}/> : ''}

                    <form onSubmit={handleSubmit(onSubmit)}>

                    <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                        <div className="flex  sm:pt-5">
                            <label htmlFor="companyName" className="w-1/5 font-medium text-gray-700 ">
                                Company name
                            </label>
                            <div className="w-3/5">
                            <input id='companyName' {...register("companyName", {value : (comp ? comp.CompanyName : "")})} placeholder="Company name" className='p-1 rounded-md w-full' />

                            </div>
                        </div>                    
                        <div className="flex  sm:pt-5">
                            <label htmlFor="subdomain" className="w-1/5 font-medium text-gray-700">
                                Test center
                            </label>
                            <div className="w-3/5">
                                {/*<input id='subdomain' {...register("subdomain", { value:(company ? company.SiteName : "")})} placeholder="Subdomain" className='p-1 rounded-md' />*/}
                                {comp.SiteName}.skillmeter.com
                            </div>
                        </div>                    
                        <div className="flex  sm:pt-5">
                            <label htmlFor="language"  className="w-1/5 font-medium text-gray-700">
                                Test center language
                            </label>
                            <div className="w-3/5">
                            <select id='language' defaultValue={comp.Language} onChange={(e) => setLanguage(e.target.value)} className='p-1 rounded-md w-full'>
                                {languages.map((item, index) => (
                                    <option key={index} value={item.value}>{item.name}</option>
                                ))}
                            </select>                                   
                            </div>
                        </div>                    

                        <div className="flex  sm:pt-5">
                            <label htmlFor="reminderFrequence" className="w-1/5 font-medium text-gray-700 ">
                                Reminder frequency
                            </label>
                            <div className="w-3/5">
                            <select id='reminderFrequence' onChange={(e)=> setReminderFrequence(e.target.value)} defaultValue={comp.ReminderFrequence} className='p-1 rounded-md w-full'>
                                <option value="0" >No reminder</option>
                                {Array.from({length: 14}, (_, i) => i + 1).map((index) => (
                                    <option key={index} value={index} >{index} day</option>
                                ))}

                            </select>                                   
                            </div>
                        </div>                    

                        <div className="flex  sm:pt-5">
                            <label htmlFor="logo" className="w-1/5 font-medium text-gray-700">
                                Company logo
                            </label>
                            <div className="w-4/5">
                                <input type="file" id='image' accept="image/*" multiple onChange={handleImageChange} className=''/>
                                {
                                newLogoBlob ? 
                                    <img src={newLogoBlob} className='' style={{'maxHeight': '100px'}}/>
                                :
                                comp.Logo ?
                                    <img src={process.env.REACT_APP_CDN_URL + '/company_img/' + comp.Logo} className='' style={{'maxHeight': '100px'}}/>
                                    : ''        
                                }
                            </div>                      
                        </div>  

                        <div className='flex sm:pt-2 sm:mt-px'>
                            <button type="submit" className='items-center flex mr-6 px-3 py-2 border text-sm rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none cursor-pointer'>
                            Update
                            {submitting ? 
                            <svg className="animate-spin h-5 w-5 mt-1 ml-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                            </svg>                
                            : ''}

                            </button>
                        </div>

                    </div>    
                    </form>


                </div>
            </div>
            :
            <div className="bg-body w-full h-screen mx-auto px-4 sm:px-6 md:px-8 pt-20">
            <div className='flex justify-center mt-24'><Loader type="ThreeDots" color="#000000" width={50}/></div>
            </div>
            }

        </div>

        </>
    );


}