import { forwardRef, useImperativeHandle } from 'react';
import React, {useState, useEffect, useRef} from 'react';

import axios from "axios";
import { Editor } from '@tinymce/tinymce-react';
import {useUser} from '../../UserContext';

const Question = forwardRef((props, ref) => {
    const { user } = useUser();
    const [body, setBody] = useState('');  // initial title is empty (for new questions)

    // useImperativeHandle is needed to be able to access isValid function from the parent component
    useImperativeHandle(ref, () => ({

        // Validator for question title
        isValid : () => {
            if (props.question.Q_Title && props.question.Q_Title.trim() != '')
                return {
                    valid: true,
                    message: null
                }
            else
                return {
                    valid: false,
                    message: 'Question is required'
                }
        }
    }));

    useEffect( () => {

        // load the question title from props.question
        if (props.question.QuestionId > 0) {
            setBody(props.question.Q_Title)
        }
    }, []); // make sure this is executed only once and not again when the props.question is changed or else the editor content will behave weird

    return (
    <>
        <label htmlFor="title" className="w-1/5 font-medium text-gray-700 ">
            Question
        </label>
        <div className="w-3/5">


            <Editor tinymceScriptSrc='/tinymce/tinymce.min.js' initialValue={body} onEditorChange={(content, editor)=>{
                //console.log(content)
                let q = {...props.question};
                //console.log(q)

                // save question title back to props.question
                q.Q_Title = content;
                props.setQuestion(q)

            }} 
                init={{
                    base_url: '/tinymce',
                    height: 500,
                    branding: false,
                    menubar: false,
                    statusbar: false,
                    plugins: ['link', 'image', 'code', 'table'],
                    toolbar: 'bold italic backcolor | alignleft aligncenter alignright alignjustify | table | image link',
                    table_toolbar: 'tableprops tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',
                    file_picker_types: 'file',
                    file_picker_callback: function(callback, value, meta) {

                        var input = document.createElement('input');
                        input.setAttribute('type', 'file');
                        input.click();
                        input.onchange = function () {
                            var file = this.files[0];

                            let fd = new FormData();
                            fd.append('file', file, file.name);
                            axios.post(process.env.REACT_APP_SKILLMETER_API + '/assets', fd, { headers: {"Authorization" : `Bearer ${user.accessToken.token}`, "Content-type": "multipart/form-data"} })
                            .then(function(response){
                                callback(process.env.REACT_APP_CDN_URL + '/assets/' + user.companyId + '/' + response.data.location, { text: response.data.location});
                            })
                            .catch(function (error) {
                                console.log(error);
                            });
                        
                        }
                        },
                    
                    images_upload_handler: async function (blobInfo, success, failure, progress) {                            
                        console.log('Asset uploaded')             
                        let fd = new FormData();
                        fd.append('file', blobInfo.blob(), blobInfo.filename());
                        const ret = await axios.post(process.env.REACT_APP_SKILLMETER_API + '/assets', fd, { headers: {"Authorization" : `Bearer ${user.accessToken.token}`, "Content-type": "multipart/form-data"} });
                        //console.log(ret.data)

                        // return Promise instead of success cb (read the changelog)
                        return Promise.resolve(process.env.REACT_APP_CDN_URL + '/assets/' + user.companyId + '/' +  ret.data.location);

                    }     

                    /* THIS WORKS!!! They changed the image upload handler to return a Promise instead of success cb!!!!

                    images_upload_handler: function (blobInfo, success, failure, progress) {                            
                        console.log("We are in the image upload handler")             
                        // For now just a placeholder image to test that we reach here when a user pastes an image
                        const ret = {
                            location: "https://img.webmd.com/dtmcms/live/webmd/consumer_assets/site_images/article_thumbnails/other/cat_relaxing_on_patio_other/1800x1200_cat_relaxing_on_patio_other.jpg"
                        }
                        console.log(ret)
                        //success(ret.location);         
                        return Promise.resolve(ret.location);

                    }    */ 
                    /*
                    images_upload_handler: async function (blobInfo, success, failure) {
                        let fd = new FormData();
                        fd.append('file', blobInfo.blob(), blobInfo.filename());
                        const ret = await axios.post(process.env.REACT_APP_SKILLMETER_API + '/assets', fd, { headers: {"Authorization" : `Bearer ${user.accessToken.token}`, "Content-type": "multipart/form-data"} });
                        console.log(ret.data)
                        success(ret.data.location)
                    }*/

                }}                                
            />

        </div>
    </>
    );
});

export default  Question;