import React, {useState, useEffect} from 'react';
import { Redirect, useLocation, Link } from 'react-router-dom';
import AuthenticationService from '../../services/AuthenticationService';
import {useUser} from '../UserContext';
import Loader from "react-loader-spinner";
import validator from 'validator';



export default function SignIn(props) {

  const [loggedIn, setLoggedIn] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isLoginError, setIsLoginError] = useState(false);
  const [suspended, setSuspended] = useState(false);
  const [email, setEmail] = useState("");
  const [magicSent, setMagicSent] = useState(false);
  const { user, setUser } = useUser();

  // check if I have  a token param
  const query = new URLSearchParams(useLocation().search);
  const token = query.get('token')

  useEffect( async () => {
      // login the user with the token
      if (token) {
          await AuthenticationService
              .signIn(token)
              .then(
              (data) => {
                  //console.log('Set auth token')
                  setUser(data);
                  setLoggedIn(true);

              },
              error => {
                  //console.log("Login fail: error = { " + error.toString() + " }");
                  //console.log(error.response)
                  if (error.response?.status == 401) {
                    if (error.response.data == 'Account suspended')
                    {
                      //console.log('Account suspended') ;
                      setSuspended(true);
                    }
                  }
          
                  setLoggedIn(false);
                  setIsLoginError(true);

              }
          );

          
      }        
  }, []);

  if (loggedIn) {
      return <Redirect to={'/dashboard'} />;
    }

  const doSignIn = async (event) => {
      //event.preventDefault();

      if (validator.isEmail) {
      
          await AuthenticationService
              .getMagicLink(email)
              .then( () => {
                  //console.log('magic sent');
                  setMagicSent(true);
                      }        
              ).catch (
              (error) => {
              console.log("Error = { " + error.toString() + " }");
              setIsError(true);
              });
          }
          else {
              setIsError(true);
          }
  }

  if (token) 
  return (
      <section className="py-12 bg-gray-50 h-screen sm:py-16 lg:py-20">

      <a href="https://skillmeter.com"><img className="h-8 w-auto mx-auto mb-10" src="/sm-logo.png" alt="Skillmeter" /></a>

      
      {isLoginError ? 
          <div className="container mx-auto flex justify-center mt-10 whitespace-pre">
            {!suspended  ? 
              <div>Oops, your magic link has expired. Please request a new one <Link to="/sign-in" className="underline text-blue-600 hover:text-blue-800">here</Link>.</div>
              : 
              <div>
                <p className='font-bold text-2xl text-center text-red-700'>Your account has been suspended.</p>

                <p className='py-2 pt-4 text-lg'>Your account has been suspended as your trial has expired or your invoice has not been paid on time despite the attempts that Skillmeter has made to contact you.</p>

                <p className='py-2 text-lg'>In order to re-activate your account please contact us at <a href="mailto:support@skillmeter.com" className="underline text-blue-600 hover:text-blue-800">support@skillmeter.com</a>. </p>

                <p className='py-2 text-lg'>Please note your candidates can still take the tests assigned to them. You will be able to access your data once the account has been re-activated.</p>                
              </div>
            }
            
          </div>
          : 
          <div className='flex justify-center'><Loader type="ThreeDots" color="#000000" width={50}/></div>
      }
      </section>

  );
else 
    return (
<section className="py-12 bg-gray-50 h-screen sm:py-16 lg:py-20">
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="relative max-w-md mx-auto lg:max-w-lg">
          {/* 
          <div className="absolute -inset-2">
            <div className="w-full h-full mx-auto rounded-3xl opacity-30 blur-lg filter" ></div>
          </div>*/}

          <a href="https://skillmeter.com"><img className="h-8 w-auto mx-auto mb-10" src="/sm-logo.png" alt="Skillmeter" /></a>

          <div className="relative overflow-hidden bg-white shadow-xl rounded-xl">
            <div className="px-4 py-6 sm:px-8">
              <div className="text-center">
                {/*<img className="h-8 w-auto mx-auto" src="/skillmeter_logo.png" alt="Skillmeter" />*/}

                <h1 className="text-xl font-bold text-gray-900">
                  Welcome back
                </h1>
              </div>

               { magicSent ? 
              <div className="mt-10 mx-auto "><p className='text-center'>We emailed you the magic link. Click on that link and we will automatically log you in. </p></div> 
              :

              <div>
                <div className="space-y-4 mt-12">
                    <label htmlFor="email" className="text-base font-medium text-gray-900">
                      Enter your email below and we will send you a magic link to sign you in 👌
                    </label>
                    <div className="mt-4">
                      <input type="email" name='email' onChange={e => { setEmail(e.target.value);}} onKeyDown={e => e.key === 'Enter' ? doSignIn() : ''} placeholder="Email address" className="block w-full px-4 py-4 text-gray-900 placeholder-gray-600 bg-white border border-gray-400 rounded-xl focus:border-gray-900 focus:ring-gray-900 caret-gray-900" required={true}/>
                    </div>
                </div>

                <div className='flex justify-center'>
                <button type="submit" onClick={doSignIn} disabled={props.maintenance.maintenance} className="flex items-center justify-center px-8 py-4 mt-5 text-base font-bold text-white transition-all duration-200 bg-green-600 border border-transparent rounded-xl focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-600 font-pj hover:bg-green-900">
                  Sign in
                </button>
                </div>
                </div>

                }

                {isError ? <p className='text-red-500 font-bold text-center mt-4'>There was an error processing the request. If this persists, <a href='mailto:support@skillmeter.com' className='underline'>contact us</a>.</p>: ''}

                <p className="text-base font-normal text-gray-900 text-center mt-8">
                  Don’t have an account? <a href="https://skillmeter.com/pricing" title="" className="font-bold rounded hover:underline focus:outline-none focus:ring-1 focus:ring-gray-900 focus:ring-offset-2">Join now</a>
                </p>



            </div>
          </div>
        </div>
      </div>
    </section>

    );
}