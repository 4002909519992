import React, {useState, useEffect} from 'react';
import {useUser} from '../UserContext';
import UsersService from '../../services/UsersService';
import Loader from "react-loader-spinner";
import Header from '../Shared/Header';
import AlertError from '../Shared/AlertError';
import AlertSuccess from '../Shared/AlertSuccess';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Switch } from '@headlessui/react'
import axios from "axios";
import { Editor } from '@tinymce/tinymce-react';


export default function Notifications (props) {
    const { user } = useUser();
    const [mounted, setMounted] = useState(false);
    const [enabled, setEnabled] = useState(false)
    const [users, setUsers] = useState();
    const [success, setSuccess] = useState();
    const [newAlternate, setNewAlternate] = useState([]);
    const [alternate, setAlternate] = useState([]);
    const [changedAlternate, setChangedAlternate] = useState(false); // know if alternate notifs have changed
    const [changedNotif, setChangedNotif] = useState(false); // know if general notification have changed
    const [firstLoad, setFirstLoad] = useState(false);    
    const [loadingEditor, setLoadingEditor] = useState(true);

    const validationSchema = Yup.object().shape({
        notifSubject: Yup.string()
            .required('Notification subject is required'),
        notifBody: Yup.string()
            .required('Notification body is required')
    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    const {register, formState: {errors}, setValue, handleSubmit} = useForm(formOptions);
    const [person, setPerson] = useState();

    //console.log({errors})

    // I need this cause TinyMCE triggers onEditorChange when I set the initial value hance I need to ignore that
    const editorContentChanged = () => {

        if (firstLoad) 
            setChangedNotif(true);
        else
            setFirstLoad(true);
    }

    const onSubmit = async(data) => {
        //console.log(data);

        await UsersService.updateNotifications(user.accessToken.token, data.notifSubject, data.notifBody, enabled ? 1:0);

        setSuccess(true);
    }

    const saveAlternateNotifications = async () => {
        await UsersService.updateAlternateNotifications(user.accessToken.token, newAlternate);
        setSuccess(true);

    }

    const handleChange = (e) => {
        //console.log(e.target.selectedOptions)
        //console.log(Array.from(e.target.selectedOptions, item => item.value));
        setNewAlternate(Array.from(e.target.selectedOptions, item => item.value));
        setChangedAlternate(true);
    }

    useEffect( async () => {
        //console.log({user})

        // get user details
        const u = await UsersService.getMe(user.accessToken.token);
        setPerson(u);
        //console.log({u})
        setEnabled('' + u.NotificationActive === 'true' ? true:false);

        if (u.AlternateNotification ) {
            setAlternate(u.AlternateNotification.split(','));
        }

        // get all users
        const userList = await UsersService.getUsers(user.accessToken.token);
        setUsers(userList);

        setMounted(true);
    }, []);

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
      }
    return ( 
        <>
        <Header nav='Notifications'/>   

        <div className="md:ml-64 flex flex-col flex-1 w-auto ">
            {/* Page title & actions */}
            <div className="relative z-10">
                <div className="py-6 px-4 sm:px-6 md:px-8 w-full fixed bg-title flex md:pr-64 shadow-sm">
                    <h1 className="font-semibold text-gray-900 text-xl">Notifications</h1>
                </div>
            </div>
            
            {/* Page */}
            { mounted ? 
            <div className="bg-body w-full min-h-screen mx-auto px-4 sm:px-6 md:px-8 pt-20">
                <div className="py-4">

                    {success ? <AlertSuccess title='Saved successfully' /> : ''}


                    <div className="pb-5 border-b border-gray-200">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">Candidate email</h3>
                        <p className="mt-2 text-sm text-gray-500">
                        This email will be automatically sent to your candidates after they've been assigned to a test. This is a personal setting and will not be shared with other users from your organisation.
                        </p>                    

                    </div>

                    {(errors?.notifSubject || errors?.notifBody)  ? <AlertError title='Your form has errors' list={[errors.notifSubject?.message, errors.notifBody?.message]}/> : ''}

                    <form onSubmit={handleSubmit(onSubmit)}>

                    <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">

                    <div className="flex  sm:pt-5">
                            <label htmlFor="role" className="w-1/5 font-medium text-gray-700  ">
                                Sending is {enabled ? 'enabled' : 'disabled'}
                            </label>
                            <div className="w-3/5">
                                <Switch
                                    checked={enabled}
                                    onChange={() => {setEnabled(!enabled); setChangedNotif(true);}}
                                    className={classNames(
                                        enabled ? 'bg-green-600' : 'bg-red-600',
                                        'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none '
                                    )}
                                    >
                                    <span
                                        aria-hidden="true"
                                        className={classNames(
                                        enabled ? 'translate-x-5' : 'translate-x-0',
                                        'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                                        )}
                                    />
                                </Switch>
                            </div>
                        </div>                    

                        <div className="flex  sm:pt-5">
                            <label htmlFor="notifSubject" className="w-1/5 font-medium text-gray-700 ">
                                Subject
                            </label>
                            <div className="w-3/5">
                            <input id='notifSubject' {...register("notifSubject", {value : (person ? person.NotificationSubject : "")})} onChange={()=> setChangedNotif(true)} className='p-1 rounded-md w-full' />

                            </div>
                        </div>                    
                        <div className="flex  sm:pt-5">
                            <label htmlFor="notifBody" className="w-1/5 font-medium text-gray-700 ">
                                Body
                            </label>
                            <div className="w-3/5">
                                {/*<textarea id='notifBody' {...register("notifBody", { value:(person ? person.NotificationBody : "")})} onChange={()=> setChangedNotif(true)} rows={10} className='p-1 rounded-md w-full' />*/}
                                {loadingEditor ? 
                                <div className="bg-body w-full h-screen mx-auto px-4 sm:px-6 md:px-8 pt-20">
                                <div className='flex justify-center mt-24'><Loader type="ThreeDots" color="#000000" width={50}/></div>
                                </div> : ''}

                                <Editor tinymceScriptSrc='/tinymce/tinymce.min.js' {...register("notifBody", { value:(person ? person.NotificationBody : "")})} initialValue={person ? person.NotificationBody : ''} onEditorChange={(content, editor)=>{setValue('notifBody', content);editorContentChanged()}} 
                                    
                                    onInit={() => {
                                        setLoadingEditor(false);
                                      }}

                                    init={{
                                        base_url: '/tinymce',
                                        height: 500,
                                        branding: false,
                                        menubar: false,
                                        statusbar: false,
                                        plugins: [
                                        'link', 'image', 'code'
                                        ],
                                        toolbar: 'bold italic backcolor | alignleft aligncenter ' +
                                        'alignright alignjustify | ' +
                                        'image link',
                                        //table_toolbar: 'tableprops tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',
                                        file_picker_types: 'file',
                                        file_picker_callback: function(callback, value, meta) {

                                            var input = document.createElement('input');
                                            input.setAttribute('type', 'file');
                                            input.click();
                                            input.onchange = function () {
                                                var file = this.files[0];

                                                let fd = new FormData();
                                                fd.append('file', file, file.name);
                                                axios.post(process.env.REACT_APP_SKILLMETER_API + '/assets', fd, { headers: {"Authorization" : `Bearer ${user.accessToken.token}`, "Content-type": "multipart/form-data"} })
                                                .then(function(response){
                                                    callback(process.env.REACT_APP_CDN_URL + '/assets/' + user.companyId + '/' + response.data.location, { text: response.data.location});
                                                })
                                                .catch(function (error) {
                                                    console.log(error);
                                                });
                                            
                                            }
                                          },
                                        
                                        images_upload_handler: async function (blobInfo, success, failure, progress) {                            
                                            //console.log('Asset uploaded')             
                                            let fd = new FormData();
                                            fd.append('file', blobInfo.blob(), blobInfo.filename());
                                            const ret = await axios.post(process.env.REACT_APP_SKILLMETER_API + '/assets', fd, { headers: {"Authorization" : `Bearer ${user.accessToken.token}`, "Content-type": "multipart/form-data"} });
                                            //console.log(ret.data)

                                            // return Promise instead of success cb (read the changelog)
                                            return Promise.resolve(process.env.REACT_APP_CDN_URL + '/assets/' + user.companyId + '/' +  ret.data.location);

                                        }     

                                        /* THIS WORKS!!! They changed the image upload handler to return a Promise instead of success cb!!!!

                                        images_upload_handler: function (blobInfo, success, failure, progress) {                            
                                            console.log("We are in the image upload handler")             
                                            // For now just a placeholder image to test that we reach here when a user pastes an image
                                            const ret = {
                                                location: "https://img.webmd.com/dtmcms/live/webmd/consumer_assets/site_images/article_thumbnails/other/cat_relaxing_on_patio_other/1800x1200_cat_relaxing_on_patio_other.jpg"
                                            }
                                            console.log(ret)
                                            //success(ret.location);         
                                            return Promise.resolve(ret.location);

                                        }    */ 
                                        /*
                                        images_upload_handler: async function (blobInfo, success, failure) {
                                            let fd = new FormData();
                                            fd.append('file', blobInfo.blob(), blobInfo.filename());
                                            const ret = await axios.post(process.env.REACT_APP_SKILLMETER_API + '/assets', fd, { headers: {"Authorization" : `Bearer ${user.accessToken.token}`, "Content-type": "multipart/form-data"} });
                                           console.log(ret.data)
                                           success(ret.data.location)
                                        }*/

                                    }}                                
                                />
                                <p className='text-sm text-gray-400'><b>Note:</b> All {'{}'} tags will be replaced by Skillmeter before sending the email</p>
                                <p className='text-sm text-gray-400'>Mandatory: {'{test_center}'}, {'{pin_code}'}</p>
                                <p className='text-sm text-gray-400'>Optional: {'{first_name}'}, {'{last_name}'}, {'{company_name}'}, {'{test_name}'}</p>
                            </div>
                        </div>                    


                        <div className='flex sm:pt-2 sm:mt-px'>
                            <input type="submit" className={'items-center mr-6 px-3 py-2 border text-sm rounded-md shadow-sm text-white  focus:outline-none cursor-pointer ' + (changedNotif ?  'bg-indigo-600 hover:bg-indigo-700' : 'bg-gray-400 ')} disabled={changedNotif ? false: true} value='Save' />
                        </div>

                    </div>    
                    </form>


                </div>

                {/* alternate notifications */}
                <div className="mt-20 pb-4">
                <div className="pb-5 border-b border-gray-200">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">Result notifications</h3>
                    <p className="mt-2 text-sm text-gray-500">
                    By default, results notifications are sent to the user who added the candidate. However, you can also nominate alternate user(s) to receive the results notifications.
                        </p>                    

                </div>

                    <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                        <div className="flex  sm:pt-5">
                            <label className="w-1/5 font-medium text-gray-700 ">
                                Alternate Notification
                            </label>
                            <div className="w-3/5">
                                <select multiple={true} size={5} defaultValue={alternate} onChange={ (e) => handleChange(e)} className='p-1 rounded-md w-full'>
                                {users.map((person) => (
                                    <option key={person.UserId} value={person.UserId} >{person.Email}</option>
                                    ))}                                    
                                </select>  
                            </div>
                        </div>                    

                        <div className='flex sm:pt-2 sm:mt-px'>
                            <input type="button" onClick={() => saveAlternateNotifications()} className={'items-center mr-6 px-3 py-2 border text-sm rounded-md shadow-sm text-white  focus:outline-none cursor-pointer ' + (changedAlternate ?  'bg-indigo-600 hover:bg-indigo-700' : 'bg-gray-400 ') } disabled={changedAlternate? false:true} value='Save' />
                        </div>

                    </div>    


                </div>

            </div>
            :
            <div className="bg-body w-full h-screen mx-auto px-4 sm:px-6 md:px-8 pt-20">
            <div className='flex justify-center mt-24'><Loader type="ThreeDots" color="#000000" width={50}/></div>
            </div>
            }

        </div>

        </>
    );


}