import { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import parse from "html-react-parser";

const AnswerMultiAnswer = forwardRef((props, ref) => {

    const [correct, setCorrect] = useState("Wrong");

    useEffect( async() => {
        //console.log(props.answer)

        if (
            (props.answer.R_1 == null ? false : props.answer.R_1) == (props.answer.A_1_Correct == null ? false : props.answer.A_1_Correct) &&
            (props.answer.R_2 == null ? false : props.answer.R_2) == (props.answer.A_2_Correct == null ? false : props.answer.A_2_Correct) &&
            (props.answer.R_3 == null ? false : props.answer.R_3) == (props.answer.A_3_Correct == null ? false : props.answer.A_3_Correct) &&
            (props.answer.R_4 == null ? false : props.answer.R_4) == (props.answer.A_4_Correct == null ? false : props.answer.A_4_Correct) &&
            (props.answer.R_5 == null ? false : props.answer.R_5) == (props.answer.A_5_Correct == null ? false : props.answer.A_5_Correct) &&
            (props.answer.R_6 == null ? false : props.answer.R_6) == (props.answer.A_6_Correct == null ? false : props.answer.A_6_Correct) &&
            (props.answer.R_7 == null ? false : props.answer.R_7) == (props.answer.A_7_Correct == null ? false : props.answer.A_7_Correct) &&
            (props.answer.R_8 == null ? false : props.answer.R_8) == (props.answer.A_8_Correct == null ? false : props.answer.A_8_Correct) &&
            (props.answer.R_9 == null ? false : props.answer.R_9) == (props.answer.A_9_Correct == null ? false : props.answer.A_9_Correct) &&
            (props.answer.R_10 == null ? false : props.answer.R_10) == (props.answer.A_10_Correct == null ? false : props.answer.A_10_Correct) &&
            (props.answer.R_11 == null ? false : props.answer.R_11) == (props.answer.A_11_Correct == null ? false : props.answer.A_11_Correct) &&
            (props.answer.R_12 == null ? false : props.answer.R_12) == (props.answer.A_12_Correct == null ? false : props.answer.A_12_Correct) 
        )
        { 
            setCorrect("Correct"); 
        }
        else {

            if (((props.answer.R_1 == null ? false : props.answer.R_1) == props.answer.A_1_Correct) && props.answer.A_1_Correct == true) setCorrect("Partially correct");
            if (((props.answer.R_2 == null ? false : props.answer.R_2) == props.answer.A_2_Correct) && props.answer.A_2_Correct == true) setCorrect("Partially correct");
            if (((props.answer.R_3 == null ? false : props.answer.R_3) == props.answer.A_3_Correct) && props.answer.A_3_Correct == true) setCorrect("Partially correct");
            if (((props.answer.R_4 == null ? false : props.answer.R_4) == props.answer.A_4_Correct) && props.answer.A_4_Correct == true) setCorrect("Partially correct");
            if (((props.answer.R_5 == null ? false : props.answer.R_5) == props.answer.A_5_Correct) && props.answer.A_5_Correct == true) setCorrect("Partially correct");
            if (((props.answer.R_6 == null ? false : props.answer.R_6) == props.answer.A_6_Correct) && props.answer.A_6_Correct == true) setCorrect("Partially correct");
            if (((props.answer.R_7 == null ? false : props.answer.R_7) == props.answer.A_7_Correct) && props.answer.A_7_Correct == true) setCorrect("Partially correct");
            if (((props.answer.R_8 == null ? false : props.answer.R_8) == props.answer.A_8_Correct) && props.answer.A_8_Correct == true) setCorrect("Partially correct");
            if (((props.answer.R_9 == null ? false : props.answer.R_9) == props.answer.A_9_Correct) && props.answer.A_9_Correct == true) setCorrect("Partially correct");
            if (((props.answer.R_10 == null ? false : props.answer.R_10) == props.answer.A_10_Correct) && props.answer.A_10_Correct == true) setCorrect("Partially correct");
            if (((props.answer.R_11 == null ? false : props.answer.R_11) == props.answer.A_11_Correct) && props.answer.A_11_Correct == true) setCorrect("Partially correct");
            if (((props.answer.R_12 == null ? false : props.answer.R_12) == props.answer.A_12_Correct) && props.answer.A_12_Correct == true) setCorrect("Partially correct");


        }


        
    }, []); 
        
    return (
    <>
        <b>Answer: <span className={"rounded px-1 py-1 " + ((correct=='Wrong'|| correct=='Partially correct') ? 'bg-red-300 text-red-800' : '') +  (correct=='Correct' ? ' bg-green-300 text-green-800' : '')}>{correct}</span></b>
        {props.answer.R_1 != null ? <p className='mb-4'>{parse(props.answer.A_1)}</p> : ''}
        {props.answer.R_2 != null ? <p className='mb-4'>{parse(props.answer.A_2)}</p> : ''}
        {props.answer.R_3 != null ? <p className='mb-4'>{parse(props.answer.A_3)}</p> : ''}
        {props.answer.R_4 != null ? <p className='mb-4'>{parse(props.answer.A_4)}</p> : ''}
        {props.answer.R_5 != null ? <p className='mb-4'>{parse(props.answer.A_5)}</p> : ''}
        {props.answer.R_6 != null ? <p className='mb-4'>{parse(props.answer.A_6)}</p> : ''}
        {props.answer.R_7 != null ? <p className='mb-4'>{parse(props.answer.A_7)}</p> : ''}
        {props.answer.R_8 != null ? <p className='mb-4'>{parse(props.answer.A_8)}</p> : ''}
        {props.answer.R_9 != null ? <p className='mb-4'>{parse(props.answer.A_9)}</p> : ''}
        {props.answer.R_10 != null ? <p className='mb-4'>{parse(props.answer.A_10)}</p> : ''}
        {props.answer.R_11 != null ? <p className='mb-4'>{parse(props.answer.A_11)}</p> : ''}
        {props.answer.R_12 != null ? <p className='mb-4'>{parse(props.answer.A_12)}</p> : ''}



        {(props.answer.Comments != null && props.answer.Comments) ? 
        <>
            <br /><br />
            <span className="whitespace-pre-line">
                <b>Comments:</b>
                {props.answer.R_Text}
            </span>
        </> : ''}
    </>
    );
});

export default AnswerMultiAnswer;