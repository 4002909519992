import { useState, useEffect, forwardRef, useImperativeHandle } from 'react';

const AnswerProgramming = forwardRef((props, ref) => {

    const [correct, setCorrect] = useState("Wrong");
    
    useEffect( async() => {
        //console.log(props.answer)

        const  questionResponse = props.answer.A_3 != null ? props.answer.A_3.ToLowerCase().trim() : "";
        const  candidateResponse = props.answer.R_1_Text != null ? props.answer.R_1_Text.ToLowerCase().trim() : "";
        

        if (questionResponse == candidateResponse) 
            setCorrect("Correct");


    }, []); 
        
    return (
    <>
        <b>Answer: <span className={"rounded px-1 py-1 " + (correct=='Wrong' ? 'bg-red-300 text-red-800' : '') +  (correct=='Correct' ? ' bg-green-300 text-green-800' : '')}>{correct}</span></b>
        <br />
        <b>Code:</b> <span className='whitespace-pre-line'>{props.answer.R_Text}</span>
        <br />
        <b>Output:</b> {props.answer.R_1_Text !=null ? props.answer.R_1_Text + " wpm" : "not defined"}

        {(props.answer.Comments != null && props.answer.Comments) ? 
        <>
            <br /><br />
            <span className="whitespace-pre-line">
                <b>Comments:</b>
                <br />
                {props.answer.R_Text}
            </span>
        </> : ''}
    </>
    );
});


export default AnswerProgramming;